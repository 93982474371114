import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import Reports from '../../classes/Reports';
import EmployeesToRemove from './EmployeesToRemove.js';
import CrewsFilter from './CrewsFilter.js';
import CrewsTable from './CrewsTable.js';
import CreateCrew from './CreateCrew.js';
import EditCrew from './EditCrew.js';
import DeleteCrew from './DeleteCrew.js';
import Box from '@mui/material/Box';

const CrewsPage = ({}) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        includeDeleted: false,
    });

    const [message, setMessage] = useState({
        color: '',
        message: ''
    });

    const [scopes, setScopes] = useState([]);
    const [reports, setReports] = useState([]);

    const [showEmployeesToRemove, setShowEmployeesToRemove] = useState(false);
    const [employeesToRemove, setEmployeesToRemove] = useState([]);

    const [crews, setCrews] = useState([]);

    const [createCrew, setCreateCrew] = useState(false);
    const [editCrew, setEditCrew] = useState(false);
    const [crewToEdit, setCrewToEdit] = useState(null);
    const [deleteCrew, setDeleteCrew] = useState(false);
    const [crewToDelete, setCrewToDelete] = useState(null);

    const loadData = async () => {
        setDidLoadData(true);

        const tempUser = (await Auth.hitEndpointNew("GET", `user/${await Auth.getUserId()}`))[0]
        const tempShowEmployeesToRemove = tempUser.permission_group_id === 39 || tempUser.permission_group_id === 49;
        setShowEmployeesToRemove(tempShowEmployeesToRemove);
        if(tempShowEmployeesToRemove){
            setEmployeesToRemove(await Auth.hitEndpointNew("GET", "employees-new", "jsonString=remove_from_crew=1"));
        }

        let tempCrews = await Auth.hitEndpointNew("GET", "crews-new");
        if(!filters.includeDeleted){
            tempCrews = tempCrews.filter(c => parseInt(c.active) === 1);
        }
        setCrews(tempCrews.sort((c1, c2) => {
            return parseInt(c1.number) < parseInt(c2.number) ? -1 : 1;
        }));

        setScopes((await Auth.hitEndpointNew("GET", "crew-scopes")).map(s => {
            return {
                ...s,
                value: s.id,
                label: s.name
            }
        }).sort((s1, s2) => s1.name < s2.name ? -1 : 1));
        
        const tempReports = await Reports.loadScheduleReports()
        setReports(tempReports.map(r => {
            return {
                ...r,
                value: r.id,
                label: r.display,
                report: r
            }
        }));
    }

    if(!didLoadData){
        loadData();
    }

    const updateCrewsArray = async (crewNumber) => {
        let tempCrews = [...crews];

        const jsonString = `jsonString=number='${crewNumber}'`;
        const tempCrew = (await Auth.hitEndpointNew('GET', 'crews-new', jsonString))[0];

        const index = tempCrews.findIndex(c => parseInt(c.id) === parseInt(tempCrew.id));
        if(index >= 0){
            tempCrews[index] = {...tempCrew}
        }
        else{
            tempCrews.push({...tempCrew});
        }

        if(!filters.includeDeleted){
            tempCrews = tempCrews.filter(c => parseInt(c.active) === 1);
        }
        setCrews(tempCrews.sort((c1, c2) => {
            return parseInt(c1.number) < parseInt(c2.number) ? -1 : 1;
        }));
    }

    const displayMessageWithTimer = (message, textColor, milliseconds) => {
        setMessage({
            color: textColor,
            message: message
        });
        setTimeout(() => {
            setMessage({
                color: '',
                message: ''
            });
        }, milliseconds)
    }

    return (
        <Box sx={{
                margin: '10px'
        }}>
            {showEmployeesToRemove && employeesToRemove.length > 0 ? 
            <EmployeesToRemove
                employeesToRemove={employeesToRemove}
            />
            : null}
            <CrewsFilter
                filters={filters}
                setFilters={setFilters}
                setDidLoadData={setDidLoadData}
            />
            <CrewsTable 
                message={message}
                crews={crews}
                setCreateCrew={setCreateCrew}
                setEditCrew={setEditCrew}
                setCrewToEdit={setCrewToEdit}
                setDeleteCrew={setDeleteCrew}
                setCrewToDelete={setCrewToDelete}
            />
            {createCrew ? 
            <CreateCrew 
                displayMessageWithTimer={displayMessageWithTimer}
                updateCrewsArray={updateCrewsArray}
                createCrew={createCrew} 
                setCreateCrew={setCreateCrew}
                scopes={scopes}
                reports={reports}
            /> 
            : null}
            {editCrew && crewToEdit ? 
            <EditCrew 
                displayMessageWithTimer={displayMessageWithTimer}
                updateCrewsArray={updateCrewsArray}
                setEmployeesToRemove={setEmployeesToRemove}
                editCrew={editCrew} 
                setEditCrew={setEditCrew}
                crewToEdit={crewToEdit}
                setCrewToEdit={setCrewToEdit}
                scopes={scopes}
                reports={reports}
            /> 
            : null}
            {deleteCrew && crewToDelete ? 
            <DeleteCrew 
                displayMessageWithTimer={displayMessageWithTimer}
                updateCrewsArray={updateCrewsArray}
                setEmployeesToRemove={setEmployeesToRemove}
                deleteCrew={deleteCrew} 
                setDeleteCrew={setDeleteCrew}
                crewToDelete={crewToDelete}
                setCrewToDelete={setCrewToDelete}
            /> 
            : null}
        </Box>
    );
}

export default CrewsPage;