import {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import { adminKey } from '../../api/index.js';

import logo from '../../img/HeaderLogo.png';

import Auth from '../authentication/Auth.js';
import { Button } from '../utilities/Button.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import MaintenanceScreenContainer from './styles/MaintenanceScreenContainer';
import {logError} from "../../utilities/error";

const MaintenanceScreen = (props) => {
    const [loginData, setLoginData] = useState({
        adminKey: '',
        username: '',
        password: ''
    });

    const navigate = useNavigate();

    const authenticate = async () => {
        if(loginData.adminKey !== adminKey){
            logError("ERROR: Bad Admin Key");
            return;
        }
        
        try {
            const returnedData = await Auth.hitEndpoint("POST", "LOGIN", "", loginData);
            if(!returnedData.success){
                logError("ERROR: Username/Password Wrong");
                return;
            }

            Auth.login(returnedData.token);
            navigate("/site-maintenance");

        } catch (error) {
            logError("ERROR: Username/Password Wrong");
            logError(error);
        }
    }

    return (
        <MaintenanceScreenContainer>
            <img className='logo' src={logo} alt="SVC Logo"/>
            <div className='maintenance-message'>
                The Field Management System(FMS) is current down for maintenance. It will be back up shortly. Sorry for the inconvenience and thank you for your patience!
            </div>
            <div className="login">
                <div className='login-header'>Maintenance Admin Login</div>
                <TextboxWithLabel className="admin-key" inputType="text" name="admin-key" label="Admin Key" value={loginData.adminKey} handleTextbox={(e) => setLoginData({...loginData, adminKey: e.target.value})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                <TextboxWithLabel className="username" inputType="text" name="username" label="User Name" value={loginData.username} handleTextbox={(e) => setLoginData({...loginData, username: e.target.value})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                <TextboxWithLabel className="password" inputType="password" name="password" label="Password" value={loginData.password} handleTextbox={(e) => setLoginData({...loginData, password: e.target.value})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                <Button className="submit" handleClick={authenticate} buttonText="Submit" buttonMargin="20px auto" width="100" height="30"/>
            </div>
        </MaintenanceScreenContainer>
    )
};

export default MaintenanceScreen;