import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import dayjs from 'dayjs';
import Employees from '../../classes/Employees.js';

const CreateCrew = ({displayMessageWithTimer, updateCrewsArray,
                        createCrew, setCreateCrew, scopes, reports}) => {
    const [newCrew, setNewCrew] = useState({
        active: true,
        company: '',
        scope: null,
        members: [],
        number: '',
        phone_number: '',
    });

    const [employees, setEmployees] = useState([]);

    const [selectedReports, setSelectedReports] = useState([]);

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async () => {
        if(newCrew.number === ''){
            setError({
                type: 'NO CREW NUMBER',
                message: 'Please enter a crew number'
            })
            return;
        }
        if(newCrew.scope === null){
            setError({
                type: 'NO CREW SCOPE',
                message: 'Please select the crews scope'
            })
            return;
        }
        if(newCrew.members.length > 0){
            let hasLeader = false;
            for(let i = 0; i < newCrew.members.length; i++){
                if(hasLeader && newCrew.members[i].position.label === "Leader"){
                    setError({
                        type: 'MORE THAN ONE LEAD',
                        message: 'Multiple members are labeled as "Leaders"'
                    })
                    return;
                }
                if(newCrew.members[i].position.label === "Leader"){
                    hasLeader = true;
                }
                if(newCrew.members[i].position.label === ""){
                    setError({
                        type: 'NO CREW MEMBER POSITION SELECTED',
                        message: 'Please select a position for all crew members'
                    })
                    return;
                }
            }
            if(!hasLeader){
                setError({
                    type: 'NO CREW LEADER',
                    message: 'Please select one crew member to be the "Leader"'
                })
                return;
            }
        }
        const jsonString = JSON.stringify({number: newCrew.number});
        let dupData = await Auth.hitEndpoint("GET", "CREWS", `jsonString=${jsonString}`);
        if(dupData.length > 0){
            setError({
                type: 'DUPLICATE CREW NUMBER',
                message: 'Please enter a unused crew number'
            })
            return;
        }
        if(selectedReports.length === 0){
            setError({
                type: 'NO HOME REPORT SELECTED',
                message: 'Please select a home report'
            })
            return;
        }

        const crewLeader = newCrew.members.find(m => m.position.label === 'Leader');
        for(let i = 0; i < newCrew.members.length; i++){
            newCrew.members[i] = Employees.addCrewNumber(newCrew.number, newCrew.members[i]);
            const empDBObj = {
                id: newCrew.members[i].id,
                crews: newCrew.members[i].crews
            }
            await Auth.hitEndpointNew("PATCH", "employee", "", empDBObj); 
        }
        const dbObj = {
            active: newCrew.active,
            company: newCrew.company,
            crew_scope_id: newCrew.crew_scope_id,
            members: newCrew.members.map(m => {
                return {
                    employee_id: m.id,
                    position: m.position.value
                }
            }),
            modified_at: dayjs(new Date()).format('YYYY/MM/DD'),
            modified_by: await Auth.getUserId(),
            number: newCrew.number,
            phone_number: crewLeader.phone_number,
            scheduleReports: selectedReports.map(sr => {
                return {
                    schedule_report_id: sr.value
                }
            })
        };

        await Auth.hitEndpointNew("POST", "crew", "", dbObj);

        await updateCrewsArray(dbObj.number);
        displayMessageWithTimer('1 Crew Created', 'green', 5000);
        setCreateCrew(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Crew Created', 'green', 5000);
        setCreateCrew(false);
    }

    const setScopeAndCompanyAndLoadEmployees = async (scope) => {
        if(scope === null){
            setNewCrew({
                ...newCrew,
                crew_scope_id: null,
                scope: null,
                company: ""
            });
            setSelectedReports([]);
            return;
        }
        
        const tempReport = reports.find(r => parseInt(r.value) === parseInt(scope.schedule_report_id));
        const tempSelectedReports = [...selectedReports];
        if(!tempSelectedReports.find(sr => parseInt(sr.report.id) === parseInt(tempReport.value))){
            tempSelectedReports.push(tempReport)
        }
        setSelectedReports([...tempSelectedReports]);

        const tempNewCrew = {
            ...newCrew,
            crew_scope_id: scope.id,
            scope: scope,
            company: scope.company
        }
        setNewCrew(tempNewCrew);
        if(tempNewCrew.company !== ""){
            let jsonString = "";
            if(tempNewCrew.company === "SVC"){
                jsonString = JSON.stringify({concrete: 1});
            }
            if(tempNewCrew.company === "SVP"){
                jsonString = JSON.stringify({plumbing: 1});
            }
            if(tempNewCrew.company === "SVF"){
                jsonString = JSON.stringify({framing: 1});
            }
            const tempEmployees = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${jsonString}`);
            setEmployees(tempEmployees.filter(e => e.active).map(e => {
                let label = e.crews !== null && e.crews !== "" ? `${e.crews} - ` : "";
                label += e.alias !== null && e.alias !== "" ? `${e.alias} - (${e.first_name} ${e.last_name})` : `${e.first_name} ${e.last_name}`
                return {
                    ...e,
                    value: e.id,
                    label: label
                }
            }).sort((e1, e2) => {
                const e1CN = e1.crews !== null && e1.crews !== "" ? Number.parseInt(e1.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;
                const e2CN = e2.crews !== null && e2.crews !== "" ? Number.parseInt(e2.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;

                if(e1CN && e2CN){
                    return e1CN < e2CN ? -1 : 1
                }
                else if(e1CN){
                    return -1
                }
                else if(e2CN){
                    return 1;
                }

                if(e1.first_name === e2.first_name){
                    return e1.last_name < e2.last_name ? -1 : 1;
                }

                return e1.first_name < e2.first_name ? -1 : 1;
            }));
        }
    }

    const handleCrewMembers = (members) => {
        setNewCrew({...newCrew, members: members.map(m => {
            return {
                ...m,
                position: m.position === undefined ? {value: 0, label: ''} : m.position
            }
        })})
    }

    const handleMemberPosition = (employeeId, position) => {
        const tempNewCrew = {...newCrew};
        for(let i = 0; i < tempNewCrew.members.length; i++){
            if(tempNewCrew.members[i].id === employeeId){
                tempNewCrew.members[i].position = position;
            }
        }
        setNewCrew({...tempNewCrew});
    }

    return (
        <Dialog open={createCrew} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Create Crew</DialogTitle>
            <DialogContent sx={{width: '800px', paddingBottom: '30px'}}>
                <FormControlLabel 
                    sx={{
                        margin: '10px 0'
                    }}
                    control={<Checkbox 
                        defaultChecked={newCrew.active} 
                        onChange={(e) => setNewCrew({...newCrew, active: e.target.checked})}/>} 
                    label="Active" 
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto'
                    }}
                    size='small'
                    label="Crew Number"
                    placeholder="Enter Crew Number"
                    value={newCrew.number}
                    onChange={(e) => setNewCrew({...newCrew, number: e.target.value})}
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '30px',
                                margin: '10px auto'
                            }
                        }
                    }} 
                    placeholder="Select Scope"
                    value={newCrew.scope}
                    onChange={(scope) => setScopeAndCompanyAndLoadEmployees(scope)}
                    options={scopes}
                    isClearable
                    isSearchable
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                // height: '30px',
                                margin: '10px auto'
                            }
                        }
                    }} 
                    placeholder="Select Members"
                    value={newCrew.members}
                    onChange={(members) => handleCrewMembers(members)}
                    options={employees}
                    isClearable
                    isSearchable
                    isMulti
                />
                {newCrew.members.length > 0 &&
                newCrew.members.map(m => {
                    return (
                        <Grid 
                            key={m.id}
                            sx={{
                                padding: '0',
                                margin: '0 auto',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                width: '75%',
                            }}
                        >
                            <Grid
                                sx={{
                                    paddingRight: '10px',
                                    display: 'grid',
                                    alignItems: 'center',
                                    textAlign: 'right'
                                }}
                            >{m.label}</Grid>
                            <Select 
                                styles={{
                                    control: styles => {
                                        return {
                                            ...styles,
                                            height: '30px',
                                            margin: '10px auto'
                                        }
                                    }
                                }} 
                                placeholder="Select Member Position"
                                value={m.position}
                                onChange={(position) => handleMemberPosition(m.id, position)}
                                options={[
                                    {value: "Leader", label: "Leader"},
                                    {value: "Member", label: "Member"}
                                ]}
                                isClearable
                            />
                        </Grid>
                    );
                })}
                {newCrew.members.length === 0 && 
                <Grid
                    sx={{
                        margin: '0 auto 10px',
                        textAlign: 'center',
                        fontSize: '22px',
                        color: 'rgb(255, 150, 150)'
                    }}
                >
                    No Crew Members Selected
                </Grid>}
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '30px',
                                margin: '10px auto 30px'
                            }
                        },
                    }} 
                    placeholder="Select Report"
                    value={selectedReports}
                    onChange={(reports) => setSelectedReports(reports)}
                    options={reports}
                    isClearable
                    isSearchable
                    isMulti
                />
                {error.type !== '' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateCrew;