import {useContext, useEffect, useState, memo} from 'react';
import { useNavigate } from 'react-router';

import Can from "../authentication/Can.js";

import './styles/navbar.css'
import Auth from "../authentication/Auth.js";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MainNavLink from "./MainNavLink";
import SubNavLink from "./SubNavLink";
import SingleNavLink from "./SingleNavLink";
import Button from "@mui/material/Button";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FMS from '../../classes/FMS'

const Navbar = () => {

    const navigate = useNavigate();

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: 'rgb(55, 65, 81)',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                  //  color: theme.palette.text.secondary,
               //     marginRight: theme.spacing(1.5),
                },
                '&:active': {
                  //  backgroundColor: alpha(
                   //     theme.palette.primary.main,
                  //      theme.palette.action.selectedOpacity,
                 //   ),
                },
            },
            ...theme.applyStyles('dark', {
           //     color: theme.palette.grey[300],
            }),
        },
    }));
    
    const [didLoadData, setDidLoadData] = useState(false);
    const [menu, setMenu] = useState([])

    useEffect(() => {
        const loadData = async () => {
            const permissions = await Auth.getPermissions();
            const isUat = Can.isAuthorizedRead(permissions, 'uat')
            setMenu(await FMS.loadMainMenu(permissions, isUat));
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, []);

    const appbarStyle = {
        gridColumn: '1/6',
        background: 'rgb(58, 64, 67)'
        // background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(4,115,58,1) 100%)'
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileNavigate = (route) => {
        navigate(route, { replace: true })
        handleClose();
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    return (
        <AppBar position="static" color="primary" style={appbarStyle} className={"appbar"}>
            <Toolbar className={"toolbar"}>
                {menu.map((item, index) => {
                    if (item.children.length === 0) {
                        return (<SingleNavLink key={`menu-${index}`} label={item.label} route={item.route}
                                               icon={item.icon}/>)
                    } else {
                        return (
                            <MainNavLink key={`menu-${index}`} label={item.label} route={item.route} icon={item.icon}>
                                {item.children.map((child, cIndex) => {
                                    return (<SubNavLink key={`menu-${index}-${cIndex}`} label={child.label}
                                                        route={child.route}/>)
                                })}
                            </MainNavLink>)
                    }
                })}
            </Toolbar>
            <div className="mobile-nav">
                <Button
                    id="mobile-customized-button"
                    aria-controls={open ? 'mobile-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    style={{ background: 'rgb(58, 64, 67)', color: 'white'}}
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Navigation
                </Button>
                <StyledMenu
                    id="mobile-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {menu.map((item, index) => {
                        if (item.children.length === 0) {
                            return ( <MenuItem key={`mobile-menu-${index}`} onClick={() => handleMobileNavigate(item.route)} disableRipple>{item.label}</MenuItem>)
                        } else {
                            let element = <div key={`mobile-menu-child-container-${index}`}>
                                <Divider key={`mobile-menu-divider-${index}`} sx={{ my: 0.5 }} />
                                <MenuItem key={`mobile-menu-${index}`} disableRipple>{item.label}</MenuItem>
                                {item.children.map((child, cIndex) => {
                                    return ( <MenuItem key={`mobile-menu-child-${cIndex}`} onClick={() => handleMobileNavigate(child.route)} disableRipple>{child.mobile}</MenuItem>)
                                })}
                            </div>
                            return (element)
                        }
                    })}
                </StyledMenu>
            </div>
        </AppBar>
    );
}

export default memo(Navbar);
