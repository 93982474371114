import {useMemo, useState} from 'react';
import Employees from '../../classes/Employees.js';
import MainHeader from '../utilities/MainHeader.js';
import EmployeesPageContainer from './styles/EmployeesPageContainer.js';
import Select, {createFilter} from "react-select";
import Crews from "../../classes/Crews";
import {Autocomplete, Box, darken, IconButton, lighten, Tooltip, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {MaterialReactTable} from "material-react-table";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Users from "../../classes/Users";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Add, CheckCircle, Clear} from "@mui/icons-material";
import Auth from "../authentication/Auth";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const EmployeeTimeOffPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        showInactiveEmployees: false
    })

    const [companies, setCompanies] = useState([
        {label: 'SVC', value: 'svc'},
        {label: 'SVP', value: 'svp'},
        {label: 'SVF', value: 'svf'}
    ])
    const [selectedCompany, setSelectedCompany] = useState('')

    const [crews, setCrews] = useState([])
    const [employees, setEmployees] = useState([]);

    const [selectedCrew, setSelectedCrew] = useState('')
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [userId, setUserId] = useState(-1)
    const [users, setUsers] = useState([])

    const [openRequest, setOpenRequest] = useState(false)
    const [requestEmployee, setRequestEmployee] = useState('')

    const [request, setRequest] = useState({})
    const [requestRow, setRequestRow] = useState(null)

    const resetRequest = () => {
        let tempRequest = {
            id: null,
            employee_id: null,
            start_date: null,
            end_date: null,
            notes: '',
            approved: null,
            created_by: null,
            approval_by: null,
            is_new: true,
        }
        setRequest(tempRequest)
    }

    const handleCompanyChange = async (selection) => {
        setSelectedCompany(selection)
        setCrews(await Crews.loadCrewsForDropdown({company: selection.value}))
        setEmployees(await Employees.loadEmployeesForDropDown({[selection.value]: 1}))
    }

    const handleCrewChange = async (selection) => {
        setSelectedEmployee('')
        setSelectedCrew(selection)
        let emps = []
        if (selection !== null) {
            let lead = selection.members.find(x => x.position === "Leader")
            emps.push(lead.employee)
            for (let m = 0; m < selection.members.length; m++) {
                if (selection.members[m].position === "Member") {
                    emps.push(selection.members[m].employee)
                }
            }
        }
        setSelectedEmployees(emps)
    }
    const handleEmployeeChange = async (selection) => {
        setSelectedCrew('')
        let emps = []
        setSelectedEmployee(selection)
        if (selection !== null) {
            emps.push(selection)
        }
        setSelectedEmployees(emps)
    }

    const loadData = async () => {
        setUsers(await Users.loadUsers())
        setUserId(await Auth.getUserId())
        setDidLoadData(true);
    }

    if (!didLoadData) {
        loadData();
    }

    const getUserInitials = (userId) => {
        let user = users.find(x => x.id === userId)
        if (user !== undefined) {
            return `${user.firstname[0]}${user.lastname[0]}`
        } else {
            return ''
        }
    }

    const gridColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => dayjs(row.start_date).format('dddd, MMMM Do, YYYY'),
                    header: 'Start',
                    size: 30,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => dayjs(row.end_date).format('dddd, MMMM Do, YYYY'),
                    header: 'End',
                    size: 30,
                    enableEditing: false,
                },
                {
                    accessorKey: 'notes',
                    header: 'Notes',
                    size: 75,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => getUserInitials(row.created_by),
                    header: 'Entered By',
                    size: 10,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => getUserInitials(row.approval_by),
                    header: 'Approved By',
                    size: 10,
                    enableEditing: false,
                }

            ]
            return columns
        }
    );

    const handleApproveRequest = async (row, approved) => {

        let emps = [...selectedEmployees]
        let tempRequest = {...request}

        tempRequest.id = row.original.id
        tempRequest.employee_id = row.original.employee_id
        tempRequest.start_date = dayjs(row.original.start_date).format('YYYY-MM-DD')
        tempRequest.end_date = dayjs(row.original.end_date).format('YYYY-MM-DD')
        tempRequest.notes = row.original.notes
        tempRequest.approved = approved
        if(row.original.approved !== null){
            tempRequest.approval_by = userId
        }
        tempRequest.created_by = row.original.created_by

        let savedRequest = await Employees.saveTimeOffRequest(tempRequest)
        for (let e = 0; e < emps.length; e++) {
            if (emps[e].id === requestEmployee.id || emps[e].id === tempRequest.employee_id) {
                if(tempRequest.id !== null){
                    let timeOff = [...emps[e].time_off]
                    let eRow = timeOff.find(x => x.id === tempRequest.id)
                    if(eRow !== undefined){
                        eRow.start_date = tempRequest.start_date
                        eRow.end_date = tempRequest.end_date
                        eRow.notes = tempRequest.notes
                        eRow.approved = tempRequest.approved
                        eRow.approval_by = tempRequest.approval_by
                    }
                    emps[e].time_off = timeOff
                }
                else {
                    emps[e].time_off = [...emps[e].time_off, savedRequest]
                }
            }
        }
        setCrews(await Crews.loadCrewsForDropdown({company: selectedCompany.value}))
        setEmployees(await Employees.loadEmployeesForDropDown({[selectedCompany.value]: 1}))
        setSelectedEmployees(emps)
    }

    const handleEditRequest = (row) => {

        let tempRequest = {...request}
        tempRequest.id = row.original.id
        tempRequest.employee_id = row.original.employee_id
        tempRequest.start_date = row.original.start_date
        tempRequest.end_date = row.original.end_date
        tempRequest.notes = row.original.notes
        tempRequest.approved = row.original.approved
        if(row.original.approved !== null){
            tempRequest.approval_by = row.original.approval_by
        }
        tempRequest.created_by = row.original.created_by
        tempRequest.is_new = false

        setRequest(tempRequest)
        setOpenRequest(true)

    }
    const handleDeleteRequest = async (row) => {
        let emps = [...selectedEmployees]

        let deletedRequest = await Employees.deleteTimeOffRequest(row.original.id)
        for (let e = 0; e < emps.length; e++) {
            if (emps[e].id === requestEmployee.id || emps[e].id === row.original.employee_id) {
                let timeOff = [...emps[e].time_off]
                let eRow = timeOff.find(x => x.id === row.original.id)
                const index = timeOff.indexOf(eRow);
                timeOff.splice(index, 1)
                emps[e].time_off = timeOff
            }
        }
        setSelectedEmployees(emps)
        setCrews(await Crews.loadCrewsForDropdown({company: selectedCompany.value}))
        setEmployees(await Employees.loadEmployeesForDropDown({[selectedCompany.value]: 1}))
        resetRequest()
    }
    const handleSaveRequest = async () => {

        let tempRequest = {...request}
        let emps = [...selectedEmployees]

        if (tempRequest.start_date !== null && tempRequest.end_date !== null && tempRequest.notes !== null && tempRequest.notes !== '') {
            tempRequest.start_date = dayjs(tempRequest.start_date).format('YYYY-MM-DD')
            tempRequest.end_date = dayjs(tempRequest.end_date).format('YYYY-MM-DD')
            if (tempRequest.id === null) {
                tempRequest.created_by = await Auth.getUserId()
            }
        }

        let savedRequest = await Employees.saveTimeOffRequest(tempRequest)
        for (let e = 0; e < emps.length; e++) {
            if (emps[e].id === requestEmployee.id || emps[e].id === tempRequest.employee_id) {
                if(!tempRequest.is_new){
                    let timeOff = [...emps[e].time_off]
                    let row = timeOff.find(x => x.id === tempRequest.id)
                    if(row !== undefined){
                        row.start_date = tempRequest.start_date
                        row.end_date = tempRequest.end_date
                        row.notes = tempRequest.notes
                        row.approved = tempRequest.approved
                        row.approval_by = tempRequest.approval_by
                    }
                    emps[e].time_off = timeOff
                }
                else {
                    emps[e].time_off = [...emps[e].time_off, savedRequest]
                }
            }
        }

        setCrews(await Crews.loadCrewsForDropdown({company: selectedCompany.value}))
        setEmployees(await Employees.loadEmployeesForDropDown({[selectedCompany.value]: 1}))

        resetRequest()
        setSelectedEmployees(emps)
        setOpenRequest(false)
        setRequestEmployee('')
    }

    const handleCancelRequest = () => {
        setRequestEmployee('')
        resetRequest()
        setRequestRow(null)
        setOpenRequest(false)
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);
    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: ('any'),
    };

    return (
        <EmployeesPageContainer>
            <div className='page-filter'>
                <div className='filter-header'>Search for an Employee</div>
                <Select id="company-filter-select-id"
                        className="company-filter-select"
                        value={selectedCompany}
                        placeholder="Select A Company"
                        onChange={(e) => handleCompanyChange(e)}
                        options={companies}
                        isClearable
                        isSearchable
                    // isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        filterOption={createFilter(filterConfig)}/>
                THEN
                <Select id="crew-filter-select-id"
                        className="crew-filter-select"
                        value={selectedCrew}
                        placeholder="Select A Crew"
                        onChange={(e) => handleCrewChange(e)}
                        options={crews}
                        isClearable
                        isSearchable
                    //isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        filterOption={createFilter(filterConfig)}/>
                OR
                <Select id="employee-filter-select-id"
                        className="employee-filter-select"
                        value={selectedEmployee}
                        placeholder="Select An Employee"
                        onChange={(e) => handleEmployeeChange(e)}
                        options={employees}
                        isClearable
                        isSearchable
                    //  isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        filterOption={createFilter(filterConfig)}/>
            </div>
            <div className='page-header'>
                <div className='header-title'>Time Off</div>
            </div>
            {selectedEmployees.map((employee) => {
                return (
                    <div key={`${employee.id}-time-off-div`} style={{zIndex: 0, paddingBottom: '20px'}}>
                        <div key={`${employee.id}-table-div`}
                             style={{borderBottom: '1pt solid', borderLeft: '1pt solid', borderRight: '1pt solid'}}>
                            <MaterialReactTable
                                id={`employee-time-off-${employee.id}-detail-table`}
                                columns={gridColumns}
                                data={employee.time_off}
                                rowCount={employee.time_off.length}
                                enableGrouping
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableExpanding={false}
                                enableExpandAll={false}
                                enableTopToolbar={true}
                                enableBottomToolbar={true}
                                enableSorting={false}
                                enableFilters={false}
                                enableHiding={false}
                                enableEditing={true}
                                editDisplayMode="table"
                                enableColumnActions={true}
                                enableRowActions={true}
                                muiTableContainerProps={{
                                    sx: {
                                        minHeight: '100px'
                                    }
                                }}
                                muiTableHeadCellProps={({column}) => ({
                                    sx: {
                                        '& .MuiTableSortLabel-icon': {
                                            color: column.getIsSorted() ? 'limegreen !important' : undefined
                                        },
                                        backgroundColor: '#D3D3D3'
                                    }
                                })}
                                renderRowActions={({row, table}) => {
                                    return (
                                        <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                            <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                                {(row.original.approved === null || row.original.approved === 0 || row.original.approved === false) &&
                                                    dayjs(row.original.end_date).format('YYYY-MM-DD') >= dayjs(new Date()).format('YYYY-MM-DD')
                                                    && <Tooltip title="Approve Request">
                                                        <IconButton onClick={async () => {
                                                            await handleApproveRequest(row, true);
                                                        }}  style={{cursor: 'pointer', color: 'green'}}>
                                                            <CheckCircle/>
                                                        </IconButton>
                                                    </Tooltip>}
                                                {(row.original.approved === 1 || row.original.approved === true) &&
                                                    dayjs(row.original.end_date).format('YYYY-MM-DD') >= dayjs(new Date()).format('YYYY-MM-DD')
                                                    && <Tooltip title="Deny Request">
                                                        <IconButton onClick={async () => {
                                                            await handleApproveRequest(row, false);
                                                        }}  style={{cursor: 'pointer', color: 'red'}}>
                                                            <Clear/>
                                                        </IconButton>
                                                    </Tooltip>}
                                            </div>
                                            <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                                {dayjs(row.original.end_date).format('YYYY-MM-DD') >= dayjs(new Date()).format('YYYY-MM-DD') &&
                                                    <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                                        <Tooltip title="Edit Request">
                                                            <IconButton onClick={async () => {
                                                                await handleEditRequest(row);
                                                            }} style={{cursor: 'pointer'}}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>}
                                                {dayjs(row.original.start_date).format('YYYY-MM-DD') >= dayjs(new Date()).format('YYYY-MM-DD') &&
                                                    <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                                        <Tooltip title="Delete Request">
                                                            <IconButton onClick={async () => {
                                                                await handleDeleteRequest(row);
                                                            }}  style={{cursor: 'pointer', color: 'red'}}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>}
                                            </div>
                                        </div>
                                    )
                                }}
                                renderTopToolbarCustomActions={(table) => {
                                    const handleCreateRequest = (employee) => {
                                        let tempRequest = {...request}
                                        tempRequest.employee_id = employee !== null ? employee.id : null
                                        tempRequest.created_by = userId
                                        tempRequest.start_date = null
                                        tempRequest.end_date = null
                                        tempRequest.notes = ''
                                        tempRequest.is_new = true
                                        tempRequest.approved = false
                                        setRequest(tempRequest)
                                        setRequestEmployee(employee)
                                        setOpenRequest(true)
                                    };
                                    return <div>
                                        <Typography variant="h8">
                                            {employee.alias !== null ? `(${employee.alias}) ` : ''}{employee.first_name} {employee.last_name}
                                        </Typography>
                                        <Tooltip title="New Time off Request">
                                            <IconButton onClick={() => {
                                                handleCreateRequest(employee)
                                            }}>
                                                <Add/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>;
                                }}
                                initialState={{
                                    showColumnFilters: false,
                                    pagination: {pageSize: 100, pageIndex: 0},
                                    density: 'compact',
                                }}
                            />
                        </div>

                        {openRequest &&
                            <Dialog open={openRequest}>
                                <DialogTitle>{requestEmployee !== '' ? `${requestEmployee.alias !== null ? `(${requestEmployee.alias}) ` : ''}${requestEmployee.first_name} ${requestEmployee.last_name}` : ''} New
                                    Time Off Request</DialogTitle>
                                <DialogContent sx={{width: "600px", height: '500px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(date) => {
                                                const tempDate = new Date(date);
                                                let tempRequest = {...request}
                                                tempRequest.start_date = tempDate
                                                setRequest(tempRequest)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    helperText: 'Start Date',
                                                    sx: {
                                                        width: '75%',
                                                        paddingBottom: '15px',
                                                        minWidth: '120px',
                                                    },
                                                },
                                            }}
                                            maxDate={dayjs(request.end_date)}
                                            defaultValue={dayjs(request.start_date)}
                                            selected={request.start_date}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(date) => {
                                                const tempDate = new Date(date);
                                                let tempRequest = {...request}
                                                tempRequest.end_date = tempDate
                                                setRequest(tempRequest)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    helperText: 'End Date',
                                                    sx: {
                                                        width: '75%',
                                                        paddingBottom: '15px',
                                                        minWidth: '120px',
                                                    },
                                                },
                                            }}
                                            minDate={dayjs(request.start_date)}
                                            defaultValue={dayjs(request.end_date)}
                                            selected={request.end_date}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        helperText='Notes'
                                        onBlur={(e) => {
                                            let tempRequest = {...request}
                                            let note = e.target.value
                                            tempRequest.notes = note
                                            setRequest(tempRequest)
                                        }}
                                        defaultValue={request.notes}
                                    ></TextField>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='contained' onClick={() => handleSaveRequest()}>Save</Button>
                                    <Button variant='contained' onClick={handleCancelRequest}>Cancel</Button>
                                </DialogActions>
                            </Dialog>}
                    </div>
                )
            })}

        </EmployeesPageContainer>
    );
}

export default EmployeeTimeOffPage;