import { useState } from 'react';
import { createAlert } from '../../utilities/MainHeaderAlert.js';
import Employees from '../../../classes/Employees';
import Auth from '../../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';

const EditEmployee = ({displayMessageWithTimer, updateEmployeesArray,
                        editEmployee, setEditEmployee, employeeToEdit, setEmployeeToEdit}) => {
    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const setActive = async (e) => {
        let tempEmployee = {...employeeToEdit}
        if(employeeToEdit.active){
            const crewMembers = await Auth.hitEndpointNew("GET", "crew-members", `jsonString=${JSON.stringify({employee_id: employeeToEdit.id})}`);
            if(crewMembers.length > 0){
                tempEmployee.active = false;
                tempEmployee.remove_from_crew = true;
                createAlert(
                    `Remove ${employeeToEdit.first_name} ${employeeToEdit.last_name} from crew ${employeeToEdit.crews}`,
                    '/crews-page',
                    [39, 49]
                )
            }
            else{
                tempEmployee.active = false;
            }
        }
        else{
            tempEmployee.active = true;
            tempEmployee.remove_from_crew = false;
        }
        setEmployeeToEdit({...tempEmployee})
    }

    const closeWithSave = async () => {
        const errorResult = await Employees.checkEmployeeForErrors(employeeToEdit);
        if(errorResult !== 'SUCCESS'){
            if(errorResult === 'ERROR_DUPLICATE_NAME'){
                setError({
                    type: 'DUPLICATE NAME',
                    message: 'Name already exists. Please enter a unique name.'
                })
                return;
            }
            if(errorResult === 'ERROR_DUPLICATE_QUICK_BOOKS_ID'){
                setError({
                    type: 'DUPLICATE QUICKBOOKS ID',
                    message: 'Quickbooks ID already exists. Please enter a unique Quickbooks ID.'
                })
                return;
            }
            setError({
                type: errorResult,
                message: ''
            })
            return;
        }

        const dbObj = {
            id: employeeToEdit.id,
            active: employeeToEdit.active,
            remove_from_crew: employeeToEdit.remove_from_crew,
            first_name: employeeToEdit.first_name,
            last_name: employeeToEdit.last_name,
            alias: employeeToEdit.alias,
            phone_number: employeeToEdit.phone_number,
            email: employeeToEdit.email,
            qb_id: employeeToEdit.qb_id,
            svc: employeeToEdit.svc,
            svp: employeeToEdit.svp,
            svf: employeeToEdit.svf,
            sub: employeeToEdit.sub,
            company: employeeToEdit.company,
            sub_concrete: employeeToEdit.sub_concrete,
            sub_plumbing: employeeToEdit.sub_plumbing,
            sub_framing: employeeToEdit.sub_framing,
            modified_by: await Auth.getUserId(),
            modified_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss'),
            hire_date: employeeToEdit.hire_date ? dayjs(new Date(employeeToEdit.hire_date)).format('YYYY-MM-DD H:mm:ss') : null,
            inquiry_date: employeeToEdit.inquiry_date ? dayjs(new Date(employeeToEdit.inquiry_date)).format('YYYY-MM-DD H:mm:ss') : null,
        };
        
        await Auth.hitEndpointNew("PATCH", "employee", "", dbObj);

        await updateEmployeesArray(dbObj);
        displayMessageWithTimer('1 Job Updated', 'green', 5000);
        setEmployeeToEdit(null);
        setEditEmployee(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Employees Edited', 'green', 5000);
        setEmployeeToEdit(null);
        setEditEmployee(false);
    }

    return (
        <Dialog open={editEmployee} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Edit Employee</DialogTitle>
            <DialogContent sx={{width: '800px', paddingBottom: '30px'}}>
                <FormControlLabel 
                    sx={{
                        margin: '10px 0'
                    }}
                    control={<Checkbox
                        checked={employeeToEdit.active}
                        onChange={setActive}/>} 
                    label="Active" 
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_FIRST_NAME" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="First Name"
                    placeholder="Enter First Name"
                    value={employeeToEdit.first_name}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, first_name: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_LAST_NAME" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={employeeToEdit.last_name}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, last_name: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Alias/Nickname"
                    placeholder="Enter Alias/Nickname"
                    value={employeeToEdit.alias}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, alias: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    value={employeeToEdit.phone_number}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, phone_number: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Email"
                    placeholder="Enter Email"
                    value={employeeToEdit.email}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, email: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_QUICKBOOKS_ID" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="QuickBooks ID"
                    placeholder="Enter QuickBooks ID"
                    value={employeeToEdit.qb_id}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, qb_id: e.target.value})}
                />
                <FormControlLabel 
                    sx={{
                        margin: '10px 0 0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        checked={employeeToEdit.svc}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, svc: e.target.checked})}/>} 
                    label="SVC" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        checked={employeeToEdit.svp}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, svp: e.target.checked})}/>} 
                    label="SVP" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        checked={employeeToEdit.svf}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, svf: e.target.checked})}/>} 
                    label="SVF" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        checked={employeeToEdit.sub}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, sub: e.target.checked})}/>} 
                    label="Sub Contractor" 
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0',
                        backgroundColor: error.type === "ERROR_NO_SUB_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Sub Contractor Name"
                    placeholder="Enter Sub Contractor Name"
                    value={employeeToEdit.company}
                    onChange={(e) => setEmployeeToEdit({...employeeToEdit, company: e.target.value})}
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!employeeToEdit.sub && employeeToEdit.company === ''}
                        checked={employeeToEdit.sub_concrete}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, sub_concrete: e.target.checked})}/>} 
                    label="Concrete Sub" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!employeeToEdit.sub && employeeToEdit.company === ''}
                        checked={employeeToEdit.sub_plumbing}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, sub_plumbing: e.target.checked})}/>} 
                    label="Plumbing Sub" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!employeeToEdit.sub && employeeToEdit.company === ''}
                        checked={employeeToEdit.sub_framing}
                        onChange={(e) => setEmployeeToEdit({...employeeToEdit, sub_framing: e.target.checked})}/>} 
                    label="Framing Sub" 
                />
                {(error.type === 'DUPLICATE NAME' || error.type === 'ERROR_DUPLICATE_QUICK_BOOKS_ID') &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Update</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditEmployee;