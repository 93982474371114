import {useState} from 'react';
import XLSX from 'xlsx';

import SiteMaintenanceContainer from './styles/SiteMaintenanceContainer';
import {logError} from "../../utilities/error";

// TODO Add drop down to select app state mode

const SiteMaintenance = () => {
    const [data, setData] = useState(null);

    const parseFile = async (e) => {
        logError('Starting....');

        // const jobNumber = data[0]["Job / Lot"].split("-")[0];
        // const job = (await Auth.hitEndpoint("GET", "JOBS", `selector=four&number=${jobNumber}`))[0];
        // const lots = await Auth.hitEndpoint("GET", "LOTS", `selector=one&jobId=${job._id}`);

        // const parsedData = [];
        // const startingDate = (new Date("1900-01-01")).valueOf();
        // for(let i = 0; i < data.length; i++){
        //     const convertedDate = (data[i].Date - 1) * 1000 * 60 * 60 * 24;
        //     const splitJobLotPhase = data[i]["Job / Lot"] ? data[i]["Job / Lot"].split("-") : [];
        //     if(splitJobLotPhase.length === 3){
        //         parsedData.push({
        //             releaseDate: new Date(startingDate + convertedDate),
        //             jobNumber: splitJobLotPhase[0],
        //             lotNumber: Number.parseInt(splitJobLotPhase[1]).toString(), // TODO This won't work with all lot numbers
        //             phaseId: `Phase ${splitJobLotPhase[2]}`,
        //         });
        //     }
        // }

        // const currentPhase = 0;
        // for(let i = 0; i < parsedData.length; i++){
        //     const currentLot = lots.find(lotToFind => lotToFind.lotNumber.toString() === parsedData[i].lotNumber.toString() && lotToFind.phaseId.toString() === parsedData[i].phaseId.toString());
        //     if(!currentLot){
        //         continue;
        //     }
        //     if(currentLot.releasePhases.phases[currentPhase].startsStatus === ""){
        //         currentLot.releasePhases.phases[currentPhase].startsStatus = "Processed";
        //         currentLot.releasePhases.phases[currentPhase].releaseDate = parsedData[i].releaseDate;

        //         // TODO Update DB
        //         // await Auth.hitEndpoint("PATCH", "LOTS", "", currentLot); 
        //     }
        //     else{
        //
        //     }
        // }

        logError('Finished');
    }

    const handleOnDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const fileType = file.type;
        const validFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if(validFileType === fileType){
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "binary"});
                workbook.SheetNames.forEach((sheetName) => {
                    const lotData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    setData(lotData);
                });
            }
            fileReader.readAsBinaryString(file);
        }
        else{
            alert('Invalid File Type. .xlsx Files Only In Valid Format');
        }

        const dropArea = document.getElementById("drop-area");
        dropArea.innerText = "Drag File Here";
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        const dropArea = document.getElementById("drop-area");
        dropArea.innerText = "Drop File";
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        const dropArea = document.getElementById("drop-area");
        dropArea.innerText = "Drag File Here";
    }

    return (
        <SiteMaintenanceContainer>
            <div className='drag-n-drop-box' onDrop={handleOnDrop} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
                <div className='drag-n-drop-header'>Drag N Drop</div>
                <div id="drop-area" className="drop-area">Drag File Here</div>
            </div>
            <div className='submit'>
                <div className='button' onClick={parseFile}>Parse File</div>
            </div>
        </SiteMaintenanceContainer>
    );
}

export default SiteMaintenance;