import styled from 'styled-components';

const PaverMeasurementsContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 0;

    .measurements-sub-header{
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
        border-top: 2px solid white;
    }

    .release-date-box{
        display: grid;
        background: rgb(210, 200, 190);

        .measurements-release-date-box,
        .measurements-release-status-box,
        .po-display-box,
        .measurements-lot-notes-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .measurements-release-date-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 15px 5px 5px 0;
            }

            .measurements-release-date-value{
                grid-column: 6/11;
                padding: 15px 0 5px 5px;
            }

            .measurements-release-status-label,
            .po-display-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .measurements-release-status-value,
            .po-display-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;
            }

            .measurements-lot-notes-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .measurements-lot-notes-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;
                cursor: pointer;

                .add-note-button-box {
                    display: grid;
                    justify-content: start;
                    margin-top: -2px;
                    
                    .add-note-button {
                        width: 100px;
                        height: 22px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: default;
                        
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }
    }

    .no-pavers-box{
        display: grid;
        background: rgb(200, 200, 200);
        color: rgb(245, 245, 245);

        .no-pavers-message{
            padding: 20px 40px;
        }
    }

    .style-color-box{
        display: grid;
        background: rgb(210, 200, 190);

        .style-box, .color-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .style-label, .color-label{
                display: grid;
                justify-self: end;
                padding: 5px 5px 5px 0;
                grid-column: 1/6;
            }

            .style-value, .color-value{
                display: grid;
                grid-column: 6/11;
                padding: 5px 5px 5px 5px;
            }
        }
    }
    
    .measurements-box{
        display: grid;
        padding: 15px 0 20px;
        background: rgb(210, 200, 190);
        margin-bottom: 10px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .individual-measurements{
            display: grid;
            grid-template-columns: 200px 55px 145px ;
            padding: 5px 0;
            
            .measurement-type{
                align-self: center;
                padding-left: 50px;
            }
            
            .measurements-input{
                text-align: right;
                width: 50px;
            }

            .measurement-total-postfix{
                align-self: center;
                padding-left: 8px;
            }

            .measurement-static{
                text-align: right;
            }
        }

        .total-measurements{
            display: grid;
            grid-template-columns: 180px 20px 55px 145px;
            margin-top: 8px;

            .total-measurements-type{
                padding-left: 50px;
            }

            .total-measurements-sign{
                justify-self: center;
            }

            .total-measurements-total-value{
                justify-self: end;
            }

            .total-measurements-total-postfix{
                padding-left: 8px;
            }
        }

        .submit {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 20px 0 0;
            
            .button {
                justify-self: center;
                width: 180px;
                height: 30px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }
    }
`

export default PaverMeasurementsContainer;