import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth';
import Grid from "@mui/material/Grid";
import {MaterialReactTable} from "material-react-table";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddchartIcon from '@mui/icons-material/Addchart';
import Can from '../authentication/Can';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import XLSX from 'xlsx';

const JobsTable = ({message, jobs, setJobs, setCreateJob,
                    setEditJob, setJobToEdit, setDeleteJob, setJobToDelete}) => {
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    });

    const canEditJob = Can.isAuthorized('lots', 'WRITE');

    const columns = useMemo(() => [
        {
            id: 'builder',
            accessorKey: 'builder.name',
            header: 'Builder',
            enableEditing: false,
            minSize: 100,
            maxSize: 150
        },
        {
            id: 'project_name',
            accessorKey: 'project_name',
            header: 'Project Name',
            enableEditing: false,
            minSize: 200,
            maxSize: 250
        },
        {
            id: 'category',
            accessorKey: 'type.description',
            header: 'Category',
            enableEditing: false,
            minSize: 100,
            maxSize: 150
        },
        {
            id: 'job_number',
            accessorKey: 'number',
            header: 'Job #',
            enableEditing: false,
            minSize: 100,
            maxSize: 150,
        },
        {
            id: 'city',
            accessorKey: 'city',
            header: 'City',
            enableEditing: false,
            size: 150
        },
        {
            id: 'concrete',
            accessorFn: (row) => getAttributeColumnData(row, [1]),
            header: 'Concrete',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'plumbing',
            accessorFn: (row) => getAttributeColumnData(row, [2, 3, 4]),
            header: 'Plumbing',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'plumbing_gas',
            accessorFn: (row) => getAttributeColumnData(row, [5]),
            header: 'Plumbing Gas',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'rfg',
            accessorFn: (row) => getAttributeColumnData(row, [7]),
            header: 'RFG',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'trash',
            accessorFn: (row) => getAttributeColumnData(row, [8]),
            header: 'Trash',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'masonry',
            accessorFn: (row) => getAttributeColumnData(row, [9]),
            header: 'Masonry',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'pavers',
            accessorFn: (row) => getAttributeColumnData(row, [10]),
            header: 'Pavers',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'gates',
            accessorFn: (row) => getAttributeColumnData(row, [12]),
            header: 'Gates',
            enableEditing: false,
            size: 150,
        },
        {
            id: 'framing',
            accessorFn: (row) => getAttributeColumnData(row, [13]),
            header: 'Framing',
            enableEditing: false,
            size: 150,
        },
    ], []);

    const getAttributeColumnData = (job, attribute_ids) => {
        // Get attribute
        let attribute = null;
        for(let i = 0; i < attribute_ids.length; i++){
            attribute = job.attributes.find(att => parseInt(att.attribute_id) === attribute_ids[i]);
            if(attribute !== undefined){
                break;
            }
        }

        if(attribute === undefined){
            return '';
        }

        // If attribute is 5 or 10 check for 'optional'
        if(attribute_ids[0] === 5 && attribute !== undefined){
            attribute = job.attributes.find(att => parseInt(att.attribute_id) === 6);
            if(attribute !== undefined){
                return 'Opt';
            }
        }
        if(attribute_ids[0] === 10 && attribute !== undefined){
            attribute = job.attributes.find(att => parseInt(att.attribute_id) === 11);
            if(attribute !== undefined){
                return 'Opt';
            }
        }

        return 'X';
    }

    const hasAttribute = (job, attribute_id) => {
        return job.attributes.find(att => parseInt(att.attribute_id) === attribute_id) !== undefined;
    }

    const exportToXLSX = async () => {
        const exportData = [];
        for(let i = 0; i < jobs.length; i++){
            const numberOfLots = await Auth.hitEndpoint("GET", "STATS", `selector=eight&jobId=${jobs[i].id}`);

            let assigned = jobs[i].assignments.find(a => a.assignment.name === 'concreteGeneral');
            const concreteGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'concreteSuper');
            const concreteSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'plumbingGeneral');
            const plumbingGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'plumbingSuper');
            const plumbingSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'masonryGeneral');
            const masonryGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'masonrySuper');
            const masonrySuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'paverGeneral');
            const paverGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'paverSuper');
            const paverSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'rfgGeneral');
            const rfgGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'rfgSuper');
            const rfgSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'trashGeneral');
            const trashGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'trashSuper');
            const trashSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'gatesGeneral');
            const gatesGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'gatesSuper');
            const gatesSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'framingGeneral');
            const framingGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'framingSuper');
            const framingSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";

            exportData.push({
                'Active': jobs[i].active ? 'X' : '',
                'Builder': jobs[i].builder.name,
                'Project Name': jobs[i].project_name,
                'Type': jobs[i].type.description,
                'Job #': jobs[i].number,
                'City': jobs[i].city,
                'Cross Roads': jobs[i].cross_streets,
                'ZipCode': jobs[i].zip_code,
                'Lot Count': numberOfLots.stat,
                'Concrete General': concreteGeneral,
                'Concrete Super': concreteSuper,
                'Plumbing General': plumbingGeneral,
                'Plumbing Super': plumbingSuper,
                'Masonry General': masonryGeneral,
                'Masonry Super': masonrySuper,
                'Paver General': paverGeneral,
                'Paver Super': paverSuper,
                'RFGT General': rfgGeneral,
                'RFGT Super': rfgSuper,
                'Trash General': trashGeneral,
                'Trash Super': trashSuper,
                'Gates General': gatesGeneral,
                'Gates Super': gatesSuper,
                'Framing General': framingGeneral,
                'Framing Super': framingSuper,
                'Concrete': hasAttribute(jobs[i], 1) ? 'X' : '',
                'Plumbing Soil': hasAttribute(jobs[i], 2) ? 'X' : '',
                'Plumbing Topout': hasAttribute(jobs[i], 3) ? 'X' : '',
                'Plumbing Trim': hasAttribute(jobs[i], 4) ? 'X' : '',
                'Plumbing Gas Optional': hasAttribute(jobs[i], 6) ? 'X' : '',
                'Plumbing Gas': hasAttribute(jobs[i], 5) ? 'X' : '',
                'RFG': hasAttribute(jobs[i], 7) ? 'X' : '',
                'Trash': hasAttribute(jobs[i], 8) ? 'X' : '',
                'Masonry': hasAttribute(jobs[i], 9) ? 'X' : '',
                'Pavers Optional': hasAttribute(jobs[i], 11) ? 'X' : '',
                'Pavers': hasAttribute(jobs[i], 10) ? 'X' : '',
                'Gates': hasAttribute(jobs[i], 12) ? 'X' : '',
                'Framing': hasAttribute(jobs[i], 13) ? 'X' : '',
            });
        }

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Jobs');
        XLSX.writeFile(workbook, './Jobs.xlsx');
    }

    return (
        <MaterialReactTable
            muiTableContainerProps={{ sx: { maxWidth: '1880px', maxHeight: '600px' } }}
            columns={columns}
            data={jobs}
            layoutMode='grid-no-grow'
            enableColumnFilters
            enableStickyHeader
            enableColumnPinning
            onPaginationChange={(paginationState) => {
                if(!bypassPagination){
                    setPagination(paginationState);
                }
                setBypassPagination(false);
            }}
            initialState={{
                pagination: pagination,
                columnPinning: {left: ['mrt-row-actions', 'builder', 'project_name', 'category', 'job_number', 'city']}
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    size: 130,
                    grow: false,
                },
            }}
            renderTopToolbarCustomActions={({table}) => {
                return (
                    <Grid>
                        <Tooltip title="Open Create Job">
                            <IconButton onClick={() => setCreateJob(true)}>
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Export To XLSX">
                            <IconButton onClick={() => exportToXLSX()}>
                                <AddchartIcon />
                            </IconButton>
                        </Tooltip>
                        <Grid>
                            <span style={{fontWeight: 'bold'}}>Status: </span>
                            <span style={{fontWeight: 'bold', color: message.color}}>{message.message}</span>
                        </Grid>
                    </Grid>
                );
            }}
            // muiTableBodyCellProps={({ cell, row, table }) => {
                
            //     return {
            //         sx: {
            //             cursor: 'pointer'
            //         },
            //         onClick: () => {
            //             const attributeIds = [];
            //             attributeIds['concrete'] = 1;
            //             attributeIds['plumbing'] = 2;
            //             attributeIds['plumbing_gas'] = 5;
            //             attributeIds['rfg'] = 7;
            //             attributeIds['trash'] = 8;
            //             attributeIds['masonry'] = 9;
            //             attributeIds['pavers'] = 10;
            //             attributeIds['gates'] = 12;
            //             attributeIds['framing'] = 13;

                        
            //             const index = row.original.attributes.findIndex(a => parseInt(a.attribute_id) === attributeIds[cell.column.id]);
            //             if(index >= 0){
            //                 row.original.attributes.splice(index, 1);
            //             }
            //             else{
            //                 row.original.attributes.push({attribute_id: attributeIds[cell.column.id]});
            //             }
            //             const jobIndex = jobs.findIndex(j => parseInt(j.id) === parseInt(row.original.id));
            //             // jobs[jobIndex] = row.original;
            //             // setJobs({...jobs});

            //         }
            //     }
            // }}
            positionToolbarAlertBanner='hide'
            state={{pagination}}
            enableRowActions={true}
            renderRowActions={({ row, table }) => {
                return (
                    <Box sx={{ display: 'flex', gap: '1rem',}}>
                        {canEditJob &&
                        <Tooltip title="Edit">
                            <IconButton onClick={async () => {
                                const tempJob = row.original;
                                tempJob.active = parseInt(tempJob.active) === 1 || tempJob.active;
                                tempJob.builder.value = tempJob.builder.id;
                                tempJob.builder.label = tempJob.builder.name;
                                tempJob.job_type = tempJob.type;
                                tempJob.job_type.value = tempJob.type.id;
                                tempJob.job_type.label = tempJob.type.description;
                                tempJob.assignments = tempJob.assignments.map(a => {
                                    a.user.value = a.user.id;
                                    a.user.label = `${a.user.field.display} - ${a.user.firstname} ${a.user.lastname}`;
                                    return a;
                                })
                                setEditJob(true);
                                setJobToEdit(row.original);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>}
                        {canEditJob &&
                        <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => {
                                setJobToDelete(row.original);
                                setDeleteJob(true);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>}
                    </Box>
                )
            }}
        />
    );
}

export default JobsTable;