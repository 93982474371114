import { useState } from 'react';

import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { Button } from '../utilities/Button.js';
import ViewUsersContainer from './styles/ViewUsersContainer';

const ViewUsers = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        showInactiveUsers: false,
        role: '',
        position: '',
        permissionGroupID: '',
        field: ''
    })

    const [users, setUsers] = useState([]);

    const [roles, setRoles] = useState([]);
    const [positions, setPositions] = useState([]);
    const [fields, setFields] = useState([]);
    // const [permissions, setPermissions] = useState([]);
    const [permissionGroups, setPermissionGroups] = useState([]);

    const applyFilters = async () => {
        const tempUsers = await Auth.hitEndpoint("GET", "USERS");
        const filteredUsers = tempUsers.filter(user => {
            if(!filterOptions.showInactiveUsers && !user.active){
                return false;
            }
            if(filterOptions.role !== '' && Number.parseInt(filterOptions.role) !== user.role_id){
                return false;
            }
            if(filterOptions.position !== '' && Number.parseInt(filterOptions.position) !== user.position_id){
                return false;
            }
            if(filterOptions.field !== '' && Number.parseInt(filterOptions.field) !== user.field_id){
                return false;
            }
            if(filterOptions.permissionGroupID !== '' && Number.parseInt(filterOptions.permissionGroupID) !== parseInt(user.permission_group_id)){
                return false;
            }
            
            return true;
        });
        const sortedUsers = filteredUsers.sort((user1, user2) => user1.firstname < user2.firstname ? -1 : 1);
        setUsers(sortedUsers);
    }

    const loadData = async () => {
        setDidLoadData(true);

        setRoles(await Auth.hitEndpoint("GET", "USER_ROLES"));
        setPositions(await Auth.hitEndpoint("GET", "USER_POSITIONS"));
        setFields(await Auth.hitEndpoint("GET", "USER_FIELDS"));
        // setPermissions(await Auth.hitEndpoint("GET", "USER_PERMISSIONS"));
        setPermissionGroups(await Auth.hitEndpointNew("GET", 'permission/groups'));

        await applyFilters();
    }

    if(!didLoadData){
        loadData();
    }

    // const updateUser = async (user) => {

    //     user.permissions = user.permissions.map(permission => {
    //         delete permission.permission;
    //         return permission;
    //     })

    //     await Auth.hitEndpoint("PATCH", "USER", "", user);
    // }

    const toggleActive = async (user) => {
        const tempUser = {...user};
        delete tempUser.permissions;
        delete tempUser.name;
        delete tempUser.field;
        delete tempUser.position;
        delete tempUser.role;
        delete tempUser.permissionGroup;
        tempUser.active = !tempUser.active;
        await Auth.hitEndpointNew("PATCH", "user", "", tempUser);
        await applyFilters();
    }

    // const togglePermission = (user, permissionId, permissionName) => {
    //     if(!user.active){
    //         return;
    //     }
    //     const tempUsers = [...users];
    //     const userIndex = tempUsers.findIndex(userToFind => userToFind.id === user.id);
    //     const permissionIndex = tempUsers[userIndex].permissions.findIndex(permissionToFind => Number.parseInt(permissionToFind.permission_id) === permissionId);

    //     if(permissionIndex >= 0){
    //         tempUsers[userIndex].permissions[permissionIndex][permissionName] = tempUsers[userIndex].permissions[permissionIndex][permissionName] === 0 ? 1 : 0;
    //     }
    //     else{
    //         const tempPermission = {
    //             user_id: tempUsers[userIndex].id,
    //             permission_id: permissionId,
    //             view: 0,
    //             create: 0,
    //             edit: 0,
    //             delete: 0
    //         }
    //         tempPermission[permissionName] = 1;
    //         tempUsers[userIndex].permissions.push(tempPermission);
    //     }

    //     updateUser(tempUsers[userIndex])
    //     setUsers([...tempUsers]);
    // }
    
    // const columns = ['Active', 'Name', 'Role', 'User Name', 'Email', 'Position', 'Field', ...permissions.map(permissions => permissions.title)];
    const columns = ['Active', 'Name', 'Role', 'User Name', 'Email', 'Position', 'Field', 'Permission Group'];
    const content = users.map(user => ({
        name: `${user.firstname} ${user.lastname}`,
        active: user.active,
        id: user.id,
        role: user.role.name,
        username: user.username,
        email: user.email,
        position: user.position.name,
        field: user.field.name,
        permissionGroup: user.permission_group_id ? permissionGroups.find(pg => parseInt(pg.id) === parseInt(user.permission_group_id)) : {display: ''},
        // permissions: permissions.map(permission => {
        //     const currentPermission = user.permissions.find(userPermission => Number.parseInt(userPermission.permission_id) === permission.id);
        //     if(currentPermission !== undefined){
        //         return {
        //             id: Number.parseInt(currentPermission.permission_id),
        //             view: currentPermission.view,
        //             create: currentPermission.create,
        //             edit: currentPermission.edit,
        //             delete: currentPermission.delete,
        //             useView: permission.view,
        //             useCreate: permission.create,
        //             useEdit: permission.edit,
        //             useDelete: permission.delete,
        //         }
        //     }
        //     return {
        //         id: Number.parseInt(permission.id),
        //         view: 0,
        //         create: 0,
        //         edit: 0,
        //         delete: 0,
        //         useView: permission.view,
        //         useCreate: permission.create,
        //         useEdit: permission.edit,
        //         useDelete: permission.delete,
        //     }
        // })
    }))

    return (
        <ViewUsersContainer>
            <div className='top-boxes'>

                <div className='page-filter'>
                    <div className='filter-header'>View Users</div>
                    <CheckboxWithLabel className="show-inactive-users" name="show-inactive-users" label="Show Inactive Users" checked={filterOptions.showInactiveUsers} handleCheckbox={(e) => setFilterOptions({...filterOptions, showInactiveUsers: e.target.checked})}columns="10" labelStart="1" labelEnd="7" inputStart="7" inputEnd="10"/>
                    <DropDownWithLabel className="role" name="role" label="Role" handleDropDown={(e) => setFilterOptions({...filterOptions, role: e.target.value})} addBlankOption={true} selectionList={roles.map(role => ({id: role.id, display: role.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="position" name="position" label="Position" handleDropDown={(e) => setFilterOptions({...filterOptions, position: e.target.value})} addBlankOption={true} selectionList={positions.map(position => ({id: position.id, display: position.name}))}columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="field" name="field" label="Field" handleDropDown={(e) => setFilterOptions({...filterOptions, field: e.target.value})} addBlankOption={true} selectionList={fields.map(field => ({id: field.id, display: field.name}))}columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="permission-group" name="permission-group" label="Permission Group" handleDropDown={(e) => setFilterOptions({...filterOptions, permissionGroupID: e.target.value})} addBlankOption={true} selectionList={permissionGroups} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <Button className="apply-filters" handleKeyPress={((e) => {if(e.key === 'Enter'){applyFilters()}})} handleClick={((e) => applyFilters())} buttonText="Apply Filter" width="120"/>
                </div>
                {/* <div className='page-key'>
                    <div className='key-header'>Permissions Key</div>
                    <div className='key'>
                        <div className='key-pair'>
                            <div className='key-symbol'>V</div>
                            <div className='key-value'>View Permission</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-symbol'>C</div>
                            <div className='key-value'>Create Permission</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-symbol'>E</div>
                            <div className='key-value'>Edit Permission</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-symbol'>D</div>
                            <div className='key-value'>Delete Permission</div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div>
                <div className='table-header'>Users</div>
                <div className='grid-table'>
                    <div className='grid-table-header'>
                        {columns.map((column, index)=> {
                            let className = 'grid-table-header-data';
                            if(column === 'Name'){className = 'grid-table-header-data name';}
                            else{className = 'grid-table-header-data';}
                            return <div key={index} className={className}>{column}</div>
                        })}
                    </div>
                    {content.map((user, index) => {
                        const inactive = user.active ? '' : 'inactive';
                        const row = index % 2 === 0 ? 'row-one' : 'row-two';
                        return (
                            <div key={index} className='grid-table-row'>
                                <div className={`grid-table-row-data ${inactive}`} onClick={async () => toggleActive(user)}>{user.active ? 'X' : ''}</div>
                                <div className={`grid-table-row-data ${inactive} fixed ${row.toString()}`}>{user.name}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.role}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.username}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.email}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.position}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.field}</div>
                                <div className={`grid-table-row-data ${inactive}`}>{user.permissionGroup.display}</div>
                                {/* {user.permissions.map((permission, index) => {
                                    const viewIsUsed = permission.useView ? 'used' : 'not-used';
                                    const viewHasPermission = permission.view ? user.active ? 'has-permission' : 'inactive-white' : '';
                                    const viewClassName = `permission-value ${viewIsUsed} ${viewHasPermission}`

                                    const createIsUsed = permission.useCreate ? 'used' : 'not-used';
                                    const createHasPermission = permission.create ? user.active ? 'has-permission' : 'inactive-white' : '';
                                    const createClassName = `permission-value ${createIsUsed} ${createHasPermission}`

                                    const editIsUsed = permission.useEdit ? 'used' : 'not-used';
                                    const editHasPermission = permission.edit ? user.active ? 'has-permission' : 'inactive-white' : '';
                                    const editClassName = `permission-value ${editIsUsed} ${editHasPermission}`

                                    const deleteIsUsed = permission.useDelete ? 'used' : 'not-used';
                                    const deleteHasPermission = permission.delete ? user.active ? 'has-permission' : 'inactive-white' : '';
                                    const deleteClassName = `permission-value ${deleteIsUsed} ${deleteHasPermission}`

                                    return (
                                        <div key={`${index}-permissions`} className='grid-table-row-data permissions'>
                                            <div className={viewClassName} onClick={(e) => togglePermission(user, permission.id, 'view')}>V</div>
                                            <div className={createClassName} onClick={(e) => togglePermission(user, permission.id, 'create')}>C</div>
                                            <div className={editClassName} onClick={(e) => togglePermission(user, permission.id, 'edit')}>E</div>
                                            <div className={deleteClassName} onClick={(e) => togglePermission(user, permission.id, 'delete')}>D</div>
                                        </div>
                                    )
                                })} */}
                            </div>
                        )
                    })}
                </div>
            </div>
        </ViewUsersContainer>
    );
}

export default ViewUsers;