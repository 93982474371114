import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button';
import ReporterContainer from '../styles/ReporterContainer';

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import {logError} from "../../../utilities/error";

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '16mm',
        backgroundColor: 'white',
        width: '216mm',
        height: '280mm'
      },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        marginTop: '5mm',

        company: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '3mm',
            width: '30%',
            textAlign: 'center',
        },
        title: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '5mm',
            width: '40%',
            textAlign: 'center',
        },
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '195mm',
        width: '100%',
        fontSize: '3mm',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        columns: {
            marginLeft: '1pt',
            marginRight: '1pt',
            paddingTop: '1.5mm',
            paddingBottom: '1.5mm',
            backgroundColor: 'rgb(220, 220, 220)',
            fontFamily: 'Helvetica',
            fontSize: '2.5mm',
            textAlign: 'center',
        },
        cProjectName: {
            width: '18%'
        },
        cJobNumber: {
            width: '5%'
        },
        cLotNumber: {
            width: '5%'
        },
        cDate: {
            width: '5%'
        },
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',

        columns: {
            height: '6mm',
            marginLeft: '1pt',
            marginRight: '1pt',
            paddingTop: '1.5mm',
            fontFamily: 'Helvetica',
            fontSize: '2.5mm',
            textAlign: 'center',
        },
        info: {
            width: '91.5%',
            paddingLeft: '2mm',
            textAlign: 'start',
        },
        cProjectName: {
            width: '18%'
        },
        cJobNumber: {
            width: '5%'
        },
        cLotNumber: {
            width: '5%'
        },
        cDate: {
            width: '5%'
        },
    },
});

const WipCycleTime = () => {
    const [buttonText, setButtonText] = useState('WIP Cycle Time Report - Concrete');

    const [data, setData] = useState([]);

    const createWIPCycleTimeReportConcrete = async () => {
        setButtonText('Fetching Report...');

        let stat = await Auth.hitEndpoint("GET", "STATS", `selector=nineteen`);

        if(stat.length === 0){
            logError("NO DATA RETURNED");
            return;
        }

        let currentFirstName = '';
        let currentBuilder = '';
        const tempData = [];
        for(let i = 0; i < stat.length; i++){
            if(stat[i].firstname !== currentFirstName){
                currentFirstName = stat[i].firstname;
                tempData.push({
                    lineType: 'name',
                    firstname: stat[i].firstname,
                    lastname: stat[i].lastname
                })
            }
            if(stat[i].name !== currentBuilder){
                currentBuilder = stat[i].name;
                tempData.push({
                    lineType: 'builder',
                    builderName: stat[i].name
                })
            }

            const layout                = stat[i].phase_tasks.find(pt => pt.id === 5);
            const excavateFooting       = stat[i].phase_tasks.find(pt => pt.id === 8);
            const spreadPerimeter       = stat[i].phase_tasks.find(pt => pt.id === 10);
            const setPerimeter          = stat[i].phase_tasks.find(pt => pt.id === 11);
            const soilLabor             = stat[i].phase_tasks.find(pt => pt.id === 21);
            const deliverABC            = stat[i].phase_tasks.find(pt => pt.id === 27);
            const backfillCompact       = stat[i].phase_tasks.find(pt => pt.id === 31);
            const roughGradeSpreadABC   = stat[i].phase_tasks.find(pt => pt.id === 32);
            const setFloorLinePerm      = stat[i].phase_tasks.find(pt => pt.id === 33);
            const boxWrapLabor          = stat[i].phase_tasks.find(pt => pt.id === 36);
            const finishFloor           = stat[i].phase_tasks.find(pt => pt.id === 41);

            tempData.push({
                lineType: 'data',
                projectName: stat[i].project_name,
                jobNumber: stat[i].job_number,
                lotNumber: stat[i].lot_number,
                releaseDate: dayjs(new Date(stat[i].release_date)).format('MM-DD-YYYY'),     
                layout:               layout !== undefined && layout.completed_date !== null ? dayjs(new Date(layout.completed_date)).format('MM-DD-YYYY') : '',
                excavateFooting:      excavateFooting !== undefined && excavateFooting.completed_date !== null ? dayjs(new Date(excavateFooting.completed_date)).format('MM-DD-YYYY') : '',          
                spreadPerimeter:      spreadPerimeter !== undefined && spreadPerimeter.completed_date !== null ? dayjs(new Date(spreadPerimeter.completed_date)).format('MM-DD-YYYY') : '',          
                setPerimeter:         setPerimeter !== undefined && setPerimeter.completed_date !== null ? dayjs(new Date(setPerimeter.completed_date)).format('MM-DD-YYYY') : '',                
                soilLabor:            soilLabor !== undefined && soilLabor.completed_date !== null ? dayjs(new Date(soilLabor.completed_date)).format('MM-DD-YYYY') : '',                      
                deliverABC:           backfillCompact !== undefined && backfillCompact.completed_date !== null ? dayjs(new Date(backfillCompact.completed_date)).format('MM-DD-YYYY') : '',          
                backfillCompact:      deliverABC !== undefined && deliverABC.completed_date !== null ? dayjs(new Date(deliverABC.completed_date)).format('MM-DD-YYYY') : '',                    
                roughGradeSpreadABC:  roughGradeSpreadABC !== undefined && roughGradeSpreadABC.completed_date !== null ? dayjs(new Date(roughGradeSpreadABC.completed_date)).format('MM-DD-YYYY') : '',  
                setFloorLinePerm:     setFloorLinePerm !== undefined && setFloorLinePerm.completed_date !== null ? dayjs(new Date(setFloorLinePerm.completed_date)).format('MM-DD-YYYY') : '',        
                boxWrapLabor:         boxWrapLabor !== undefined && boxWrapLabor.completed_date !== null ? dayjs(new Date(boxWrapLabor.completed_date)).format('MM-DD-YYYY') : '',                
                finishFloor:          finishFloor !== undefined && finishFloor.completed_date !== null ? dayjs(new Date(finishFloor.completed_date)).format('MM-DD-YYYY') : '',                  
            })
        }

        setData(tempData);

        setButtonText('WIP Cycle Time Report - Concrete');
    }

    return (
        <ReporterContainer>
            <Button 
                className="WIP-concrete-report" 
                handleKeyPress={((e) => {
                    if (e.key === 'Enter') {
                        createWIPCycleTimeReportConcrete()
                    }
                })} 
                handleClick={((e) => createWIPCycleTimeReportConcrete())} 
                buttonText={buttonText} 
                height="40" 
                width="300"
            />
            <PDFViewer style={styles.viewer}>
                <Document title="User Login Report">
                    <Page size="LETTER" orientation="landscape"  style={styles.page} wrap>
                        <View style={styles.header} fixed>
                            <View style={styles.header.company}>
                                <Text>SIERRA VERDE CONSTRUCTION, LLC</Text>
                            </View>
                            <View style={styles.header.title}>
                                <Text>CYCLE TIME - EVENFLOW REPORT</Text>
                            </View>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cProjectName]}>Project Name</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cJobNumber]}>Job #</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cLotNumber]}>Lot #</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Release</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Layout</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Excavate Footing</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Spread Perimeter</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Set Perimeter</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Soil Labot</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Backfill & Compact</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Deliver ABC</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Rough Grade & Spread ABC</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Set Floor & Line Perm</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Box & Wrap Labor</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.cDate]}>Finish Floor</Text>
                        </View>
                        <View wrap>
                            {data.map((d, index) => {
                                if(d.lineType === 'name'){
                                    const color = 'rgb(252, 230, 212)';
                                    return (
                                        <View key={`${d.id}-${index}-name`} style={styles.tableRow}>
                                            <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.info]}>{`${d.firstname} ${d.lastname}`}</Text>
                                        </View>
                                    )
                                }
                                if(d.lineType === 'builder'){
                                    const color = 'rgb(217, 217, 217)';
                                    return (
                                        <View key={`${d.id}-${index}-builder`} style={styles.tableRow}>
                                            <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.info]}>{d.builderName}</Text>
                                        </View>
                                    )
                                }

                                const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';   
                                
                                return (
                                    <View key={`${d.id}-${index}-data`} style={styles.tableRow}>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cProjectName]}>{d.projectName}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cJobNumber]}>{d.jobNumber}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cLotNumber]}>{d.lotNumber}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.releaseDate}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.layout}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.excavateFooting}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.spreadPerimeter}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.setPerimeter}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.soilLabor}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.deliverABC}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.backfillCompact}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.roughGradeSpreadABC}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.setFloorLinePerm}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.boxWrapLabor}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.finishFloor}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </ReporterContainer>
    );
}

export default WipCycleTime;
