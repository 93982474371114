import {useState} from 'react';
import moment from "moment/moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import Auth from '../authentication/Auth.js';
import { DateboxWithLabel } from './Datebox.js';
import { Button } from './Button.js';
import MainHeader from '../utilities/MainHeader';
import Popup, {PopupSuccessColor} from '../utilities/Popup.js';
import MessagerContainer from './styles/MessagerContainer.js';

const Messager = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [message, setMessage] = useState({});
    const [devMessage, setDevMessage] = useState({});

    const [regSuccess, setRegSuccess] = useState(false);
    const [devSuccess, setDevSuccess] = useState(false);

    const canEditMessages = props.isAuthorized('messaging', 'WRITE');
    const canEditDevMessages = props.isAuthorized('messaging', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);

        const tempMessages = await Auth.hitEndpointNew("GET", "messages");
        for(let i = 0; i < tempMessages.length; i++){
            if(tempMessages[i].expires !== null){
                let date = tempMessages[i].expires
               // result[i].expires = new Date(date);
                tempMessages[i].expires = new Date(date);
            }

            if(tempMessages[i].type === "message"){
                setMessage(tempMessages[i]);
            }
            if(tempMessages[i].type === "dev-message"){
                setDevMessage(tempMessages[i]);
            }
        }
    }

    if(!didLoadData){
        loadData();
    }

    const updateMessage = async (message) => {
        if(message.message === ''){
            message.expires = null;
        }

        const dbMessageObj = {};
        if(message.expires !== undefined){ dbMessageObj.expires = message.expires && message.expires !== null ? moment(new Date(message.expires)).format('YYYY-MM-DD H:mm:ss') : null; }
        if(message.id !== undefined){ dbMessageObj.id = message.id; }
        if(message.message !== undefined){ dbMessageObj.message = message.message; }
        if(message.modified_at !== undefined){ dbMessageObj.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss'); }
        if(message.modified_by !== undefined){ dbMessageObj.modified_by = message.modified_by; }
        if(message.type !== undefined){ dbMessageObj.type = message.type; }

        await Auth.hitEndpointNew("PATCH", "message", "", dbMessageObj);
    }

    const handleSubmitMessage = () => {
        updateMessage(message);
        setRegSuccess(true);
    }

    const handleSubmitDevMessage = () => {
        updateMessage(devMessage);
        setDevSuccess(true);
    }

    const togglePopup = () => {
        setRegSuccess(false);
        setDevSuccess(false);
    }

    return (
        <MessagerContainer>
            <div className='messager-header'>Messaging</div>
            {canEditMessages &&
            <div className='message-text-area'>
                <label className="message-text-label" htmlFor='message'>Message</label>
                <textarea className="message-text-input" id='message' name='message' rows='10' cols='60' placeholder='Enter Message, leave blank to remove message'
                          value={message.message} onChange={((e) => setMessage({...message, message: e.target.value}))}/>
                <DateboxWithLabel className="message-expire-date" label="Message Expires On" selected={message.expires} handleDatebox={date => setMessage({...message, expires: date})} enableTabLoop={false} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={6} inputStart={6} inputEnd={9}/>
                <Button className="update-message" handleKeyPress={((e) => {if(e.key === 'Enter'){handleSubmitMessage()}})} handleClick={((e) => handleSubmitMessage())} buttonText="Update Message" width="140"/>
            </div>}
            {canEditDevMessages &&
            <div className='message-text-area'>
                <label className="message-text-label" htmlFor='message'>Dev Message</label>
                <textarea className="message-text-input" id='message' name='message' rows='10' cols='60' placeholder='Enter Dev Message, leave blank to remove message'
                          value={devMessage.message} onChange={((e) => setDevMessage({...devMessage, message: e.target.value}))}/>
                <DateboxWithLabel className="dev-message-expire-date" label="Message Expires On" selected={devMessage.expires} handleDatebox={date => setDevMessage({...devMessage, expires: date})} enableTabLoop={false} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={6} inputStart={6} inputEnd={9}/>
                <Button className="update-dev-message" handleKeyPress={((e) => {if(e.key === 'Enter'){handleSubmitDevMessage()}})} handleClick={((e) => handleSubmitDevMessage())} buttonText="Update Message" width="140"/>
            </div>}
            {regSuccess && <Popup color={PopupSuccessColor} message={`Message has been updated.`} handlePopup={togglePopup}/>}
            {devSuccess && <Popup color={PopupSuccessColor} message={`Dev message has been updated.`} handlePopup={togglePopup}/>}
        </MessagerContainer>
    )
};

export default Messager;