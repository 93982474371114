import styled from 'styled-components';

const PlumbingCameraQAFormContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 0;

    .qa-form-sub-header{
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
        border-top: 2px solid white;
    }

    .qa-form-box{
        display: grid;
        background: rgb(210, 200, 190);
        margin-bottom: 20px;

        .qa-form-question-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);
            align-items: center;
            padding: 0 0 10px;
            // separating lines
            // margin: 0 5px 10px 5px;
            // border-bottom: 2px solid black;

            .qa-form-show-all-history-submit {
                grid-column: 1/21;
                display: grid;
                justify-content: center;
                align-items: center;
                padding: 15px 0;
                
                .qa-form-show-all-history-button {
                    justify-self: center;
                    width: 140px;
                    height: 25px;
                    background: rgb(240, 240, 240);
                    border: 1px solid rgb(120, 120, 120);
                    border-radius: 3px;
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    cursor: default;
                    
                    :hover {
                        background: rgb(230, 230, 230);
                    }
                }
            }

            .qa-form-question-number{
                grid-column: 1/3;
                align-self: start;
                text-align: right;
                padding-right: 5px;
            }

            .qa-form-question-question{
                grid-column: 3/13;
                align-self: start;
                line-height: 110%;
            }

            .qa-form-radio-button-yes{
                grid-column: 13/14;
                align-self: start;
                // cursor: pointer;
            }

            .qa-form-radio-label-yes{
                grid-column: 14/16;
                align-self: start;
            }

            .qa-form-radio-button-no{
                grid-column: 16/17;
                align-self: start;
                // cursor: pointer;
            }

            .qa-form-radio-label-no{
                grid-column: 17/19;
                align-self: start;
            }

            .qa-form-history-button{
                grid-column: 19/21;
                align-self: start;
                justify-self: center;
                width: 25px;
                height: 20px;
                padding-bottom: 3px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }

            .qa-form-question-history-box{
                grid-column: 1/21;
                display: grid;
                grid-template-columns: repeat(20, 1fr);

                .qa-form-question-history{
                    grid-column: 1/21;
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);
                    margin: 5px 0;

                    .qa-form-question-history-date{
                        grid-column: 3/8;
                    }

                    .qa-form-question-history-name{
                        grid-column: 8/16;
                    }

                    .qa-form-question-history-result{
                        grid-column: 16/21;
                    }
                }

                .qa-form-question-no-history{
                    grid-column: 1/21;
                    display: grid;
                    justify-content: center;
                    padding-top: 5px;
                }
            }
        }

        .qa-form-comment-box{
            display: grid;
            padding-bottom: 15px;

            .qa-form-comments-header{
                font-weight: bold;
                padding: 5px 10px;
            }

            .qa-form-comment-history{
                display: grid;
                grid-template-columns: repeat(20, 1fr);

                .qa-form-comment-history-date{
                    grid-column: 1/6;
                    padding: 5px 10px;
                }

                .qa-form-comment-history-name{
                    grid-column: 6/21;
                    padding: 5px 0;
                }
                
                .qa-form-comment-history-comment{
                    grid-column: 1/21;
                    padding: 0 15px 5px;
                }
            }

            .qa-form-comment-text-area{
                display: grid;
                justify-content: center;
                padding: 10px 0 0;
            }
        }

        .qa-form-submit {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 10px 0 20px;
            
            .qa-form-submit-button {
                justify-self: center;
                width: 200px;
                height: 30px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }

    //     .individual-measurements{
    //         display: grid;
    //         grid-template-columns: 200px 55px 145px ;
    //         padding: 5px 0;
            
    //         .measurement-type{
    //             align-self: center;
    //             padding-left: 50px;
    //         }
            
    //         .measurements-input{
    //             text-align: right;
    //             width: 50px;
    //         }

    //         .measurement-total-postfix{
    //             align-self: center;
    //             padding-left: 8px;
    //         }

    //         .measurement-static{
    //             text-align: right;
    //         }
    //     }

    //     .total-measurements{
    //         display: grid;
    //         grid-template-columns: 180px 20px 55px 145px;
    //         margin-top: 8px;

    //         .total-measurements-type{
    //             padding-left: 50px;
    //         }

    //         .total-measurements-sign{
    //             justify-self: center;
    //         }

    //         .total-measurements-total-value{
    //             justify-self: end;
    //         }

    //         .total-measurements-total-postfix{
    //             padding-left: 8px;
    //         }
    //     }

    //     .submit {
    //         display: grid;
    //         justify-content: center;
    //         align-items: center;
    //         padding: 20px 0 0;
            
    //         .button {
    //             justify-self: center;
    //             width: 180px;
    //             height: 30px;
    //             background: rgb(240, 240, 240);
    //             border: 1px solid rgb(120, 120, 120);
    //             border-radius: 3px;
    //             display: grid;
    //             justify-content: center;
    //             align-items: center;
    //             cursor: default;
                
    //             :hover {
    //                 background: rgb(230, 230, 230);
    //             }
    //         }
    //     }
    }
`

export default PlumbingCameraQAFormContainer;