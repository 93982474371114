import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {useState} from "react";
import RoutingFilter from "./RoutingFilter";
import RoutingContainer from "../styles/RoutingContainer";
import DialogActions from "@mui/material/DialogActions";
import Schedules from "../../../classes/Schedules";

const RoutingData = ({phases, schedule, setSchedule, editDetails, setEditDetails, selectedTasks, setOpen, crews, setCrews, minHeight, marginTop}) => {

    const [isExisting, setIsExisting] = useState(false)
    const [routeSchedule, setRouteSchedule] = useState([])

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        padding: "20px",
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        border: "1pt solid",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        marginTop: marginTop,
        width: "100%",
        minHeight: minHeight,
    });

    // function handleOnDragEnd(result) {
    //     const items = []//Array.from(selectedEmployeesEdit);
    //     let sourceItem = items[result.source.index]
    //     if (!result.destination) {
    //         return
    //     }
    //     else {
    //         const [reorderedItem] = items.splice(result.source.index, 1);
    //         items.splice(result.destination.index, 0, reorderedItem);
    //         setRouteSchedule(items);
    //     }
    // }
    function handleOnDragEnd(result) {
        const items = Array.from(routeSchedule);
        let sourceItem = items[result.source.index]
        if (!result.destination) {
            return
        }
        else {
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            for(let s=0; s<items.length; s++){
                items[s].routing_seq = (s + 1)
            }
            setRouteSchedule(items);
        }
    }

    const handleRoutingCancel = () => {
        setOpen(false)
    }
    const handleRoutingSave = async () => {
        setSchedule(await Schedules.handleRoutingSave(schedule, routeSchedule))
        setOpen(false)
    }

    return (
        <RoutingContainer>
            <RoutingFilter
                phases={phases}
                crews={crews}
                setCrews={setCrews}
                schedule={schedule}
                routeSchedule={routeSchedule}
                setRouteSchedule={setRouteSchedule}
            />

            <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable" style={{overflow:"auto"}}>
                {(droppableProvided, droppableSnapshot) => (
                    <div
                        ref={droppableProvided.innerRef}
                        style={getListStyle(droppableSnapshot.isDraggingOver)}
                    >
                        {routeSchedule.sort((a,b) => a.routing_seq - b.routing_seq).map((item, index) => {
                            return (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(draggableProvided, draggableSnapshot) => (
                                        <div
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            style={getItemStyle(
                                                draggableSnapshot.isDragging,
                                                draggableProvided.draggableProps.style,
                                            )}>
                                            <div style={{width: "20%", float: "left", marginTop:"-5px"}}>{item.lot.job.number} {item.lot.number} {item.lot.phase}</div>
                                            <div style={{width: "20%", float: "left", marginTop:"-5px"}}>{item.lot.address} {item.lot.job.city}, {item.lot.job.state} {item.lot.job.zip}</div>
                                            <div style={{width: "20%", float: "left", marginTop:"-5px"}}>{item.task.code !== null ? item.task.code : ''} {item.task.name}</div>
                                            <div style={{width: "30%", float: "left", marginTop:"-5px"}}>{item.notes}</div>
                                            <div style={{width: "10%", float: "left", marginTop:"-5px"}}>{item.routing_seq}</div>
                                        </div>
                                    )}
                                </Draggable>
                            )})}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

            <DialogActions>
                <Button onClick={handleRoutingCancel} value={1}>Cancel</Button>
                <Button onClick={handleRoutingSave} value={0}>Save</Button>

            </DialogActions>
        </RoutingContainer>
    )
}

export default RoutingData;