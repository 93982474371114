import styled from 'styled-components';

const GateInformationContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 0;

    .gate-information-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .gate-information-sub-header{
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
    }

    .release-date-box{
        display: grid;
        background: rgb(210, 200, 190);
        margin-bottom: 10px;

        .gate-information-release-date-box,
        .gate-information-release-status-box,
        .gate-information-lot-notes-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .gate-information-release-date-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 15px 5px 5px 0;
            }

            .gate-information-release-date-value{
                grid-column: 6/11;
                padding: 15px 0 5px 5px;
            }

            .gate-information-release-status-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .gate-information-release-status-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;
            }

            .gate-information-lot-notes-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .gate-information-lot-notes-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;

                .add-note-button-box {
                    display: grid;
                    justify-content: start;
                    margin-top: -2px;
                    
                    .add-note-button {
                        width: 100px;
                        height: 22px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }
    }

    .gate-information-box{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        background: rgb(210, 200, 190);
        padding: 10px 0;
        margin: 10px 0;
        cursor: pointer;
        border: 2px solid black;
        border-radius: 5px;

        .information-label{
            display: grid;
            grid-column: 2/6;
            justify-self: end;
            padding-right: 5px;
            margin: 10px 0;
        }

        .information-data{
            display: grid;
            grid-column: 6/11;
            align-items: center;
        }
    }

    .gate-information-input-box{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        background: rgb(210, 200, 190);
        padding: 10px 0 15px;
        border: 2px solid black;
        border-radius: 5px;

        .gate-information-width{
            grid-column: 1/8;
        }

        .gate-information-measurement-type{
            grid-column: 8/11;
        }

        .gate-information-decorative-iron,
        .gate-information-handedness,
        .gate-information-level,
        .gate-information-submit{
            grid-column: 1/11;
        }

    }
`

export default GateInformationContainer;