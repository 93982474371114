import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button';
import ReporterContainer from '../styles/ReporterContainer';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '20mm',
        backgroundColor: 'white',
        width: '280mm',
        height: '216mm'
      },
    companyInfo: {
        margin: '7mm auto 0',
        fontSize: '10mm',
        width: '55%',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        borderBottom: '1mm solid black'
    },
    docTitle: {
        margin: '3mm auto 0',
        padding: '2mm 0',
        fontSize: '8mm',
        width: '40%',
        textAlign: 'center',
        fontFamily: 'Helvetica',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '170mm',
        width: '100%',
        fontSize: '3mm',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',
    },
    columns: {
        marginLeft: '.5mm',
        marginRight: '.5mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        fontFamily: 'Helvetica',
        fontSize: '3mm',
        textAlign: 'center',
    },
});

const GatesReport = () => {
    const [gates, setGates] = useState([]);

    const [loadParameters, setLoadParameters] = useState({
        startDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0).subtract(3, 'day'),
        endDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(1, 'day'),
    })

    const [buttonText, setButtonText] = useState('Create Gates Report');

    const createGatesReport = async () => {
        setButtonText('Fetching Report...');

        let whereStr = 'queryString=gate_information.width > 0';
        if(loadParameters.startDate !== null){
            whereStr += ` and gate_information.created_at >= '${dayjs(new Date(loadParameters.startDate)).format('YYYY-MM-DD')}'`;
        }
        if(loadParameters.endDate !== null){
            whereStr += ` and gate_information.created_at < '${dayjs(new Date(loadParameters.endDate)).format('YYYY-MM-DD')}'`;
        }
        const tempLots = await Auth.hitEndpointNew("GET", `lots/index-new`, whereStr);
        const tempGates = tempLots.map(l => {
            l.gate_information.forEach(g => g.lot = l);
            return l.gate_information;
        }).flat();

        tempGates.sort((g1, g2) => {
            const g1jn = parseInt(g1.lot.job.number);
            const g2jn = parseInt(g2.lot.job.number);
            const g1ln = parseInt(g1.lot.number);
            const g2ln = parseInt(g2.lot.number);
            if(g1jn === g2jn){
                if(g1ln === g2ln){
                    return g1.lot.phase < g2.lot.phase ? -1 : 1;
                }
                return g1ln < g2ln ? -1 : 1;
            }
            return g1jn < g2jn ? -1 : 1;
        });
        setGates(tempGates.filter(g => g.deleted_at === null));

        setButtonText('Create Gates Report');
    }

    return (
        <ReporterContainer>
            <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                <div style={{marginBottom: "5px"}}>Start Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, startDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                                // variant: 'standard',
                            },
                        }}
                        defaultValue={loadParameters.startDate}
                    />
                </LocalizationProvider>
            </div>
            <div style={{justifySelf: "center", width: "300px", marginBottom: "20px"}}>
                <div style={{marginBottom: "5px"}}>End Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, endDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                                // variant: 'standard',
                            },
                        }}
                        defaultValue={loadParameters.endDate}
                    />
                </LocalizationProvider>
            </div>
            <Button
                className="gates-report"
                handleKeyPress={((e) => {
                    if (e.key === 'Enter') {
                        createGatesReport()
                    }
                })}
                handleClick={((e) => createGatesReport())}
                buttonText={buttonText}
                height="40"
                width="300"
            />
            <PDFViewer style={styles.viewer}>
                <Document title="Gates Report">
                    <Page orientation="landscape" size="LETTER" style={styles.page} wrap>
                        <View style={styles.companyInfo} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.docTitle} fixed>
                            <Text>Gates Report</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '12mm'}]}>Job #</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '12mm'}]}>Lot #</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '28mm'}]}>Lot Phase</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '55mm'}]}>Project</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Built</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Shipped</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '20mm'}]}>Invoice #</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '20mm'}]}>Width</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '20mm'}]}>Handedness</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Decorative Iron</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Level</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '12mm'}]}>Created By</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Created On</Text>
                        </View>
                        {gates.map((g, index) => {
                            const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';
                            return (
                                <View key={`${g.id}-${index}`} style={styles.tableRow} wrap={false}>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '12mm'}]}>{g.lot.job.number}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '12mm'}]}>{g.lot.number}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '28mm'}]}>{g.lot.phase}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '55mm'}]}>{g.lot.job.project_name}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{g.built ? 'X' : ''}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{g.shipped ? 'X' : ''}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '20mm'}]}>{g.invoice_number}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '20mm'}]}>{g.measurement_type ? `${g.width} ${g.measurement_type.name}` : g.width}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '20mm'}]}>{g.handedness}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{g.decorative_iron ? 'X' : ''}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{g.level}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '12mm'}]}>{g.user ? `${g.user.firstname[0]}.${g.user.lastname[0]}.` : ''}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{g.created_at ? dayjs(g.created_at).format('MM/DD/YYYY') : ''}</Text>
                                </View>
                            )
                        })}
                    </Page>
                </Document>
            </PDFViewer>
        </ReporterContainer>
    );
}

export default GatesReport;
