import Grid from '@mui/material/Grid';

const EmployeesToRemove = ({employeesToRemove}) => {
    return (
        <Grid 
            sx={{
                display: 'grid',
                justifyContent: 'center',
                width: 600, 
                padding: '10px 0',
                marginBottom: '10px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '5px',
                zIndex: '49',
                backgroundColor: 'rgb(255, 255, 150)'
            }}
        >
            <Grid
                sx={{
                    margin: '5px 0'
                }}
            >
                Please remove the following employees from crews:
            </Grid>
            {employeesToRemove.map(e => {
                return (
                    <Grid 
                        key={e.id}
                        sx={{
                            margin: '5px 0'
                        }}
                    >
                        {`${e.first_name} ${e.last_name} - Crew ${e.crews}`}
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default EmployeesToRemove;