import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth';
import Grid from "@mui/material/Grid";
import {MaterialReactTable} from "material-react-table";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Can from '../authentication/Can';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const CrewsTable = ({message, crews, setCreateCrew,
                    setEditCrew, setCrewToEdit, setDeleteCrew, setCrewToDelete}) => {
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    });

    const canEditCrew = Can.isAuthorized('employees', 'WRITE');

    const columns = useMemo(() => [
        {
            accessorKey: 'number',
            header: 'Crew #',
            enableEditing: false,
            size: 250
        },
        {
            accessorFn: (row) => row.scope === null ? "" : row.scope.name,
            header: 'Scope',
            enableEditing: false,
            size: 300
        },
        {
            header: 'Members',
            enableEditing: false,
            size: 400,
            Cell: ({row}) => {
                const members = row.original.members.sort((m1, m2) => m1.position === "Leader" ? -1 : 1);
                return (
                    <Grid
                        sx={{
                            margin: '5px 0'
                        }}
                    >
                        {members.map((m, i) => {
                            let display = "(" + m.position[0] + ")";
                            display += m.employee.first_name + " " + m.employee.last_name;
                            display += m.employee.alias !== null && m.employee.alias !== "" ? (" - " + m.employee.alias) : '';
                            return (
                                <Grid key={i}>
                                    {display}
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            }
        },
        {
            accessorKey: 'company',
            header: 'Company',
            enableEditing: false,
            size: 300
        },
        {
            accessorKey: 'phone_number',
            header: 'Phone #',
            enableEditing: false,
            size: 200
        },
    ], []);

    return (
        <Box
            sx={{
                width: '1600px'
            }}
        >
            <MaterialReactTable
                muiTableContainerProps={{ sx: {maxHeight: '600px' } }}
                columns={columns}
                data={crews}
                layoutMode='grid-no-grow'
                enableColumnFilters
                enableStickyHeader
                onPaginationChange={(paginationState) => {
                    if(!bypassPagination){
                        setPagination(paginationState);
                    }
                    setBypassPagination(false);
                }}
                initialState={{
                    pagination: pagination,
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 130,
                        grow: false,
                    },
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Grid>
                            <Tooltip title="Open Create Crew">
                                <IconButton onClick={() => setCreateCrew(true)}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                            <Grid>
                                <span style={{fontWeight: 'bold'}}>Status: </span>
                                <span style={{fontWeight: 'bold', color: message.color}}>{message.message}</span>
                            </Grid>
                        </Grid>
                    );
                }}
                muiTableBodyCellProps={({ row }) => {
                    let bgColor = row.original.active || row.original.active === '1' ? 'white' : 'rgb(255, 150, 150)';
                    return {
                        sx: {
                            backgroundColor: bgColor,
                            padding: '0'
                        }
                    }
                }}
                positionToolbarAlertBanner='hide'
                state={{pagination}}
                enableRowActions={true}
                renderRowActions={({ row, table }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem',}}>
                            {canEditCrew &&
                            <Tooltip title="Edit">
                                <IconButton onClick={async () => {
                                    setEditCrew(true);
                                    const tempCrewDB = (await Auth.hitEndpointNew("GET", "crews-new", `jsonString=id=${row.original.id}`))[0]
                                    const tempCrew = {
                                        ...tempCrewDB,
                                        active: tempCrewDB.active === 1,
                                        scope: tempCrewDB.crew_scope_id !== null && tempCrewDB.crew_scope_id !== "" ? {
                                            ...tempCrewDB.scope,
                                            value: tempCrewDB.scope.id,
                                            label: tempCrewDB.scope.name
                                        } : null,
                                        members: tempCrewDB.members.map(m => {
                                            const e = {...m.employee}
                                            let label = e.crews !== null && e.crews !== "" ? `${e.crews} - ` : "";
                                            label += e.alias !== null && e.alias !== "" ? `${e.alias} - (${e.first_name} ${e.last_name})` : `${e.first_name} ${e.last_name}`
                                            return {
                                                ...m.employee,
                                                value: m.employee.id,
                                                label: label,
                                                position: {
                                                    value: m.position,
                                                    label: m.position
                                                }

                                            }
                                        }),
                                        scheduleReports: tempCrewDB.scheduleReports.map(sr => {
                                            return {
                                                ...sr.report,
                                                value: sr.report.id,
                                                label: sr.report.display,
                                                reportId: sr.id
                                            }
                                        })
                                    }
                                    setCrewToEdit(tempCrew);
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>}
                            {canEditCrew &&
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={async () => {
                                    if(!row.original.active){
                                        return;
                                    }
                                    setCrewToDelete((await Auth.hitEndpointNew("GET", "crews-new", `jsonString=id=${row.original.id}`))[0]);
                                    // setCrewToDelete({...row.original});
                                    setDeleteCrew(true);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>}
                        </Box>
                    )
                }}
            />
        </Box>
    );
}

export default CrewsTable;