import { useState } from 'react';
import Auth from '../../authentication/Auth.js';
import MainHeader from '../../utilities/MainHeader.js';
import EmployeesFilter from './EmployeesFilter.js';
import EmployeesTable from './EmployeesTable.js';
import CreateEmployee from './CreateEmployee.js';
import EditEmployee from './EditEmployee.js';
import DeleteEmployee from './DeleteEmployee.js';
import Box from '@mui/material/Box';

const EmployeesPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        includeDeleted: false,
    });

    const [message, setMessage] = useState({
        color: '',
        message: ''
    });

    const [employees, setEmployees] = useState([]);

    const [createEmployee, setCreateEmployee] = useState(false);
    const [editEmployee, setEditEmployee] = useState(false);
    const [employeeToEdit, setEmployeeToEdit] = useState(null);
    const [deleteEmployee, setDeleteEmployee] = useState(false);
    const [employeeToDelete, setEmployeeToDelete] = useState(null);

    const loadData = async () => {
        setDidLoadData(true);

        let tempEmployees = await Auth.hitEndpointNew("GET", "employees-new");
        if(!filters.includeDeleted){
            tempEmployees = tempEmployees.filter(e => parseInt(e.active) === 1);
        }
        setEmployees(tempEmployees.sort((e1, e2) => {
            return e1.first_name < e2.first_name ? -1 : 1;
        }))
    }

    if(!didLoadData){
        loadData();
    }

    const updateEmployeesArray = async (employee) => {
        let tempEmployees = [...employees];

        const jsonString = `jsonString=employee.first_name='${employee.first_name}' and employee.last_name='${employee.last_name}' and employee.qb_id='${employee.qb_id}'`;
        const tempEmployee = (await Auth.hitEndpointNew('GET', 'employees-new', jsonString))[0];

        const index = tempEmployees.findIndex(e => parseInt(e.id) === parseInt(tempEmployee.id));
        if(index >= 0){
            tempEmployees[index] = {...tempEmployee}
        }
        else{
            tempEmployees.push({...tempEmployee});
        }

        if(!filters.includeDeleted){
            tempEmployees = tempEmployees.filter(e => parseInt(e.active) === 1);
        }
        setEmployees(tempEmployees.sort((e1, e2) => {
            return e1.first_name < e2.first_name ? -1 : 1;
        }))
    }

    const displayMessageWithTimer = (message, textColor, milliseconds) => {
        setMessage({
            color: textColor,
            message: message
        });
        setTimeout(() => {
            setMessage({
                color: '',
                message: ''
            });
        }, milliseconds)
    }

    return (
        <Box sx={{
                margin: '10px'
        }}>
            <EmployeesFilter
                filters={filters}
                setFilters={setFilters}
                setDidLoadData={setDidLoadData}
            />
            <EmployeesTable 
                message={message}
                employees={employees}
                setCreateEmployee={setCreateEmployee}
                setEditEmployee={setEditEmployee}
                setEmployeeToEdit={setEmployeeToEdit}
                setDeleteEmployee={setDeleteEmployee}
                setEmployeeToDelete={setEmployeeToDelete}
            />
            {createEmployee ? 
            <CreateEmployee 
                displayMessageWithTimer={displayMessageWithTimer}
                updateEmployeesArray={updateEmployeesArray}
                createEmployee={createEmployee} 
                setCreateEmployee={setCreateEmployee}
            /> 
            : null}
            {editEmployee && employeeToEdit ? 
            <EditEmployee 
                displayMessageWithTimer={displayMessageWithTimer}
                updateEmployeesArray={updateEmployeesArray}
                editEmployee={editEmployee} 
                setEditEmployee={setEditEmployee}
                employeeToEdit={employeeToEdit}
                setEmployeeToEdit={setEmployeeToEdit}
            /> 
            : null}
            {deleteEmployee && employeeToDelete ? 
            <DeleteEmployee 
                displayMessageWithTimer={displayMessageWithTimer}
                updateEmployeesArray={updateEmployeesArray}
                deleteEmployee={deleteEmployee} 
                setDeleteEmployee={setDeleteEmployee}
                employeeToDelete={employeeToDelete}
                setEmployeeToDelete={setEmployeeToDelete}
            /> 
            : null}
        </Box>
    );
}

export default EmployeesPage;