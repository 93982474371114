import {useEffect, useState} from 'react';
import Lots from '../../classes/Lots.js';
import NotesIcon from '../../img/NotesIcon.png';
import Utilities from '../../classes/Utilities'
import LotNotes from '../lots/LotNotes.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import ConcreteMeasurementsContainer from './styles/ConcreteMeasurementsContainer.js';
import Auth from "../authentication/Auth.js";
import moment from "moment";
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";


// Add more forms here
const ConcreteMeasurements = (props) => {
    const [user, setUser] = useState('');

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [concreteArrayIndex, setConcreteArrayIndex] = useState(-1);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [didLoadData, setDidLoadData] = useState(false);

    const [hasExistingData, setHasExistingData] = useState(false);
    const [displayExistingDataWarning, setDisplayExistingDataWarning] = useState(false);
    const [success, setSuccess] = useState(false);

    const [measurements, setMeasurements] = useState([])

    let totalSF = 0;

    useEffect(() => {
        const loadData = async () => {
            setUser(`${await Auth.getUserId()}`);
            setMeasurements(await Utilities.loadMeasurements('1'))
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const lotSelected = async (lot) => {
        // Get selected lot
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());

        // Check if user has been save indicating prior data
        if(tempSelectedLot !== undefined && tempSelectedLot !== null && tempSelectedLot.measurements > 0){
            setHasExistingData(!(tempSelectedLot.measurements.concrete.user === ''));
            setDisplayExistingDataWarning(!(tempSelectedLot.measurements.concrete.user === ''));
        }
        else{
            setHasExistingData(false)
            setDisplayExistingDataWarning(false)
        }
        let tempIndex = tempSelectedLot === null ? 0 : tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 2);
        if(tempIndex < 0){
            tempSelectedLot.phases.push({
                lot_id: tempSelectedLot.id,
                modified_at: new Date(),
                modified_by: await Auth.getUserId(),
                phase_id: 2,
                release_date: null,
                start_status_id: null,
                starts_status: "",
            });
            tempIndex = tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 2);
        }
        setConcreteArrayIndex(tempIndex);
        return tempSelectedLot
    }

    const updateMeasurement = (type, measurement, target) => {
        if(measurement === '' || parseInt(measurement) === 0){
            return;
        }
        let phases = selectedLot.phases
        if(phases[concreteArrayIndex].starts_status === '' || phases[concreteArrayIndex].starts_status === null 
            || phases[concreteArrayIndex].starts_status === 'Need Measurements' || parseInt(phases[concreteArrayIndex].starts_status_id) === 2){
            phases[concreteArrayIndex].release_date = moment(new Date()).format('YYYY-MM-DD H:mm:ss');//(new Date()).toISOString();
            phases[concreteArrayIndex].start_status_id = 1;
            phases[concreteArrayIndex].starts_status = 'Received';
            phases[concreteArrayIndex].modified_by = user;
        }
        setSelectedLot(current => {
            return{
                ...current,
                measurements: {
                    ...current.measurements,
                    concrete: {
                        ...current.measurements.concrete,
                        [type]: Number.parseInt(measurement === '' ? 0 : measurement),
                        user: user,
                        date: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    }
                },
            }
        })
    }

    const updateLot = async () => {
        let tempLot = {...selectedLot}
        delete tempLot.gate_information;
        await Lots.updateLot(tempLot, user);

        // Update lot in list of lots
       const index = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
       const tempLots = [...lots];
       tempLots[index] = tempLot;
       setLots([...tempLots]);
    }

    const handleSubmitMeasurements = (e) => {
        updateLot();

        setSuccess(true);
        setHasExistingData(true);
    }

    const togglePopup = () => {
        setDisplayExistingDataWarning(false);
        setSuccess(false);
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
    }

    if (selectedLot && selectedLot.measurements !== undefined && Object.keys(selectedLot.measurements).length > 0 && Object.keys(selectedLot.measurements.concrete).length > 0) {
        totalSF = Number.parseInt(selectedLot.measurements.concrete.driveway) +
            Number.parseInt(selectedLot.measurements.concrete.walkway) +
            Number.parseInt(selectedLot.measurements.concrete.entry) +
            Number.parseInt(selectedLot.measurements.concrete.approach) +
            Number.parseInt(selectedLot.measurements.concrete.patio) +
            Number.parseInt(selectedLot.measurements.concrete.courtyard);
    }

    // Check which concrete measuremnets to render
    const canCreateConcreteMeasurements = props.isAuthorized('concreteMeasurements', 'WRITE');
    const canEditConcreteMeasurements = props.isAuthorized('concreteMeasurements', 'WRITE'); 
    const inputConcreteMeasurements = (!hasExistingData && canCreateConcreteMeasurements) || (hasExistingData && canEditConcreteMeasurements);

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <ConcreteMeasurementsContainer>
                <JobLotFilter
                    title={'Concrete Flatwork Measurements'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />

                {selectedLot && <div className='measurements-sub-header'>Measurements</div>}
                {selectedLot &&
                <div className='release-date-box'>
                    <div className='measurements-release-date-box'>
                        <div className='measurements-release-date-label'>Release Date:</div>
                        {selectedLot.phases[concreteArrayIndex].release_date && <div className='measurements-release-date-value'>{Globals.formatDateToDisplay(selectedLot.phases[concreteArrayIndex].release_date)}</div>}
                        {!selectedLot.phases[concreteArrayIndex].release_date && <div className='measurements-release-date-value'>Not Released</div>}
                    </div>
                    <div className='measurements-release-status-box'>
                        <div className='measurements-release-status-label'>Starts Status:</div>
                        {(selectedLot.phases[concreteArrayIndex].starts_status) && <div className='measurements-release-status-value'>{selectedLot.phases[concreteArrayIndex].starts_status}</div>}
                        {(!selectedLot.phases[concreteArrayIndex].starts_status) && <div className='measurements-release-status-value'>N/A</div>}
                    </div>
                    <div className='measurements-lot-notes-box'>
                        <div className='measurements-lot-notes-label'>Lot Notes:</div>
                        <div className='measurements-lot-notes-value' onClick={(e) => handleDisplayNotes()}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                    </div>
                </div>}
                {selectedLot &&
                    <div className='measurements-box'>
                     {measurements.map(m => {
                         return(
                         <div key={m.name + 'entry-measurements'} className='entry-measurements individual-measurements'>
                             <div className='measurement-type'>{m.description}</div>
                             {inputConcreteMeasurements && <div>
                                 <input id={m.name + '-measurements-total'} className='measurements-input' type='number' onClick={() => document.getElementById(m.name + '-measurements-total').select()}
                                 value={selectedLot.measurements.concrete[m.name]} onChange={(e) => updateMeasurement(m.name, e.target.value, e.target)}/>
                             </div>}
                             {!inputConcreteMeasurements && <div className='measurement-static'>{m.name}</div>}
                             <div className='measurement-total-postfix'>sqft</div>
                         </div>)
                     })}
                    <div className='total-measurements'>
                        <div className='total-measurements-type'>Total</div>
                        <div className='total-measurements-sign'>=</div>
                        <div className='total-measurements-total-value'>{totalSF}</div>
                        <div className='total-measurements-total-postfix'>sqft</div>
                    </div>
                    {inputConcreteMeasurements &&
                    <div className='submit'>
                        <div tabIndex='0' className='button' onClick={handleSubmitMeasurements}>Submit Measurements</div>
                    </div>}
                </div>}
                {displayNotes ?
                <LotNotes
                    openLotNotes={displayNotes}
                    setOpenLotNotes={setDisplayNotes}
                    lotToViewNotes={selectedLot}
                    setLotToViewNotes={() => {}}
                    noteType={'Foundation'}
                    updatedLotReturned={closeNotes}
                />
                : null}
                {displayExistingDataWarning && canEditConcreteMeasurements && <Popup color={PopupWarningColor} message={`The selected lot already contains data. Overwriting data will result in losing current data`} handlePopup={togglePopup}/>}
                {success && <Popup color={PopupSuccessColor} message={`Concrete Measurements have been saved`} handlePopup={togglePopup}/>}
            </ConcreteMeasurementsContainer>
        </FieldFormsContainer>
    )
}

export default ConcreteMeasurements;