import {createContext, useEffect, useState} from "react";

export const AppContext = createContext(false);

// Provider component
export const AppProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem('appState');
        return storedState ? JSON.parse(storedState) : false;
    });

    useEffect(() => {
        localStorage.setItem('appState', JSON.stringify(state));
    }, [state]);

    return (
        <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated, state, setState }}>
            {children}
        </AppContext.Provider>
    );
};