import {useEffect, useState} from 'react';
import dayjs from "dayjs";
import NotesIcon from '../../img/NotesIcon.png';
import { DropDownWithLabel, DropDown } from '../utilities/DropDown';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { Button } from '../utilities/Button.js';
import LotNotes from '../lots/LotNotes.js';
import Popup, {PopupWarningColor} from '../utilities/Popup.js';
import GateInformationContainer from './styles/GateInformationContainer';
import Auth from '../authentication/Auth.js';
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";

const GateInformation = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [measurementTypes, setMeasurementTypes] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const canCreateGatesInformation = props.isAuthorized('gateInformation', 'WRITE');
    const canEditGatesInformation = props.isAuthorized('gateInformation', 'WRITE');

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);

            const tempMeasurementTypes = await Auth.hitEndpointNew("GET", "measurement-types")
            setMeasurementTypes(tempMeasurementTypes.map(m => {
                return {
                    ...m,
                    display: m.name
                }
            }));
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const handleJobSelect = async (e) => {
        //.sort((l1, l2) => {
        //     const n1 = parseInt(l1.number);
        //     const n2 = parseInt(l2.number);
        //     if(n1 === n2){
        //         return l1.phase < l2.phase ? -1 : 1;
        //     }
        //     return n1 < n2 ? -1 : 1;
        // }))
    }

    const lotSelected = async (lot) => {
        const tempLot = lot === null ? null : (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${lot.id}`))[0];
        if(tempLot !== null) {
            tempLot.value = lot.value
            tempLot.label = lot.label
            tempLot.lot = lot.lot
            tempLot.gatesPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 9)
            if (tempLot.gatesPhase === undefined) {
                tempLot.gatesPhase = {
                    release_date: null,
                    starts_status: null
                }
            }
            tempLot.gate_information = tempLot.gate_information.map(g => {
                return {
                    ...g,
                    displayMode: 'display'
                }
            })
        }
        return tempLot;
    }

    const handleSaveGateInformation = async (gateId) => {
        const index = gateId !== undefined ? selectedLot.gate_information.findIndex(g => parseInt(g.id) === parseInt(gateId)) : 0;

        if(selectedLot.gate_information[index].width === ''){
            setError({
                type: 'NO_WIDTH',
                message: 'Please enter a width'
            })
            return;
        }
        if(selectedLot.gate_information[index].measurement_type_id === null || selectedLot.gate_information[index].measurement_type_id === ''){
            setError({
                type: 'NO_MEASUREMENT_TYPE',
                message: 'Please select the measurement type'
            })
            return;
        }
        if(selectedLot.gate_information[index].handedness === ''){
            setError({
                type: 'NO_HANDEDNESS',
                message: 'Please select a handedness'
            })
            return;
        }

        const dbObj = {
            id: selectedLot.id,
            gate_information: selectedLot.gate_information.map(g => {
                delete g.measurement_type;
                delete g.displayMode;
                delete g.user;
                g.created_at = dayjs(new Date(g.created_at)).format('YYYY-MM-DD');
                g.level = g.level ? g.level : null;
                return g
            })
        }

        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', dbObj);

        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${selectedLot.id}`))[0];
        tempLot.gatesPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 9)
        if(tempLot.gatesPhase === undefined){
            tempLot.gatesPhase = {
                release_date: null,
                starts_status: null
            }
        }
        tempLot.gate_information = tempLot.gate_information.map(g => {
            return {
                ...g,
                displayMode: 'display'
            }
        })
        tempLot.value = selectedLot.value
        tempLot.label = selectedLot.label
        tempLot.lot = selectedLot.lot
        setSelectedLot({...tempLot});
    }

    const handleCancelCreateEditGateInformation = async () => {
        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${selectedLot.id}`))[0];
        tempLot.gatesPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 9)
        if(tempLot.gatesPhase === undefined){
            tempLot.gatesPhase = {
                release_date: null,
                starts_status: null
            }
        }
        tempLot.gate_information = tempLot.gate_information.map(g => {
            return {
                ...g,
                displayMode: 'display'
            }
        })
        setSelectedLot({...tempLot});
    }

    const handleAddGateInformation = async () => {
        const tempLot = {...selectedLot};
        tempLot.gate_information.splice(0, 0, {
            displayMode: 'create',
            lot_id: '',
            width: '',
            deleted_at: null,
            measurement_type: {
                id: 1,
                name: 'inches'
            },
            measurement_type_id: 1,
            handedness: '',
            decorative_iron: false,
            level: '',
            created_by: await Auth.getUserId(),
            created_at: new Date()
        });
        setSelectedLot({...tempLot});
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
    } 

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <GateInformationContainer>
                <JobLotFilter
                    title={'Gate Information'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />
                {selectedLot && <div className='gate-information-sub-header'>Gate Information</div>}
                {selectedLot !== null && selectedLot.gatesPhase !== undefined &&
                <div className='release-date-box'>
                    <div className='gate-information-release-date-box'>
                        <div className='gate-information-release-date-label'>Release Date:</div>
                        {selectedLot.gatesPhase.release_date !== null ?
                        <div className='gate-information-release-date-value'>
                            {dayjs(new Date(selectedLot.gatesPhase.release_date)).format('YYYY-MM-DD')}
                        </div> :
                        <div className='gate-information-release-date-value'>Not Released</div>}
                    </div>
                    <div className='gate-information-release-status-box'>
                        <div className='gate-information-release-status-label'>Starts Status:</div>
                        {(selectedLot.gatesPhase.starts_status !== null && selectedLot.gatesPhase.starts_status !== '') ?
                        <div className='gate-information-release-status-value'>{selectedLot.gatesPhase.starts_status}</div> :
                        <div className='gate-information-release-status-value'>N/A</div>}
                    </div>
                    <div className='gate-information-lot-notes-box'>
                        <div className='gate-information-lot-notes-label'>Lot Notes:</div>
                        <div
                            className='gate-information-lot-notes-value'
                            onClick={() => setDisplayNotes(true)}>
                                {selectedLot.notes !== ''  ?
                                <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> :
                                <div className='add-note-button-box'>
                                    <div className='add-note-button'>Notes</div>
                            </div>}
                        </div>
                    </div>
                    <Button
                        className="add-gate-information"
                        handleKeyPress={(async (e) => {if(e.key === 'Enter'){await handleAddGateInformation()}})}
                        handleClick={(async () => await handleAddGateInformation())}
                        buttonText="Add Gate"
                        buttonMargin="20px auto"
                        width="120"
                    />
                </div>}
                {selectedLot !== null && selectedLot.gate_information !== null &&
                    selectedLot.gate_information.filter(g => g.deleted_at === null).map((g, index) => {
                    if((g.displayMode === 'edit' && canEditGatesInformation)
                        || (g.displayMode === 'create' && canCreateGatesInformation)){
                        return (
                            <div key={index} className='gate-information-input-box'>
                                <TextboxWithLabel
                                    className="gate-information-width"
                                    name="gate-information-width"
                                    label="Width:"
                                    value={g.width}
                                    handleTextbox={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].width = e.target.value;
                                        setSelectedLot({...tempLot});
                                    }}
                                    height="23px"
                                    margin="10px 0"
                                    columns={7}
                                    labelStart={1}
                                    labelEnd={5}
                                    inputStart={5}
                                    inputEnd={9}
                                />
                                <DropDown
                                    className="gate-information-measurement-type"
                                    name="gate-information-measurement-type"
                                    value={g.measurement_type_id}
                                    handleDropDown={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].measurement_type_id = e.target.value;
                                        tempLot.gate_information[index].measurement_type = measurementTypes.find(t => parseInt(t.id) === parseInt(e.target.value));
                                        setSelectedLot({...tempLot});
                                    }}
                                    addBlankOption={false}
                                    selectionList={measurementTypes}
                                    height="23px"
                                    margin="10px 0"
                                    columns={4}
                                    inputStart={2}
                                    inputEnd={3}
                                />
                                <DropDownWithLabel
                                    className="gate-information-handedness"
                                    name="gate-information-handedness"
                                    label="Left or Right:"
                                    value={g.handedness}
                                    handleDropDown={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].handedness = e.target.value;
                                        setSelectedLot({...tempLot});
                                    }}
                                    addBlankOption={true}
                                    selectionList={[{id: 'Left', display: 'Left'}, {id: 'Right', display: 'Right'}, {id: 'Double', display: 'Double'}]}
                                    height="23px"
                                    margin="10px 0"
                                    columns={10}
                                    labelStart={1}
                                    labelEnd={5}
                                    inputStart={5}
                                    inputEnd={8}
                                />
                                <CheckboxWithLabel
                                    className="gate-information-decorative-iron"
                                    name="gate-information-decorative-iron"
                                    label="Decorative Iron:"
                                    checked={g.decorative_iron}
                                    handleCheckbox={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].decorative_iron = e.target.checked;
                                        setSelectedLot({...tempLot});
                                    }}
                                    margin="10px 0 15px"
                                    columns="10"
                                    labelStart="1"
                                    labelEnd="5"
                                    inputStart="5"
                                    inputEnd="8"
                                />
                                <DropDownWithLabel
                                    className="gate-information-level"
                                    name="gate-information-level"
                                    label="Gate Level:"
                                    value={g.level !== null ? g.level : ''}
                                    handleDropDown={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].level = e.target.value;
                                        setSelectedLot({...tempLot});
                                    }}
                                    addBlankOption={true}
                                    selectionList={[{id: '1', display: '1'}, {id: '2', display: '2'}, {id: '3', display: '3'}, {id: '4', display: '4'}, {id: '5', display: '5'}]}
                                    height="23px"
                                    margin="10px 0 15px"
                                    columns={10}
                                    labelStart={1}
                                    labelEnd={5}
                                    inputStart={5}
                                    inputEnd={8}
                                />

                                <div>
                                    <Button
                                        className="gate-information-submit"
                                        handleKeyPress={((e) => {if(e.key === 'Enter'){handleSaveGateInformation(g.id)}})}
                                        handleClick={(() => handleSaveGateInformation(g.id))}
                                        buttonText="Save"
                                        width="120"
                                    />
                                    <Button
                                    className="gate-information-submit"
                                    handleKeyPress={(async (e) => {if(e.key === 'Enter'){await handleCancelCreateEditGateInformation()}})}
                                    handleClick={(async () => await handleCancelCreateEditGateInformation())}
                                    buttonText="Cancel"
                                    width="120"
                                />
                                </div>

                            </div>
                        )
                    }
                    return (
                        <div
                            key={index}
                            className='gate-information-box'
                            onClick={() => {
                                const tempLot = {...selectedLot};
                                const index = tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id));
                                tempLot.gate_information[index].displayMode = 'edit';
                                setSelectedLot({...tempLot});
                            }}
                        >
                            <div className="information-label">Width:</div>
                            <div className="information-data">{`${g.width} ${g.measurement_type ? g.measurement_type.name : ''}`}</div>
                            <div className="information-label">Left or Right:</div>
                            <div className="information-data">{g.handedness}</div>
                            <div className="information-label">Decorative Iron:</div>
                            <div className="information-data">{g.decorative_iron ? 'Yes' : 'No'}</div>
                            <div className="information-label">Gate Level:</div>
                            <div className="information-data">{g.level}</div>
                        </div>
                    );
                })}
                {displayNotes ?
                <LotNotes
                    openLotNotes={displayNotes}
                    setOpenLotNotes={setDisplayNotes}
                    lotToViewNotes={selectedLot}
                    setLotToViewNotes={() => {}}
                    noteType={'Gates'}
                    updatedLotReturned={closeNotes}
                />
                : null}
                {error.type !== '' && <Popup color={PopupWarningColor} message={error.message} handlePopup={() => setError({type: '', message: ''})}/>}
            </GateInformationContainer>
        </FieldFormsContainer>
    )
}

export default GateInformation;