import {useEffect, useState} from 'react';

import Lots from '../../classes/Lots.js';
import NotesIcon from '../../img/NotesIcon.png';
import Utilities from '../../classes/Utilities'
import LotNotes from '../lots/LotNotes.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import PaverMeasurementsContainer from './styles/PaverMeasurementsContainer';
import Auth from "../authentication/Auth.js";
import moment from "moment";
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";


// Add more forms here
const PaverMeasurements = (props) => {
    const [user, setUser] = useState('');

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [paverArrayIndex, setPaverArrayIndex] = useState(-1);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [didLoadData, setDidLoadData] = useState(false);

    const [hasExistingData, setHasExistingData] = useState(false);
    const [displayExistingDataWarning, setDisplayExistingDataWarning] = useState(false);
    const [success, setSuccess] = useState(false);

    const [measurements, setMeasurements] = useState([])

    let totalSF = 0;

    useEffect(() => {
        const loadData = async () => {
            setUser(`${await Auth.getUserId()}`);
            setMeasurements(await Utilities.loadMeasurements('1'))
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])



    const lotSelected = async (lot) => {
        // Get selected lot
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());
        const tempJob = lot === null ? null : jobs.find(job => parseInt(job.id) === parseInt(tempSelectedLot.job_id));

        if(lot !== null && tempSelectedLot.hasPavers === undefined && tempJob.attributes.find(attribute => parseInt(attribute.attribute_id) === 10) !== undefined){
            if(tempJob.attributes.find(attribute => parseInt(attribute.attribute_id) === 11) === undefined){
                tempSelectedLot.attributes.push({
                    attribute_id: 10,
                    lot_id: tempSelectedLot.id
                })
                tempSelectedLot.hasPavers = 'true';
            }
        }

        // Check if user has been saved indicating prior data
        if(tempSelectedLot !== undefined && tempSelectedLot !== null && tempSelectedLot.measurements > 0){
            setHasExistingData(!(tempSelectedLot.measurements.pavers.user === ''));
            setDisplayExistingDataWarning(!(tempSelectedLot.measurements.pavers.user === ''));
        }
        else{
            setHasExistingData(false)
            setDisplayExistingDataWarning(false)
        }
        let tempIndex = lot === null ? 0 : tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 8);
        if(tempIndex < 0){
            tempSelectedLot.phases.push({
                lot_id: tempSelectedLot.id,
                modified_at: new Date(),
                modified_by: await Auth.getUserId(),
                phase_id: 8,
                release_date: null,
                start_status_id: null,
                starts_status: "",
            });
            tempIndex = tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 8);
        }
        setPaverArrayIndex(tempIndex);

        return tempSelectedLot;
    }

    const updateMeasurement = (type, measurement, target) => {
        if(measurement === '' || parseInt(measurement) === 0){
            return;
        }
        let phases = selectedLot.phases
        if(phases[paverArrayIndex].starts_status === '' || phases[paverArrayIndex].starts_status === null 
            || phases[paverArrayIndex].starts_status === 'Need Measurements' || parseInt(phases[paverArrayIndex].starts_status_id) === 2){
            phases[paverArrayIndex].release_date = moment(new Date()).format('YYYY-MM-DD H:mm:ss');//(new Date()).toISOString();
            phases[paverArrayIndex].start_status_id = 1;
            phases[paverArrayIndex].starts_status = 'Received';
            phases[paverArrayIndex].modified_by = user;
        }
        setSelectedLot(current => {
            return{
                ...current,
                measurements: {
                    ...current.measurements,
                    pavers: {
                        ...current.measurements.pavers,
                        [type]: Number.parseInt(measurement === '' ? 0 : measurement),
                        user: user,
                        date: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    }
                },
            }
        })
    }

    const updateLot = async () => {
        let tempLot = {...selectedLot}
        delete tempLot.gate_information;
        await Lots.updateLot(tempLot, user);

        // Update lot in list of lots
        const index = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
        const tempLots = [...lots];
        tempLots[index] = tempLot;
        setLots([...tempLots]);
    }

    const handleSubmitMeasurements = (e) => {
        updateLot();

        setSuccess(true);
        setHasExistingData(true);
    }

    const togglePopup = () => {
        setDisplayExistingDataWarning(false);
        setSuccess(false);
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
    }


    if (selectedLot && selectedLot.measurements !== undefined && Object.keys(selectedLot.measurements).length > 0 && Object.keys(selectedLot.measurements.pavers).length > 0) {
        totalSF = Number.parseInt(selectedLot.measurements.pavers.driveway) +
            Number.parseInt(selectedLot.measurements.pavers.walkway) +
            Number.parseInt(selectedLot.measurements.pavers.entry) +
            Number.parseInt(selectedLot.measurements.pavers.approach) +
            Number.parseInt(selectedLot.measurements.pavers.patio) +
            Number.parseInt(selectedLot.measurements.pavers.courtyard);
    }


    // Check which pavers measuremnets to render
    const canCreatePaverMeasurements = props.isAuthorized('paverMeasurements', 'WRITE');
    const canEditPaverMeasurements = props.isAuthorized('paverMeasurements', 'WRITE');
    const inputPaverMeasurements = (!hasExistingData && canCreatePaverMeasurements) || (hasExistingData && canEditPaverMeasurements);

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <PaverMeasurementsContainer>
                <JobLotFilter
                    title={'Paver Measurements'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />
                {selectedLot && (selectedLot.hasPavers === 'true' || selectedLot.hasPavers === 'Opt') &&
                    <div className='style-color-box'>
                        <div className='style-box'>
                            <div className='style-label'>Style:</div>
                            {selectedLot.measurements.pavers.style !== '' && <div className='style-value'>{selectedLot.measurements.pavers.style}</div>}
                            {selectedLot.measurements.pavers.style === '' && <div className='style-value'>N/A</div>}
                        </div>
                        <div className='color-box'>
                            <div className='color-label'>Color:</div>
                            {selectedLot.measurements.pavers.color !== '' && <div className='color-value'>{selectedLot.measurements.pavers.color}</div>}
                            {selectedLot.measurements.pavers.color === '' && <div className='color-value'>N/A</div>}
                        </div>
                    </div>}
                {selectedLot && <div className='measurements-sub-header'>Measurements</div>}
                {selectedLot && (selectedLot.hasPavers === 'true' || selectedLot.hasPavers === 'Opt') && paverArrayIndex !== -1 &&
                    <div className='release-date-box'>
                        <div className='measurements-release-date-box'>
                            <div className='measurements-release-date-label'>Release Date:</div>
                            {(selectedLot.phases[paverArrayIndex] !== undefined && selectedLot.phases[paverArrayIndex].release_date) && <div className='measurements-release-date-value'>{Globals.formatDateToDisplay(selectedLot.phases[paverArrayIndex].release_date)}</div>}
                            {(selectedLot.phases[paverArrayIndex] === undefined || !selectedLot.phases[paverArrayIndex].release_date) && <div className='measurements-release-date-value'>Not Released</div>}
                        </div>
                        <div className='measurements-release-status-box'>
                            <div className='measurements-release-status-label'>Starts Status:</div>
                            {(selectedLot.phases[paverArrayIndex] !== undefined && selectedLot.phases[paverArrayIndex].starts_status) && <div className='measurements-release-status-value'>{selectedLot.phases[paverArrayIndex].starts_status}</div>}
                            {(selectedLot.phases[paverArrayIndex] === undefined || !selectedLot.phases[paverArrayIndex].starts_status) && <div className='measurements-release-status-value'>N/A</div>}
                        </div>
                        <div className='measurements-lot-notes-box'>
                            <div className='measurements-lot-notes-label'>Lot Notes:</div>
                            <div className='measurements-lot-notes-value' onClick={(e) => handleDisplayNotes()}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                        </div>
                    </div>}
                {selectedLot && (selectedLot.hasPavers === 'true' || selectedLot.hasPavers === 'Opt') &&
                    <div className='measurements-box'>
                        {measurements.map(m => {
                            return(
                                <div key={m.name + 'entry-measurements'} className='entry-measurements individual-measurements'>
                                    <div className='measurement-type'>{m.description}</div>
                                    {inputPaverMeasurements && <div>
                                        <input id={m.name + '-measurements-total'} className='measurements-input' type='number' onClick={() => document.getElementById(m.name + '-measurements-total').select()}
                                        value={selectedLot.measurements.pavers[m.name]} onChange={(e) => updateMeasurement(m.name, e.target.value, e.target)}/>
                                    </div>}
                                    {!inputPaverMeasurements && <div className='measurement-static'>{m.name}</div>}
                                    <div className='measurement-total-postfix'>sqft</div>
                                </div>)
                        })}
                        <div className='total-measurements'>
                            <div className='total-measurements-type'>Total</div>
                            <div className='total-measurements-sign'>=</div>
                            <div className='total-measurements-total-value'>{totalSF}</div>
                            <div className='total-measurements-total-postfix'>sqft</div>
                        </div>
                        {inputPaverMeasurements &&
                            <div className='submit'>
                                <div tabIndex='0' className='button' onClick={handleSubmitMeasurements}>Submit Measurements</div>
                            </div>}
                    </div>}
                {selectedLot && (selectedLot.hasPavers === undefined || (selectedLot.hasPavers !== 'true' && selectedLot.hasPavers !== 'Opt')) &&
                <div className='no-pavers-box'>
                    <div className='no-pavers-message'>This lot does not require pavers. If this is incorrect or change is required, please contact the Starts Department</div>
                </div>}
                {displayNotes ?
                <LotNotes
                    openLotNotes={displayNotes}
                    setOpenLotNotes={setDisplayNotes}
                    lotToViewNotes={selectedLot}
                    setLotToViewNotes={() => {}}
                    noteType={'Paver'}
                    updatedLotReturned={closeNotes}
                />
                : null}
                {displayExistingDataWarning && canEditPaverMeasurements && <Popup color={PopupWarningColor} message={`The selected lot already contains data. Overwriting data will result in losing current data`} handlePopup={togglePopup}/>}
                {success && <Popup color={PopupSuccessColor} message={`Paver Measurements have been saved`} handlePopup={togglePopup}/>}
            </PaverMeasurementsContainer>
        </FieldFormsContainer>
    )
}

export default PaverMeasurements;