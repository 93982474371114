import MainHeader from '../utilities/MainHeader.js';
import TaskAdjustmentContainer from './styles/TaskAdjustmentContainer';
import Select, {createFilter} from "react-select";
import TextField from "@mui/material/TextField";
import {AvailableLots} from "../scheduling/AvailableLots";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import Auth from "../authentication/Auth";
import Schedules from "../../classes/Schedules";
import Phases from "../../classes/Phases";
import {useMemo, useState} from "react";
import Jobs from "../../classes/Jobs";
import Lots from "../../classes/Lots";
import PhaseTasks from "../../classes/PhaseTasks";
import {Autocomplete, darken, IconButton, lighten, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {createTheme} from "@mui/material/styles";
import Crews from "../../classes/Crews";
import {Save} from "@mui/icons-material";
import EWAs from "../../classes/Ewas";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import Popup, {PopupSuccessColor} from "../utilities/Popup";

const TaskAdjustment = (props) => {

    const [jobs, setJobs] = useState([])
    const [lots, setLots] = useState([])
    const [phases, setPhases] = useState([])
    const [tasks, setTasks] = useState([])

    const [selectedJob, setSelectedJob] = useState('')
    const [selectedLot, setSelectedLot] = useState('')
    const [selectedPhase, setSelectedPhase] = useState('')
    const [selectedTask, setSelectedTask] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [didLoadData, setDidLoadData] = useState(false)

    const [crews, setCrews] = useState([])
    const [loadingWhat, setLoadingWhat] = useState('')
    const [schedule, setSchedule] = useState([])

    const [successEWAs, setSuccessEWAs] = useState(false)

    const loadData = async () => {
        if(Utilities.isLoggedOut()){
            const uri = Config.BASE_UI();
            window.location.replace(uri);
        }
        else {
            let date = new Date()

            setIsLoading(true)
            setDidLoadData(true);

            setLoadingWhat('Jobs')
            setJobs(await Jobs.loadJobs())

            setPhases(await Phases.loadPhases())

            setIsLoading(false)
            setLoadingWhat('')
        }
    }

    if (!didLoadData) {
        loadData();
    }

    const handleJobChange = async (e) =>{
        setSelectedJob(e)
        if(e === null) {
            setLots([])
        }
        else{
            setLots(await Lots.loadLots({'job_id': e.value}))
        }
    }
    const handleLotChange = (e) =>{
        setSelectedLot(e)
    }

    const handlePhaseChange = async (e) => {
        setSelectedPhase(e)
        setTasks(await PhaseTasks.loadPhaseTasks({'phase_task_phases.phase_id': e.value}))

        let queryObj = e !== null ? {company: e.phase.company} : null
        let crws = await Crews.loadCrewsForDropdown(queryObj)
        let array = []
        for(let c=0; c< crws.length; c++){
            if(crws[c].label !== undefined) {
                array.push(crws[c])
            }
        }
        setCrews(array)
    }

    const handleTaskChange = async (e) => {
        setSelectedTask(e)
        let sch = []
        if(e !== null){
            sch = await Schedules.loadScheduledTask(selectedJob.value, selectedLot.value, e.value)
            for(let s=0; s<sch.length; s++){
                sch[s].new_crew_id = null
                sch[s].new_date = null
                sch[s].new_notes = sch[s].ewa_id === null ?  'Job Not Complete' : sch[s].notes
            }
        }
        setSchedule(sch)
    }

    const handleSaveCellPayout = (e, value, cell, row) => {
        //TODO once we load payouts we should start limiting to only what the payout is max
        row.original.payout = value
    }
    const handleSaveCellCrew = (e, value, cell, row) => {
        row.original.new_crew_id = value
    }
    const handleSaveCellNotes = (e, value, cell, row) => {
        row.original.new_notes = value
    }

    const handleDateChange = (row, value) => {
        if (dayjs(value).format('YYYY-MM-DD') < dayjs(row.original.schedule_date).format('YYYY-MM-DD')) {
            value = null
            alert(`Date ${dayjs(value).format('MM/DD/YYYY')} cannot be before the scheduled date of ${dayjs(row.original.schedule_date).format('MM/DD/YYYY')}`)
        }
        row.original.new_date = value
    }

    /*
        * TODO rework to save new ewas with selected task code. This will complete the save.
        *  then some styling work and we should be set =)
     */
    const handleSaveAdjustment = async () => {

        let ewa
        for(let s=0; s<schedule.length; s++){
            if(schedule[s].new_date !== null && schedule[s].new_crew_id !== null) {
                let scheduleYear = dayjs(schedule[s].new_date).format('YYYY')
                let phase = selectedPhase
                let company = phase.phase.company
                let suffix = phase.phase.ewa_suffix

                let ewaNumber = await EWAs.getNextNumber(company, suffix, scheduleYear, '')
                ewa = await EWAs.createNewEWA(selectedLot.lot)
                // let negativeEWANumber = await EWAs.getNextNumber(company, suffix, scheduleYear, positiveEWANumber)
                // let negativeEWA = await EWAs.createNewEWA(selectedLot.lot)

                ewa.number = ewaNumber.ewa_number
                ewa.line_items = []
                // negativeEWA.number = negativeEWANumber.ewa_number
                // negativeEWA.line_items = []

                ewa = await populateEWA(ewa, schedule[s], 1, schedule[s].new_crew_id)
                ewa = await populateEWA(ewa, schedule[s], -1, schedule[s].crew.crew_id)

              //  ewas.push(ewa)
              //  ewas.push(negativeEWA)
            }
        }
        if(ewa.line_items !== undefined && ewa.line_items.length > 0) {
            let response = await EWAs.saveNewEWA(ewa)
            setSuccessEWAs(true)
        }
    }

    const populateEWA = async (ewa, row, multiplier, crewId) => {

        let ewaCrew = {}
        let crew = crews.find(x => x.id === crewId)
        if(crew !== undefined) {
            let employees = []
            for (let m = 0; m < crew.members.length; m++) {
                employees.push({
                    employee_id: crew.members[m].employee_id,
                    is_lead: crew.members[m].position === "Leader",
                    approved: null,
                    approval_by: null,
                    approval_date: null,
                    deleted_at: null,
                    deleted_by: null,
                })
            }
            ewaCrew = {
                crew_id: crew.id,
                employees: employees
            }
        }

        ewa.line_items.push({
            lot_id: selectedLot.lot.id,
            phase_id: selectedPhase.phase.id,
            phase_task_id: selectedTask.task.id,
            schedule_date: row.new_date,
            payout: row.payout * multiplier,
            notes: row.new_notes,
            crew: ewaCrew,
            scheduled: 1,
        })

        return ewa
    }

    const gridColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => {
                        return dayjs(new Date(row.schedule_date)).format('MM-DD-YYYY');
                    },
                    header: 'Date',
                    enableEditing: false,
                    size: 200,
                },
                {
                    accessorFn: (row) => {
                        let crew = row.crew.crew !== null ? row.crew.crew.number : '00000'
                        let lead = row.crew.employees.find(x => x.is_lead === 1)
                        let name = ''
                        if (lead === undefined) {
                            name = `${row.crew.employees[0].employee.alias !== null ? row.crew.employees[0].employee.alias : ''} ${row.crew.employees[0].employee.first_name} ${row.crew.employees[0].employee.last_name}`
                        } else {
                            name = `${lead.employee.alias !== null ? lead.employee.alias : ''} ${lead.employee.first_name} ${lead.employee.last_name}`
                        }
                        return name
                    },
                    header: 'Crew',
                    enableEditing: false,
                    size: 300,
                },
                {
                    accessorFn: (row) => {
                        return row.new_crew_id
                    },
                    header: 'New Crew',
                    size: 400,
                    enableEditing: row => row.original.ewa_id === null,
                    grow: true,
                    Edit: ({ cell, row }) => (
                    <Autocomplete
                        // disablePortal
                        id={`schedule-${row.id}-options`}
                        key={`schedule-${row.id}-options-${row.index}`}
                        options={crews.filter(x => x.id !== row.original.crew.crew_id)}
                        sx={{ width: 400, zIndex: 10000000 }}
                        onChange={(e, newValue) => handleSaveCellCrew(e, newValue !== null ? newValue.id : null, cell, row)}
                        // inputValue={crews.find(x => x.id == row.original.new_crew_id) !== undefined ? crews.find(x => x.id == row.original.new_crew_id) : ''}
                        renderInput={(params) => <TextField key={`schedule-${row.id}-options-${row.index}-input`} {...params} label="" />}
                    />
                    ),
                },
                {
                    accessorKey: 'payout',
                    header: 'Payout',
                    size: 100,
                    enableEditing: row => row.original.ewa_id === null,
                    Edit: ({ cell, row }) => {
                        return (
                            <TextField
                                placeholder='Payout'
                                type='decimal'
                                defaultValue={cell.getValue()}
                                onBlur={(e) => handleSaveCellPayout(e, e.target.value, cell, row)}
                            ></TextField>
                        )
                    }
                },
                {
                    accessorFn: (row) => {
                        if(row.ewa_id === null) {
                            return row.new_date === null ? '' : row.new_date
                        }
                        else {
                            return dayjs(row.schedule_date).format('MM/DD/YYYY')
                        }
                    },
                    header: 'Date',
                    id: `ewa-date-col`,
                    size: 150,
                    enableEditing: row => row.original.ewa_id === null,
                    grow: true,
                    Edit: ({cell, row}) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                key={`new-date-${row.index}`}
                                onChange={(newValue) => {
                                    handleDateChange(row, newValue);
                                }}
                                minDate={dayjs(row.original.schedule_date)}
                                slotProps={{
                                    textField: {
                                        helperText: '',
                                        sx: {minWidth: '120px'},
                                        variant: 'standard',
                                    },
                                }}
                                selected={row.original.new_date}
                            />
                        </LocalizationProvider>
                    )
                },
                {
                    accessorKey: 'new_notes',
                    header: 'Notes',
                    size: 200,
                    enableEditing: false,
                    Edit: ({ cell, row }) => {
                        return (
                            <TextField
                                placeholder='Notes'
                                type='text'
                                defaultValue={cell.getValue()}
                                onBlur={(e) => handleSaveCellNotes(e, e.target.value, cell, row)}
                            ></TextField>
                        )
                    }
                },
            ]
            return columns
        }
    );

    const togglePopup = () => {
        setSuccessEWAs(false)
        setSchedule([])
        setSelectedTask('')
        setSelectedPhase('')
    }

    const [matchFromStart] = useState(false);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    const baseBackgroundApprovedColor = theme.palette.mode === 'dark' ? 'rgba(123, 239, 178, 1)' : 'rgba(63, 195, 128, 1)'

    return (
        <TaskAdjustmentContainer>
            <div className={'filter-boxes'}>
                <div className="row">
                    <fieldset>
                        <legend>Step 1: Select A Job</legend>
                        <Select id="job-filter-select-id"
                                className="job-filter-select"
                                value={selectedJob}
                                onChange={(e) => handleJobChange(e)}
                                options={jobs.map(j => {
                                    return({
                                        value: j.id,
                                        label: `${j.number} ${j.project_name}`,
                                        job: j
                                    })
                                })}
                                isClearable
                                isSearchable
                                filterOption={createFilter(filterConfig)}
                                isDisabled={isLoading}/>
                        <legend>Step 2: Select A Lot</legend>
                        <Select id="lot-filter-select-id"
                                className="lot-filter-select"
                                value={selectedLot}
                                onChange={(e) => handleLotChange(e)}
                                options={lots.map(l => {
                                    return({
                                        value: l.id,
                                        label: l.phase !== null && l.phase !== '' ? `${l.phase} ${l.number}` : l.number,
                                        lot: l
                                    })
                                })}
                                isClearable
                                isSearchable
                                filterOption={createFilter(filterConfig)}
                                isDisabled={isLoading}/>
                </fieldset>
                </div>
                <div className="row">
                    <fieldset>
                        <legend>Step 3: Select A Phase</legend>
                        <Select id="phase-filter-select-id"
                                className="phase-filter-select"
                                value={selectedPhase}
                                onChange={(e) => handlePhaseChange(e)}
                                options={phases.map(p => {
                                    return({
                                        value: p.id,
                                        label: p.name,
                                        phase: p
                                    })
                                })}
                                isClearable
                                isSearchable
                                filterOption={createFilter(filterConfig)}
                                isDisabled={isLoading}/>
                    </fieldset>
                    <legend>Step 4: Select A Task</legend>
                    <fieldset>
                        <Select id="task-filter-select-id"
                                className="task-filter-select"
                                value={selectedTask}
                                onChange={(e) => handleTaskChange(e)}
                                options={tasks.filter(x => x.type === 'work' && !x.name.includes('Placeholder')).sort((a,b) => a.order_by - b.order_by).map(t => {
                                    return({
                                        value: t.id,
                                        label: t.code !== null && t.code !== '' ? `${t.code} ${t.name}` : t.name,
                                        task: t
                                    })
                                })}
                                isClearable
                                isSearchable
                                filterOption={createFilter(filterConfig)}
                                isDisabled={isLoading}/>
                    </fieldset>
                </div>
            </div>
            <div className="schedule-data-results">
                <legend>Step 5: Update a Schedule Record</legend>
                <MaterialReactTable
                    id="task-adjustment-table"
                    columns={gridColumns}
                    data={schedule.sort((a,b) => a.id - b.id)}
                    rowCount={schedule.length}
                    enableGrouping
                    enableExpanding={false}
                    enableExpandAll={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={true}
                    enableSorting={false}
                    enableFilters={false}
                    enableHiding={false}
                    enableEditing={true}
                    editDisplayMode="table"
                    enableColumnActions={true}
                    enableStickyFooter
                    muiTableContainerProps={{
                        sx: {
                            minHeight: '500px',
                            minWidth: '900px',
                        }
                    }}
                    // muiEditTextFieldProps={({cell, row}) => ({
                    //     onBlur: event => {handleSaveCell(event, event.target.value, cell, row);}
                    // })}
                    muiTableHeadCellProps={({column}) => ({
                        sx: {
                            '& .MuiTableSortLabel-icon': {
                                color: column.getIsSorted() ? 'limegreen !important' : undefined
                            },
                            backgroundColor: '#D3D3D3'
                        }
                    })}
                    muiTableBodyCellProps={({cell, row}) => ({
                        sx: {
                            height: 10,
                            fontStyle: 'italic',
                            border: 'none',
                            backgroundColor:
                                    row.original.approved ?
                                        row.index %2 === 0 ?  darken(baseBackgroundApprovedColor, 0.1) : lighten(baseBackgroundApprovedColor, 0.3)
                                        : row.original.approved === 0 ? '#FF474C' :
                                            row.index %2 === 0 ?
                                            darken(baseBackgroundColor, 0.1) :
                                            lighten(baseBackgroundColor, 0.3)
                            ,
                            borderTop: cell.row.depth === 1 ? 'solid 1pt' : row.depth === 0 ? 'solid 2pt' : 'none',
                            borderLeft: cell.column.id === "mrt-row-actions" ? 'solid 1pt' : 'none',
                            borderRight: cell.column.id === "employee" ? 'solid 1pt' : 'none',
                            paddingBottom: "1px",
                        }
                    })}
                    initialState={{
                        showColumnFilters: false,
                        pagination: {pageSize: 100, pageIndex: 0},
                        density: 'compact',
                    }}
                    renderBottomToolbarCustomActions={() => {
                        return <div>
                            <Tooltip title="Create New User">
                                <IconButton onClick={handleSaveAdjustment}>
                                    <Save />
                                </IconButton>
                            </Tooltip>
                        </div>;
                    }}
                />
            </div>
            {successEWAs && <Popup color={PopupSuccessColor} message={`The Task Adjustment has been saved & EWAs have been created`} handlePopup={togglePopup}/>}
        </TaskAdjustmentContainer>
    )
};

export default TaskAdjustment;
