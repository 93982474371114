import {useState} from 'react';

import Auth from '../authentication/Auth.js';
import UserPermissionsContainer from './styles/UserPermissionsContainer';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { Button } from '../utilities/Button.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { Checkbox } from '../utilities/Checkbox.js';
import Popup, { PopupFailureColor } from '../utilities/Popup.js';
import {logError} from "../../utilities/error";

const UserPermissions = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [mousePosition, setMousePosition] = useState([0, 0]);

    const [show, setShow] = useState("");
    const showSelections = [
        {id: 'create', display: 'Create Permission Group'},
        {id: 'edit', display: 'Edit Permission Group'},
        {id: 'assign', display: 'Assign User To Permission Group'}
    ]

    const [permissions, setPermissions] = useState([]);
    const [permissionGroups, setPermissionGroups] = useState([]);
    const [newPermissionGroup, setNewPermissionGroup] = useState({
        display: '',
        group_permissions: []
    })
    const [permissionGroupToEdit, setPermissionGroupToEdit] = useState(null);
    const [showPermissionDescription, setShowPermissionDescription] = useState('');

    const [comparePermissionGroup, setComparePermissionGroup] = useState(null);

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [compareUser, setCompareUser] = useState(null);

    const [errorNoName, setErrorNoName] = useState(false);
    const [errorDuplicateName, setErrorDuplicateName] = useState(false);
    const [errorPermissionGroupNotAssigned, setErrorPermissionGroupNotAssigned] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);
        setUsers((await Auth.hitEndpointNew("GET", `users`)).sort((u1, u2) => u1.firstname < u2.firstname ? -1 : 1));
        setPermissionGroups((await Auth.hitEndpointNew("GET", "permission/groups")).sort((g1, g2) => g1.display < g2.display ? -1 : 1));
        setPermissions((await Auth.hitEndpointNew("GET", "permissions")).sort((p1, p2) => p1.display < p2.display ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const setShowAndCancelAll = async (showValue) => {
        if(show === 'create'){
            cancelCreatingNewPermissionGroup();
        }
        if(show === 'edit'){
            await cancelUpdatingPermissionGroup();
        }
        if(show === 'assign'){
            cancelUpdatingUserPermissionGroup();
        }

        setComparePermissionGroup(null);
        setShow(showValue);
    }

    const addPermissionNewPermission = (permissionId) => {
        if(newPermissionGroup.group_permissions.find(permission => parseInt(permission.permission_id) === parseInt(permissionId))){
            return;
        }
        const tempNewPermissionGroup = {...newPermissionGroup};
        const permissionToAdd = {
            permission: permissions.find(permission => parseInt(permission.id) === parseInt(permissionId)),
            permission_id: permissionId,
            default_read: false,
            default_write: false
        }
        tempNewPermissionGroup.group_permissions.push(permissionToAdd);
        setNewPermissionGroup(tempNewPermissionGroup);
    }

    const removePermissionNewPermission = (id) => {
        const tempNewPermissionGroup = {...newPermissionGroup};
        const index = tempNewPermissionGroup.group_permissions.findIndex(permission => parseInt(permission.permission_id) === parseInt(id));
        tempNewPermissionGroup.group_permissions.splice(index, 1);
        setNewPermissionGroup(tempNewPermissionGroup);
    }

    const setDefaultReadNewPermission = (permission, e) => {
        const tempNewPermissionGroup = {...newPermissionGroup};
        const index = tempNewPermissionGroup.group_permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempNewPermissionGroup.group_permissions[index].default_read = e.target.checked;
        setNewPermissionGroup(tempNewPermissionGroup);
    }

    const setDefaultWriteNewPermission = (permission, e) => {
        const tempNewPermissionGroup = {...newPermissionGroup};
        const index = tempNewPermissionGroup.group_permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempNewPermissionGroup.group_permissions[index].default_write = e.target.checked;
        setNewPermissionGroup(tempNewPermissionGroup);
    }

    const createNewPermissionGroup = async () => {
        if(newPermissionGroup.display === ''){setErrorNoName(true); return;}
        const dupArray = await Auth.hitEndpointNew("GET", "permission/groups", `queryString=display='${newPermissionGroup.display}'`);
        if(dupArray.length > 0){setErrorDuplicateName(true); return;}

        // Prepare group permissions for insert to database
        for(let i = 0; i < newPermissionGroup.group_permissions.length; i++){
            newPermissionGroup.group_permissions[i] = {
                permission_id: newPermissionGroup.group_permissions[i].permission_id,
                default_read: newPermissionGroup.group_permissions[i].default_read,
                default_write: newPermissionGroup.group_permissions[i].default_write,
            }
        }

        await Auth.hitEndpointNew("POST", "permission/group", "", newPermissionGroup);
        setNewPermissionGroup({
            display: '',
            group_permissions: []
        });

        setPermissionGroups((await Auth.hitEndpointNew("GET", "permission/groups")).sort((g1, g2) => g1.display < g2.display ? -1 : 1));
        document.getElementById("add-permission-id").scrollTop = 0;
    }

    const cancelCreatingNewPermissionGroup = () => {
        setNewPermissionGroup({
            display: '',
            group_permissions: []
        });
        document.getElementById("add-permission-id").scrollTop = 0;
    }

    const resetPopups = () => {
        setErrorNoName(false);
        setErrorDuplicateName(false);
        setErrorPermissionGroupNotAssigned(false);
    }

    const selectGroupToEdit = (e) => {
        setPermissionGroupToEdit(permissionGroups.find(group => parseInt(group.id) === parseInt(e.target.value)));
    }

    const copyComparePermissionGroupToNewPermissionGroup = () => {
        if(!comparePermissionGroup){
            return;
        }

        const tempNewPermissionGroup = {...newPermissionGroup};
        tempNewPermissionGroup.group_permissions = tempNewPermissionGroup.group_permissions.filter(gp => {
            for(let i = 0; i < comparePermissionGroup.group_permissions.length; i++){
                if(parseInt(gp.permission_id) === parseInt(comparePermissionGroup.group_permissions[i].permission_id)){
                    return false;
                }
            }
            return true;
        });
        tempNewPermissionGroup.group_permissions = [...tempNewPermissionGroup.group_permissions, ...comparePermissionGroup.group_permissions];
        tempNewPermissionGroup.group_permissions = tempNewPermissionGroup.group_permissions.map(gp => {
            delete gp.group_id;
            return gp;
        })

        setNewPermissionGroup({...tempNewPermissionGroup});
    }

    const addPermissionEditPermission = (permissionId) => {
        if(permissionGroupToEdit === null){
            return;
        }
        if(permissionGroupToEdit.group_permissions.find(permission => parseInt(permission.permission_id) === parseInt(permissionId))){
            return;
        }
        const tempPermissionGroupToEdit = {...permissionGroupToEdit};
        const permissionToAdd = {
            permission: permissions.find(permission => parseInt(permission.id) === parseInt(permissionId)),
            group_id: permissionGroupToEdit.id,
            permission_id: permissionId,
            default_read: false,
            default_write: false
        }
        tempPermissionGroupToEdit.group_permissions.push(permissionToAdd);
        setPermissionGroupToEdit(tempPermissionGroupToEdit);
    }

    const removePermissionEditPermission = async (id) => {
        const tempPermissionGroupToEdit = {...permissionGroupToEdit};
        const index = tempPermissionGroupToEdit.group_permissions.findIndex(permission => parseInt(permission.permission_id) === parseInt(id));
        tempPermissionGroupToEdit.group_permissions.splice(index, 1);
        setPermissionGroupToEdit(tempPermissionGroupToEdit);
    }

    const setDefaultReadEditPermission = (permission, e) => {
        const tempPermissionGroupToEdit = {...permissionGroupToEdit};
        const index = tempPermissionGroupToEdit.group_permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempPermissionGroupToEdit.group_permissions[index].default_read = e.target.checked;
        setPermissionGroupToEdit(tempPermissionGroupToEdit);
    }

    const setDefaultWriteEditPermission = (permission, e) => {
        const tempPermissionGroupToEdit = {...permissionGroupToEdit};
        const index = tempPermissionGroupToEdit.group_permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempPermissionGroupToEdit.group_permissions[index].default_write = e.target.checked;
        setPermissionGroupToEdit(tempPermissionGroupToEdit);
    }

    const updatePermissionGroup = async () => {
        if(permissionGroupToEdit == null){return;}

        const dupArray = await Auth.hitEndpointNew("GET", "permission/groups", `queryString=display='${permissionGroupToEdit.display}'`);
        if(dupArray.length > 1){setErrorDuplicateName(true); return;}

        const tempUsers = await Auth.hitEndpointNew("GET", "users", `selector=eight&jsonString=${JSON.stringify({permission_group_id: permissionGroupToEdit.id})}`);
        const orignalPermissionGroup = (await Auth.hitEndpointNew("GET", "permission/groups", `queryString=id=${permissionGroupToEdit.id}`))[0];

        const remove = [];
        for(let i = 0; i < orignalPermissionGroup.group_permissions.length; i++){
            if(!permissionGroupToEdit.group_permissions.find(gp => parseInt(gp.permission_id) === orignalPermissionGroup.group_permissions[i].permission_id)){
                remove.push(orignalPermissionGroup.group_permissions[i]);
            }
        }

        for(let i = 0; i < tempUsers.length; i++){
            await Auth.hitEndpointNew("DELETE", "user/permission", "", tempUsers[i]); 

            const permissions = []
            for(let j = 0; j < permissionGroupToEdit.group_permissions.length; j++){
                const currentPermission = tempUsers[i].permissions.find(pn => parseInt(pn.permission_id) === parseInt(permissionGroupToEdit.group_permissions[j].permission_id));

                permissions.push({
                    user_id: tempUsers[i].id,
                    permission_id: currentPermission ? currentPermission.permission_id : permissionGroupToEdit.group_permissions[j].permission_id,
                    read: currentPermission ? currentPermission.read : permissionGroupToEdit.group_permissions[j].default_read,
                    write: currentPermission ? currentPermission.write : permissionGroupToEdit.group_permissions[j].default_write
                })
            }
            tempUsers[i].permissions = permissions;

            await Auth.hitEndpointNew("PATCH", "user", "", tempUsers[i]); 
        }

        for(let i = 0; i < remove.length; i++){
            await Auth.hitEndpointNew("DELETE", "group/permission", "", remove[i]);
        }

        // Prepare group permissions for insert to database
        for(let i = 0; i < permissionGroupToEdit.group_permissions.length; i++){
            delete permissionGroupToEdit.group_permissions[i].permission;
        }

        await Auth.hitEndpointNew("PATCH", "permission/group", "", permissionGroupToEdit);
        setPermissionGroupToEdit(null);

        setUsers((await Auth.hitEndpointNew("GET", `users`)).sort((u1, u2) => u1.firstname < u2.firstname ? -1 : 1));
        setPermissionGroups((await Auth.hitEndpointNew("GET", "permission/groups")).sort((g1, g2) => g1.display < g2.display ? -1 : 1));
        document.getElementById("select-permission-group-id").value = '';
        document.getElementById("add-permission-id").scrollTop = 0;
    }

    const cancelUpdatingPermissionGroup = async () => {
        setPermissionGroupToEdit(null);
        setPermissionGroups((await Auth.hitEndpointNew("GET", "permission/groups")).sort((g1, g2) => g1.display < g2.display ? -1 : 1));
        document.getElementById("select-permission-group-id").value = '';
        document.getElementById("add-permission-id").scrollTop = 0;
    }

    const selectUser = (e) => {
        const tempSelectedUser = users.find(user => parseInt(user.id) === parseInt(e.target.value));
        setSelectedUser(tempSelectedUser);
    }

    const selectComparePermissionGroup = (e) => {
        if(e.target.value === ''){
            setComparePermissionGroup(null);
            return;
        }
        const tempComparePermissionGroup = permissionGroups.find(pg => parseInt(pg.id) === parseInt(e.target.value));
        setComparePermissionGroup(tempComparePermissionGroup);
    }

    const selectCompareUser = (e) => {
        const tempCompareUser = users.find(user => parseInt(user.id) === parseInt(e.target.value));
        setCompareUser(tempCompareUser);
    }

    const selectPermissionGroup = async (e) => {
        if(e.target.value === ''){return;}
        const selectedPermissionGroup = permissionGroups.find(pg => parseInt(pg.id) === parseInt(e.target.value));
        const tempSelectedUser = {...selectedUser};
        tempSelectedUser.permission_group_id = selectedPermissionGroup.id;
        tempSelectedUser.permissions = [];
        for(let i = 0; i < selectedPermissionGroup.group_permissions.length; i++){
            tempSelectedUser.permissions.push({
                user_id: tempSelectedUser.id,
                permission_id: selectedPermissionGroup.group_permissions[i].permission_id,
                permission: selectedPermissionGroup.group_permissions[i].permission,
                read: selectedPermissionGroup.group_permissions[i].default_read,
                write: selectedPermissionGroup.group_permissions[i].default_write
            })
        }
        setSelectedUser(tempSelectedUser);
    }

    const setReadPermission = (permission, e) => {
        const tempSelectedUser = {...selectedUser};
        const index = tempSelectedUser.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempSelectedUser.permissions[index].read = e.target.checked;
        setSelectedUser(tempSelectedUser);
    }

    const setWritePermission = (permission, e) => {
        const tempSelectedUser = {...selectedUser};
        const index = tempSelectedUser.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempSelectedUser.permissions[index].write = e.target.checked;
        setSelectedUser(tempSelectedUser);
    }

    const updateUserPermissionGroup = async () => {
        if(selectedUser === null){return;}
        if(selectedUser.permissions === undefined){setErrorPermissionGroupNotAssigned(true);return;}
        
        const compareUser = (await Auth.hitEndpointNew("GET", `user/${selectedUser.id}`))[0];
        if(parseInt(selectedUser.permission_group_id) !== parseInt(compareUser.permission_group_id)){
            await Auth.hitEndpointNew("DELETE", "user/permission", "", selectedUser);
        }

        // Prepare group permissions for insert to database
        for(let i = 0; i < selectedUser.permissions.length; i++){
            delete selectedUser.permissions[i].permission;
        }

        await Auth.hitEndpointNew("PATCH", "user", "", selectedUser);

        setSelectedUser(null);
        setUsers((await Auth.hitEndpointNew("GET", `users`)).sort((u1, u2) => u1.firstname < u2.firstname ? -1 : 1));
        document.getElementById("select-user-id").value = '';
        document.getElementById("select-permission-group-id").value = '';
    }

    const cancelUpdatingUserPermissionGroup = async () => {
        setSelectedUser(null);
        setCompareUser(null);
        setUsers((await Auth.hitEndpointNew("GET", `users`)).sort((u1, u2) => u1.firstname < u2.firstname ? -1 : 1));
        const userElement = document.getElementById("select-user-id");
        const compareUserElement = document.getElementById("select-compare-user-id");
        const groupIdElement = document.getElementById("select-permission-group-id");
        if(userElement){ userElement.value = ''; }
        if(compareUserElement){ compareUserElement.value = ''; }
        if(groupIdElement){ groupIdElement.value = ''; }
    }

    const getMouseCoordinates = (e) => {
        setMousePosition([e.pageX, e.pageY]);
    }

    const addPermissionBoxWithDescription = (permissionFunction) => {
        return (
            <div className='add-permission-with-description-box'>
                <div className='add-permission-name'>Permission</div>
                <div id='add-permission-id' className='add-permission-box'  >
                    {permissions.map((permission, index) => {
                        return (
                            <div key={`${permission.display}-${index}`} className='option' 
                            onClick={() => permissionFunction(permission.id)} 
                            onMouseEnter={((e) => {getMouseCoordinates(e); setShowPermissionDescription(permission.description)})} 
                            onMouseLeave={() => setShowPermissionDescription('')}>
                                <div>{permission.display}</div>
                            </div>
                        )
                    })}
                </div>
                {showPermissionDescription !== '' &&
                <div className='description-box'>
                    <span className='description'>{showPermissionDescription}</span>
                </div>}
            </div>
        )
    }

    return (
        <UserPermissionsContainer mouseX={mousePosition[0] + 20} mouseY={mousePosition[1]}>
            <div className='permission-page'>
                <div className='permission-page-header'>Permissions</div>
                <DropDownWithLabel 
                    className="permission-page-select" 
                    id="permission-page-select-id" 
                    name="permission-page-select" 
                    label="Select" 
                    handleDropDown={(e) => setShowAndCancelAll(e.target.value)} 
                    addBlankOption={true} 
                    selectionList={showSelections} 
                    margin="20px 0 20px 0" 
                    columns={10} 
                    labelStart={1} 
                    labelEnd={4} 
                    inputStart={4} 
                    inputEnd={9}
                />
            </div>
            {show === 'create' &&
            <div className='create-permission-group'>
                <div className='create-permission-group-header'>Create Permission Group</div>
                <TextboxWithLabel 
                    className="create-permission-group-name" 
                    name="create-permission-group-name" 
                    label="Name" 
                    placeholder="Name" 
                    value={newPermissionGroup.display} 
                    handleTextbox={((e) => setNewPermissionGroup({...newPermissionGroup, display: e.target.value}))} 
                    margin="20px 0 10px 0" 
                    columns="10" 
                    labelStart="1" 
                    labelEnd="4" 
                    inputStart="4" 
                    inputEnd="9"
                />
                {addPermissionBoxWithDescription(addPermissionNewPermission)}
                {newPermissionGroup.group_permissions.length > 0 &&
                <div className="read-write-label">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {newPermissionGroup.group_permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.display}-${index}`}  className='permissions-display'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox 
                                className="read" 
                                name="read" 
                                checked={permission.default_read} 
                                handleCheckbox={(e) => setDefaultReadNewPermission(permission, e)} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                            <Checkbox 
                                className="write" 
                                name="write" 
                                checked={permission.default_write} 
                                handleCheckbox={(e) => setDefaultWriteNewPermission(permission, e)} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                            <Button 
                                className="remove-permission" 
                                handleKeyPress={(async (e) => {if(e.key === 'Enter'){removePermissionNewPermission(permission.permission_id)}})} 
                                handleClick={(async (e) => removePermissionNewPermission(permission.permission_id))} 
                                buttonText="Remove" 
                                width="120"
                            />
                        </div>
                    );
                })}
                <div className='two-button-box'>
                    <Button 
                        className="create-group" 
                        handleKeyPress={(async (e) => {if(e.key === 'Enter'){await createNewPermissionGroup()}})} 
                        handleClick={(async (e) => await createNewPermissionGroup())} 
                        buttonText="Create" 
                        width="120"
                    />
                    <Button 
                        className="cancel-create" 
                        handleKeyPress={((e) => {if(e.key === 'Enter'){cancelCreatingNewPermissionGroup()}})} 
                        handleClick={((e) => cancelCreatingNewPermissionGroup())} 
                        buttonText="Cancel" 
                        width="120"
                    />
                </div>
            </div>}
            {(show === 'create') &&
            <div className='compare-permission-group'>
                <div className='compare-permission-group-group-header'>Compare Permission Group</div>
                <DropDownWithLabel 
                    className="fixed-height select-compare-permission-group" 
                    id="select-compare-user-id" 
                    name="select-compare-user" 
                    label="Select Permission Group" 
                    handleDropDown={selectComparePermissionGroup} 
                    addBlankOption={true} 
                    selectionList={permissionGroups} 
                    margin="20px 0 10px 0" 
                    columns={10} 
                    labelStart={1} 
                    labelEnd={4} 
                    inputStart={4} 
                    inputEnd={9}
                />
                <Button 
                    className="Copy-permissions" 
                    handleKeyPress={((e) => {if(e.key === 'Enter'){copyComparePermissionGroupToNewPermissionGroup()}})} 
                    handleClick={((e) => copyComparePermissionGroupToNewPermissionGroup())} 
                    buttonText="Copy To Create Permission Group" 
                    width="300" 
                    height="40"
                />
                {comparePermissionGroup &&
                <div className="read-write-label create-compare">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {comparePermissionGroup && comparePermissionGroup.group_permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.id}-${index}`}  className='permissions-display permission-display-create'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox 
                                className="read" 
                                name="read" 
                                checked={permission.default_read} 
                                handleCheckbox={(e) => logError('Does Nothing')}
                                disabled={true} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                            <Checkbox 
                                className="write" 
                                name="write" 
                                checked={permission.default_write} 
                                handleCheckbox={(e) => logError('Does Nothing')}
                                disabled={true} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                        </div>
                    );
                })}
            </div>}
            {show === 'edit' &&
            <div className='edit-permission-group'>
                <div className='edit-permission-group-header'>Edit Permission Group</div>
                <DropDownWithLabel className="select-group" id="select-permission-group-id" name="select-group" label="Select Group" handleDropDown={selectGroupToEdit} addBlankOption={true} selectionList={permissionGroups} margin="20px 0 10px 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                {addPermissionBoxWithDescription(addPermissionEditPermission)}
                {permissionGroupToEdit && permissionGroupToEdit.group_permissions.length > 0 &&
                <div className="read-write-label">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {permissionGroupToEdit &&
                permissionGroupToEdit.group_permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.display}-${index}`}  className='permissions-display'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox className="read" name="read" checked={permission.default_read} handleCheckbox={(e) => setDefaultReadEditPermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                            <Checkbox className="write" name="write" checked={permission.default_write} handleCheckbox={(e) => setDefaultWriteEditPermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                            <Button className="remove-permission" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await removePermissionEditPermission(permission.permission_id)}})} handleClick={(async (e) => await removePermissionEditPermission(permission.permission_id))} buttonText="Remove" width="120"/>
                        </div>
                    );
                })}
                <div className='two-button-box'>
                    <Button className="update-group" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await updatePermissionGroup()}})} handleClick={(async (e) => await updatePermissionGroup())} buttonText="Update" width="120"/>
                    <Button className="cancel-update" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await cancelUpdatingPermissionGroup()}})} handleClick={(async (e) => await cancelUpdatingPermissionGroup())} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            {(show === 'edit') &&
            <div className='compare-permission-group'>
                <div className='compare-permission-group-group-header'>Compare Permission Group</div>
                <DropDownWithLabel 
                    className="fixed-height select-compare-permission-group" 
                    id="select-compare-user-id" 
                    name="select-compare-user" 
                    label="Select Permission Group" 
                    handleDropDown={selectComparePermissionGroup} 
                    addBlankOption={true} 
                    selectionList={permissionGroups} 
                    margin="20px 0 10px 0" 
                    columns={10} 
                    labelStart={1} 
                    labelEnd={4} 
                    inputStart={4} 
                    inputEnd={9}
                />
                {comparePermissionGroup &&
                <div className="read-write-label edit-compare">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {comparePermissionGroup && comparePermissionGroup.group_permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.id}-${index}`}  className='permissions-display permission-display-edit'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox 
                                className="read" 
                                name="read" 
                                checked={permission.default_read} 
                                handleCheckbox={(e) => logError('Does Nothing')}
                                disabled={true} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                            <Checkbox 
                                className="write" 
                                name="write" 
                                checked={permission.default_write} 
                                handleCheckbox={(e) => logError('Does Nothing')}
                                disabled={true} 
                                columns="2" 
                                inputStart="1" 
                                inputEnd="2"
                            />
                        </div>
                    );
                })}
            </div>}
            {show === 'assign' &&
            <div className='assign-user-permission-group'>
                <div className='assign-user-permission-group-header'>Assign User Permission Group</div>
                <DropDownWithLabel className="select-user" id="select-user-id" name="select-user" label="Select User" handleDropDown={selectUser} addBlankOption={true} selectionList={users.map(user => ({...user, display: `${user.firstname} ${user.lastname}`}))} margin="20px 0 10px 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <DropDownWithLabel className="select-permission-group" id="select-permission-group-id" name="select-permission-group" label="Select Permission Group" value={selectedUser && selectedUser.permission_group_id ? selectedUser.permission_group_id : ''}handleDropDown={selectPermissionGroup} addBlankOption={true} selectionList={permissionGroups} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                {selectedUser && selectedUser.permissions && selectedUser.permissions.length > 0 &&
                <div className="read-write-label">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {selectedUser && selectedUser.permissions && selectedUser.permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.display}-${index}`}  className='permissions-display'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox className="read" name="read" checked={permission.read} handleCheckbox={(e) => setReadPermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                            <Checkbox className="write" name="write" checked={permission.write} handleCheckbox={(e) => setWritePermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                        </div>
                    );
                })}
                <div className='two-button-box'>
                    <Button className="update-user-permission-group" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await updateUserPermissionGroup()}})} handleClick={(async (e) => await updateUserPermissionGroup())} buttonText="Assign" width="120"/>
                    <Button className="cancel-update-user-permission-group" handleKeyPress={((e) => {if(e.key === 'Enter'){cancelUpdatingUserPermissionGroup()}})} handleClick={((e) => cancelUpdatingUserPermissionGroup())} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            {show === 'assign' &&
            <div className='compare-user-information'>
                <div className='assign-user-permission-group-header'>Compare User Permission Group</div>
                <DropDownWithLabel className="fixed-height select-compare-user" id="select-compare-user-id" name="select-compare-user" label="Select User To Compare" handleDropDown={selectCompareUser} addBlankOption={true} selectionList={users.map(user => ({...user, display: `${user.firstname} ${user.lastname}`}))} margin="20px 0 10px 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <div className="compare-user-permission-group-box">
                    <div className='compare-user-permission-group-label'>Permission Group:</div>
                    <div className="compare-user-permission-group">{compareUser && compareUser.permission_group_id ? permissionGroups.find(pg => parseInt(pg.id) === parseInt(compareUser.permission_group_id)).display : 'N/A'}</div>
                </div>
                {compareUser && compareUser.permissions && compareUser.permissions.length > 0 &&
                <div className="read-write-label">
                    <div className="read-label">Read</div>
                    <div className="write-label">Write</div>
                </div>}
                {compareUser && compareUser.permissions && compareUser.permissions.map((permission, index) => {
                    return (
                        <div key={`${permission.display}-${index}`}  className='permissions-display'>
                            <div className="group-permission">{permission.permission.display}</div>
                            <Checkbox className="read" name="read" checked={permission.read} handleCheckbox={(e) => logError('Does Nothing')} disabled={true} columns="2" inputStart="1" inputEnd="2"/>
                            <Checkbox className="write" name="write" checked={permission.write} handleCheckbox={(e) => logError('Does Nothing')} disabled={true} columns="2" inputStart="1" inputEnd="2"/>
                        </div>
                    );
                })}
            </div>}
            {errorNoName && <Popup color={PopupFailureColor} message={"No permission group name entered. Enter a group name"} handlePopup={resetPopups}/>}
            {errorDuplicateName && <Popup color={PopupFailureColor} message={"Duplicate group name. Enter a unique group name"} handlePopup={resetPopups}/>}
            {errorPermissionGroupNotAssigned && <Popup color={PopupFailureColor} message={"Please assign a permission group to the user"} handlePopup={resetPopups}/>}
        </UserPermissionsContainer>
    );
}

export default UserPermissions;