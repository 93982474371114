// Global constants
import XLSX from 'xlsx';
import {logError} from "../../utilities/error";

// TODO delete crews here change the crew selection to pull from Crews Table

class Globals {
    constructor(){
        this.months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.positionValues = [ 'office', 'super', 'general', 'other' ];
        this.fieldValues = [ 'concrete', 'plumbing', 'rfg', 'trash', 'masonry', 'pavers', 'gates', 'other' ];
        this.roleValues = [ 'Admin', 'User', 'User - Starts', 'User - Dispatch'];
        this.jobTypeValues = ['single-family', 'multi-family', 'commercial'];
        this.crewMemberPositions = [
            'Leader',
            'Member'
        ]
        this.paverStylesAndColors = [
            {style: 'Dimensions 6',         colors: ['Bella', 'Rio', 'Sierra', 'Sonoran Gray', 'Victorian']},
            {style: 'Origins 6',            colors: ['Bella', 'Sierra', 'Sonoran Gray', 'Victorian']},
            {style: 'Avalon Slate',         colors: ['Bella', 'Sierra', 'Sonoran Gray', 'Victorian']},
            {style: 'Cambridge Cobble',     colors: ['Sierra']},
            {style: 'Holland',              colors: ['Rio', 'Sierra', 'Victorian']}
        ]
        this.prospectiveEmployeeJobs = [
            {category: 'Field',  position: 'Concrete'},
            {category: 'Field',  position: 'Plumbing'},
            {category: 'Field',  position: 'Framing'},
            {category: 'Field',  position: 'Masonry'},
            {category: 'Field',  position: 'Pavers'},
            {category: 'Field',  position: 'Gates'},
            {category: 'Field',  position: 'Trash Services'},
            {category: 'Field',  position: 'Grading'},
            {category: 'Field',  position: 'Backhoe'},
            {category: 'Field',  position: 'Driver'},
            {category: 'Field',  position: 'CDL Driver'},
            {category: 'Field',  position: 'Mix Truck Driver'},
            {category: 'Field',  position: 'Yard'},
            {category: 'Field',  position: 'Welder'},
            {category: 'Field',  position: 'Warehouse'},
            {category: 'Field',  position: 'Mechanic'},
            {category: 'Field',  position: 'Dump Truck Driver'},
            {category: 'Field',  position: 'Other'},
            {category: 'Office', position: 'Accounts Receivable'},
            {category: 'Office', position: 'Accounts Payable'},
            {category: 'Office', position: 'HR'},
            {category: 'Office', position: 'Payroll'},
            {category: 'Office', position: 'Estimating'},
            {category: 'Office', position: 'Dispatch'},
            {category: 'Office', position: 'Customer Service'},
            {category: 'Office', position: 'Administrative Assistant'},
            {category: 'Office', position: 'Other'},
        ]
        this.prospectiveEmployeeSkillLevels = [
            {category: 'Field', levels: ['None', 'Apprentice', 'Journry Man', 'Crew Leader', 'Foreman']},
            {category: 'Office', levels: ['0-1 Years', '1-3 Years', '3-5 Years', '5+ Years']},
        ]
        this.prospectiveEmployeeStatusCodes = [
            'New', 'Contacted'
        ]
    }

    getMonths(){return this.months;}
    getProspectiveEmployeeJobs(){return this.prospectiveEmployeeJobs;}
    getProspectiveEmployeeSkillLevels(){return this.prospectiveEmployeeSkillLevels}
    getProspectiveEmployeeStatusCodes(){return this.prospectiveEmployeeStatusCodes}

    getEWADateSearchType(){
        return [
            {id: 'schedule.schedule_date', display: "Scheduled On Date"},
            {id: 'ewa.created_at', display: "Created Date"},
            {id: 'approval_at', display: "Approval Date"},
            {id: 'declined', display: "Declined Date"},
            {id: 'schedule.completed_date', display: "Completed Date"},
            {id: 'ewa.printed_at', display: "Printed Date"}
        ]
    }

    getDateSearchType(){
        return [
            {value: 'schedule_date', label: "Scheduled On Date"},
            {value: 'created_at', label: "Created Date"},
            {value: 'approval_at', label: "Approval Date"},
            {value: 'declined', label: "Declined Date"},
            {value: 'completed_date', label: "Completed Date"},
            {value: 'printed_at', label: "Printed Date"}
        ]
    }

    formatDateToDisplay(date){
        if(date === '' || date === undefined || !date || date === 'Invalid Date'){
            return '';
        }
        try {
            if (typeof date === 'object') {
                const isoDate = date.toISOString();
                const temp = isoDate.substring(0, isoDate.indexOf('T')).split('-');
                return `${temp[1]}/${temp[2]}/${temp[0]}`
            }

            if(date.includes('T')){   
                const temp = date.substring(0, date.indexOf('T')).split('-');
                return `${temp[1]}/${temp[2]}/${temp[0]}`
            }

            const temp = date.split(' ')[0].split('-');
            return `${temp[1]}/${temp[2]}/${temp[0]}`
        }
        catch(e){
            logError(e)
            return ''
        }
    }

    formatDateForDatabase(date){
        if(date === '' || date === undefined || !date || date === 'Invalid Date'){
            return '';
        }
        try {
            if (typeof date === 'object') {
                const isoDate = date.toISOString();
                const temp = isoDate.substring(0, isoDate.indexOf('T')).split('-');
                return `${temp[0]}-${temp[1]}-${temp[2]}`
            }

            if(date.includes('T')){   
                const temp = date.substring(0, date.indexOf('T')).split('-');
                return `${temp[0]}-${temp[1]}-${temp[2]}`
            }

            const temp = date.split(' ')[0].split('-');
            return `${temp[0]}-${temp[1]}-${temp[2]}`
        }
        catch(e){
            logError(e)
            return ''
        }
    }

    exportToXLSXFileFormat(data, sheetName, fileName){
        // Data must be an array of unpacked objects with no sub-objects or sub-arrays
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, fileName);
    }
}

const globals = new Globals();
export default globals;
