import MainHeader from '../utilities/MainHeader';
import FieldFormsContainer from './styles/FieldFormsContainer';
import FieldMenu from "./FieldMenu";

const FieldForms = (props) => {

    return (
        <FieldFormsContainer>
            <FieldMenu/>
        </FieldFormsContainer>
    )
};

export default FieldForms;
