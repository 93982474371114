import styled from 'styled-components';

const EWAFormContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 0;

    .ewa-form-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .ewa-form-sub-header,
    .ew-form-sub-header{
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
    }

    .ew-form-sub-header{
        border-top: 2px solid white;
    }

    .ew-form-box{
        display: grid;
        background: rgb(210, 200, 190);
        margin-bottom: 20px;
        
        .ew-form-phase-drop-down-box,
        .ew-form-phase-code-drop-down-box,
        .ew-form-job-description-box,
        .ew-scheduled-on-date,
        .ew-form-amount-box,
        .ew-form-internal-estimate-number-box,
        .ew-form-builder-po-number-box,
        .ew-form-employee-drop-down-box{
            display: grid;
            grid-template-columns: 15% 85%;
            margin: 5px 0 5px 0;
            padding-bottom: 5px;
            .ew-form-phase-drop-down-label,
            .ew-form-phase-code-drop-down-label,
            .ew-form-job-description-header,
            .ew-scheduled-on-label,
            .amount-label,
            .ew-internal-estimate-number-label,
            .ew-builder-po-number-label,
            .ew-form-employee-drop-down-label{
                // grid-column: 1/8;
                display: grid;
                margin-right: 5px;
                justify-content: end;
                align-items: center;
                width: 95%;
            }

            
            .amount-label{
                margin-right: 2px;
            }

            .ew-form-phase-code-drop-down-select,
            .ew-form-phase-drop-down-select,
            .ew-form-job-description-text-area,
            .ew-scheduled-on-date-picker-box,
            .amount-input-box,
            .internal-estimate-number-input-box,
            .builder-po-number-input-box,
            .ew-form-employee-drop-down-select,
            .employee-select{
                display: grid;
                width: 95%;
            }
        }
        
        .amount-input,
        .schedule-date-picker,
        .ew-form-job-description-text-input,
        .ew-form-phase-drop-down-select,
        .ew-form-phase-code-drop-down-select,
        .internal-estimate-number-input,
        .builder-po-number-input,
        .employee-select{
            background-color: white;
            border: 1px solid rgb(120, 120, 120);
            font-size: small;                        
        }
        
        .ew-form-phase-drop-down-box{
            margin-top: 15px;
        }
  
        .ew-form-submit {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 10px 0 20px;
            
            .ew-form-submit-button {
                justify-self: center;
                width: 250px;
                height: 30px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }
    }
`

export default EWAFormContainer;