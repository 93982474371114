import {useEffect, useState} from 'react';
import Split from "react-split";
import Select, { createFilter } from 'react-select'

import PageContainer from '../scheduling/styles/PageContainer.js';
import SplitPane from '../utilities/styles/SplitPane.js';
import Phases from "../../classes/Phases";
import PhaseTasks from "../../classes/PhaseTasks";
import Schedules from "../../classes/Schedules";
import Crews from "../../classes/Crews";
import Employees from "../../classes/Employees";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import ScheduleTable from "../scheduling/ScheduleTable";
import ScheduleRequestTable from '../scheduling/ScheduleRequestTable.js';
import ScheduleCrew from "../scheduling/crew/ScheduleCrew";
import CrewGrid from "../scheduling/crew/CrewGrid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Auth from "../authentication/Auth"
import dayjs from "dayjs";
import LockList from "../scheduling/LockList";

import {AvailableLots} from "../scheduling/AvailableLots"
import DialogActions from "@mui/material/DialogActions";
import DetailNew from "../scheduling/detail/DetailNew";
import EWAs from "../../classes/Ewas";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import {ArrowRight} from "@mui/icons-material";
import {logError} from "../../utilities/error";
import WIPPageContainer from "./styles/WIPPageContainer";
import WIPTableConcrete from "./WIPTableConcrete";

const WIPPage = (props) => {

    const [didLoadData, setDidLoadData] = useState(false)
    const [locks, setLocks] = useState([])
    const [isLocked, setIsLocked] = useState(false)
    const [saveStatus, setSaveStatus] = useState('')
    const [userId, setUserId] = useState(-1)

    const [availableLots, setAvailableLots] = useState([])
    const [availableLotsFiltered, setAvailableLotsFiltered] = useState([])
    const [selectedLots, setSelectedLots] = useState([])
    const [selectedLotsFull, setSelectedLotsFull] = useState([])
    const [availableJobs, setAvailableJobs] = useState([])
    const [supers, setSupers] = useState([])
    const [crews, setCrews] = useState([])
    const [employees, setEmployees] = useState([])
    const [suppliers, setSuppliers] = useState([])

    //Selections Objects
    const [selectedSuper, setSelectedSuper] = useState(null)
    const [selectedPhase, setSelectedPhase] = useState(null)
    const [selectedPhaseCompany, setSelectedPhaseCompany] = useState(null)
    const [selectedPhaseId, setSelectedPhaseId] = useState(null)
    const [selectedTasks, setSelectedTasks] = useState([])
    const [selectedJob, setSelectedJob] = useState('')
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [selectedEmployeesReassign, setSelectedEmployeesReassign] = useState([])
    const [empsToRemove, setEmpsToRemove] = useState([])

    const [scheduleTab, setScheduleTab] = useState('work')

    const [notes, setNotes] = useState('')
    const [searchTerm, setSearchTerm] = useState('')

    const [schedule, setSchedule] = useState([])
    const [phaseTasks, setPhaseTasks] = useState([])
    const [phases, setPhases] = useState([])
    const [selectPhases, setSelectPhases] = useState([])
    const [scheduleDate, setScheduleDate] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [loadingWhat, setLoadingWhat] = useState('')

    const [detailOpen, setDetailOpen] = useState(false)

    const [useEwaTasks, setUseEwaTasks] = useState(false)
    const [hideLots, setHideLots] = useState(false)

    const [buttonText, setButtonText] = useState('WIP Cycle Time Report - Concrete');

    useEffect(() => {
        const loadData = async () => {
            if(Utilities.isLoggedOut()){
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            }
            else {
                let date = new Date()

                setIsLoading(true)
                setDidLoadData(true);
                const user = await Auth.getUserId()
                setUserId(user)
                // setSelectedDate(date)
                // setScheduleDate(dayjs(date).format('YYYY-MM-DD'))
                let dbLocks = await Schedules.loadLockDate(date)
                setLocks(dbLocks)
                setIsLocked(await Schedules.checkLocked(dbLocks))
                setLoadingWhat('Phases')

                setPhaseTasks(await Auth.hitEndpointNew("GET", "wip/concrete/tasks", ``))
                setLoadingWhat('Supers')
                setSupers(await Schedules.loadSupers(null))

                setLoadingWhat('Suppliers')
                setSuppliers(await Schedules.loadSuppliers(null))

                setIsLoading(false)
                setLoadingWhat('')
            }
        }

        if (!didLoadData) {
            loadData();
        }
    }, [didLoadData]);

    const [data, setData] = useState([])
    const createWIPCycleTimeReportConcrete = async () => {

        setButtonText('Fetching Report...');
        let stat = await Auth.hitEndpointNew("GET", "wip/concrete", ``);

        if(stat.length === 0){
            logError("NO DATA RETURNED");
            return;
        }

        // let currentFirstName = '';
        // let currentBuilder = '';
        // const tempData = [];
        // for(let i = 0; i < stat.length; i++){
        //     if(stat[i].firstname !== currentFirstName){
        //         currentFirstName = stat[i].firstname;
        //         tempData.push({
        //             lineType: 'name',
        //             firstname: stat[i].firstname,
        //             lastname: stat[i].lastname
        //         })
        //     }
        //     if(stat[i].name !== currentBuilder){
        //         currentBuilder = stat[i].name;
        //         tempData.push({
        //             lineType: 'builder',
        //             builderName: stat[i].name
        //         })
        //     }
        //
        //     const layout                = stat[i].phase_tasks.find(pt => pt.id === 5);
        //     const excavateFooting       = stat[i].phase_tasks.find(pt => pt.id === 8);
        //     const spreadPerimeter       = stat[i].phase_tasks.find(pt => pt.id === 10);
        //     const setPerimeter          = stat[i].phase_tasks.find(pt => pt.id === 11);
        //     const soilLabor             = stat[i].phase_tasks.find(pt => pt.id === 21);
        //     const deliverABC            = stat[i].phase_tasks.find(pt => pt.id === 27);
        //     const backfillCompact       = stat[i].phase_tasks.find(pt => pt.id === 31);
        //     const roughGradeSpreadABC   = stat[i].phase_tasks.find(pt => pt.id === 32);
        //     const setFloorLinePerm      = stat[i].phase_tasks.find(pt => pt.id === 33);
        //     const boxWrapLabor          = stat[i].phase_tasks.find(pt => pt.id === 36);
        //     const finishFloor           = stat[i].phase_tasks.find(pt => pt.id === 41);
        //
        //     tempData.push({
        //         lineType: 'data',
        //         projectName: stat[i].project_name,
        //         jobNumber: stat[i].job_number,
        //         lotNumber: stat[i].lot_number,
        //         releaseDate: dayjs(new Date(stat[i].release_date)).format('MM-DD-YYYY'),
        //         layout:               layout !== undefined && layout.completed_date !== null ? dayjs(new Date(layout.completed_date)).format('MM-DD-YYYY') : '',
        //         excavateFooting:      excavateFooting !== undefined && excavateFooting.completed_date !== null ? dayjs(new Date(excavateFooting.completed_date)).format('MM-DD-YYYY') : '',
        //         spreadPerimeter:      spreadPerimeter !== undefined && spreadPerimeter.completed_date !== null ? dayjs(new Date(spreadPerimeter.completed_date)).format('MM-DD-YYYY') : '',
        //         setPerimeter:         setPerimeter !== undefined && setPerimeter.completed_date !== null ? dayjs(new Date(setPerimeter.completed_date)).format('MM-DD-YYYY') : '',
        //         soilLabor:            soilLabor !== undefined && soilLabor.completed_date !== null ? dayjs(new Date(soilLabor.completed_date)).format('MM-DD-YYYY') : '',
        //         deliverABC:           backfillCompact !== undefined && backfillCompact.completed_date !== null ? dayjs(new Date(backfillCompact.completed_date)).format('MM-DD-YYYY') : '',
        //         backfillCompact:      deliverABC !== undefined && deliverABC.completed_date !== null ? dayjs(new Date(deliverABC.completed_date)).format('MM-DD-YYYY') : '',
        //         roughGradeSpreadABC:  roughGradeSpreadABC !== undefined && roughGradeSpreadABC.completed_date !== null ? dayjs(new Date(roughGradeSpreadABC.completed_date)).format('MM-DD-YYYY') : '',
        //         setFloorLinePerm:     setFloorLinePerm !== undefined && setFloorLinePerm.completed_date !== null ? dayjs(new Date(setFloorLinePerm.completed_date)).format('MM-DD-YYYY') : '',
        //         boxWrapLabor:         boxWrapLabor !== undefined && boxWrapLabor.completed_date !== null ? dayjs(new Date(boxWrapLabor.completed_date)).format('MM-DD-YYYY') : '',
        //         finishFloor:          finishFloor !== undefined && finishFloor.completed_date !== null ? dayjs(new Date(finishFloor.completed_date)).format('MM-DD-YYYY') : '',
        //     })
        // }

        setData(stat);

        setButtonText('WIP Cycle Time Report - Concrete');
    }

    const handlePhaseOnChange = async (selection) => {
        setIsLoading(true)
        setAvailableLots([])
        setAvailableLotsFiltered([])
        setAvailableJobs([])

        let phaseTaskIds = []
        if(selectedDate === null || selectedDate === ''){
            alert('Please select a date first')
        }
        else {
            setSelectedPhase(selection)
            if (selection === null) {
                setSelectedTasks([])
                setPhaseTasks([])
            } else {
                phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
            }
            if (selection === null || (selectedPhaseCompany !== null && selection.phase.company !== selectedPhaseCompany)) {
                setSelectedEmployees([])
                setSelectedEmployeesReassign([])
            }
            setSelectedPhaseCompany(selection === null ? null : selection.phase.company)
            let phaseId = selection !== null ? selection.value : null

            setSelectedPhaseId(phaseId)
            setLoadingWhat('Phase Tasks')

            if (phaseId !== null) {
                setPhaseTasks(await PhaseTasks.loadPhaseTasks({"phase_task_phases.phase_id": phaseId}))
            }

            setLoadingWhat('Available Jobs')
            if (phaseTaskIds.length > 0) {
                setAvailableJobs(await Schedules.loadAvailableJobs())
            }
            setLoadingWhat('Available Lots')
            let avlLots = []
            if (phaseTaskIds.length > 0 && selectedJob !== null) {
                avlLots = await Schedules.loadAvailableLots(selectedJob.id, phaseId, phaseTaskIds, schedule)
            }
            setAvailableLots(avlLots)
            setAvailableLotsFiltered(avlLots)

            await handleLoadEmployees(selection)
            await handleLoadCrews(selection)
            await handleLoadSchedule(selectedDate, phaseId, phaseTaskIds, scheduleTab)
            setIsLocked(await Schedules.checkLocked(locks, phaseId))
        }
        setIsLoading(false)
        setLoadingWhat('')
    };

    const handleLoadEmployees = async (phase) => {
        let queryObj = phase !== null ? {[phase.phase.company.toLowerCase()]: 1} : null
        setEmployees(await Employees.loadEmployeesForDropDown(queryObj, selectedDate.format('YYYY-MM-DD')))
    }
    const handleLoadCrews = async (phase) => {
        let queryObj = phase !== null ? {company: phase.phase.company} : null
        setCrews(await Crews.loadCrewsForDropdown(queryObj, selectedDate.format('YYYY-MM-DD')))
    }

    const handlePhaseTaskOnChange = async (selections) => {
        setIsLoading(true)
        setSelectedTasks(selections)
        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selections)

        setLoadingWhat('Available Jobs')
        if(phaseTaskIds.length > 0) {
            setAvailableJobs(await Schedules.loadAvailableJobs())
        }
        setLoadingWhat('Available Lots')
        let avlLots = []
        if(phaseTaskIds.length > 0 && selectedJob !== ''){
            avlLots = await Schedules.loadAvailableLots(selectedJob.id, selectedPhaseId, phaseTaskIds, schedule)
        }
        setAvailableLots(avlLots)
        setAvailableLotsFiltered(avlLots)

        await handleLoadSchedule(selectedDate, selectedPhaseId, phaseTaskIds, scheduleTab)
        setIsLoading(false)
        setLoadingWhat('')
    }

    const handleJobChange = async (e) => {
        setIsLoading(true)
        setSelectedJob(e)
        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
        setLoadingWhat('Available Lots')
        if(e !== null) {
            let lots = await Schedules.loadAvailableLots(e.id, selectedPhaseId, phaseTaskIds, schedule)
            setAvailableLots(lots)
            setAvailableLotsFiltered(lots)
        }

        setIsLoading(false)
        setLoadingWhat('')
    };

    const handleSuperChanged = async (e) => {
        let selectPhases = [{
            label: 'Assigned',
            options: []
        },
            {
                label: 'Other',
                options: []
            }]
        let sup = e === null ? '' : supers.find(s => s.id.toString() === e.value.toString())
        phases.forEach(p => {
            if (sup !== undefined && sup.field_id !== undefined && p.field_id.toString() === sup.field_id.toString()) {
                selectPhases[0].options.push({value: p.id, label: p.name, phase: p})
            } else {
                selectPhases[1].options.push({value: p.id, label: p.name, phase: p})
            }
        })
        setSelectPhases(selectPhases)

        setSelectedSuper(e === null ? null : supers.find(x => x.id.toString() === e.value.toString()))

        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
        setLoadingWhat('Available Jobs')
        setAvailableJobs(await Schedules.loadAvailableJobs())
        setLoadingWhat('Available Lots')
        let avlLots = []
        if(phaseTaskIds.length > 0 && selectedJob !== ''){
            avlLots = await Schedules.loadAvailableLots(selectedJob.id, selectedPhaseId, phaseTaskIds, schedule)
        }
        setAvailableLots(avlLots)
        setAvailableLotsFiltered(avlLots)

        setLoadingWhat('')
    }

    const handleDateChanged = async date => {
        await handleClearFilters()

        setIsLoading(true)
        setScheduleDate(dayjs(date).format('YYYY-MM-DD'))
        setSelectedDate(date)

        let dbLocks = await Schedules.loadLockDate(date)
        setLocks(dbLocks)
        setIsLocked(await Schedules.checkLocked(dbLocks, selectedPhaseId))

        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
        await handleLoadSchedule(date, selectedPhaseId, phaseTaskIds, scheduleTab)
        setIsLoading(false)
    }

    //separated this so we can call it without the job.id as a filter. It does get a new queryObj again but should not have an impact on performance and should be consistent
    const handleLoadSchedule = async (date, phase, phaseTaskIds, type) => {
        if(date === null || date === ''){
            // alert("Select a Date First")
        }
        else {
            setLoadingWhat('Schedule')
            let empIds = await getEmployeeFilter(selectedEmployees)
            //sending in null for job so that does not get added to the query.
            let qObj = await getAvailableAndScheduleFilters(null, phase, phaseTaskIds, '', type)
            setSchedule(await Schedules.loadSchedule(date, qObj, empIds))
            setLoadingWhat('')
        }
    }

    const getAvailableAndScheduleFilters = async (job, phase, phaseTaskIds, superintendent, type) => {
        let qObj = {}
        if(type === 'work') {
            qObj["schedule.scheduled"] = 1
            if (job !== undefined && job !== null && job !== '') {
                qObj["job.id"] = job.id
            }
            if (phase !== null) {
                qObj["phase_task_phases.phase_id"] = phase
            }
            if (phaseTaskIds !== null && phaseTaskIds.length > 0) {
                qObj["phase_task.id"] = phaseTaskIds
            }
            if (superintendent !== undefined && superintendent !== null && superintendent !== '') {
                qObj['job_assignment.user_id'] = superintendent
            }
        }
        if(type === 'request'){
            qObj["schedule.scheduled"] = 0
            qObj["schedule.requested"] = 1
        }
        return qObj
    }
    const getEmployeeFilter = async (selectedEmps) => {
        let empIds = []
        selectedEmps.forEach(emp => {
            empIds.push(emp.id)
        })
        return empIds
    }

    const handleSearchAvailableLotsChange = (e) => {
        setSearchTerm(e.target.value)
        let filterLots = availableLots.filter(x => x.phase.includes(e.target.value) || x.number.includes(e.target.value) || x.address.includes(e.target.value))
        setAvailableLotsFiltered(filterLots)
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value)
    }

    const handleClearFilters = () => {
        setSelectedTasks([])
        setSchedule([])
        setSelectedSuper('')
        setSelectedPhase(null)
        setSelectedPhaseId(null)
        setSelectedJob('')
        setSelectedLots([])
        setAvailableJobs([])
        setAvailableLots([])
        setAvailableLotsFiltered([])
        setSelectedEmployees([])
        setNotes('')
    }
    //Add to the schedule - in state only. User will still need to click save to save changes
    const handleAddSelectedLotsToSchedule = async () => {
        if (!hasErrors()) {
            await addLineToSchedule(selectedLots)
        }
    };
    const hasErrors = () => {
        if (scheduleDate === '') {
            alert("Please select a date first")
            return true
        } else if (selectedTasks.length === 0) {
            alert("Select a task first")
            return true
        } else {
            return false
        }
    }

    const hasEwa = (selectedPhaseTasks) => {
        return selectedPhaseTasks.filter(x => x.type === 'ewa').length > 0
    }

    const addLineToSchedule = async (lots, detailAdded) => {
        let selectedPhaseTasks = getSelectedPhaseTasks()
        let ewa = hasEwa(selectedPhaseTasks)
        if(ewa && selectedEmployees.length === 0){
            alert("You are trying to schedule an EWA phase code. Select at least one employee first.")
        }
        else {
            let needsDetails = selectedPhaseTasks.filter(x => x.detail.length > 0).length > 0
            if (needsDetails && !detailAdded) {
                let newLots = []
                for (let i = 0; i < lots.length; i++) {
                    const lt = lots[i]
                    const lot = availableLots.find(l => l.id.toString() === lt.toString())
                    if (lot !== undefined && newLots.filter(x => x.id.toString() === lt.toString()).length === 0) {
                        newLots.push(lot)
                    }
                    setSelectedLotsFull(newLots)
                }
                setDetailOpen(true)
            } else {
                let response = await Schedules.addLineToSchedule(lots, selectedPhaseId, selectedPhaseTasks, schedule, availableLots, selectedLots, selectedEmployees, selectedDate, userId, notes, selectedLotsFull, ewa)
                setSchedule((response.schedule))
                setAvailableLotsFiltered(response.availableLots)
                setAvailableLots(response.availableLots)
                if(ewa) {
                    await EWAs.saveNewEWAsFromSchedule(response.ewas)
                }
                await handleSubmit(response.schedule)
            }
        }
    }
    const getSelectedPhaseTasks = () => {
        let selectedPhaseTasks = []
        selectedTasks.forEach(st => {
            let task = phaseTasks.find(t => t.id.toString() === st.value.toString())
            if (task !== undefined) {
                selectedPhaseTasks.push(task)
            }
        })
        return selectedPhaseTasks
    }
    const getSelectedPhaseNames = (onlyForDetails) => {
        let selectedPhaseTasks = ''
        selectedTasks.forEach(st => {
            let task = phaseTasks.find(t => t.id.toString() === st.value.toString())
            if (task !== undefined && (!onlyForDetails || task.schedule_detail_type !== null)) {
                selectedPhaseTasks += ' ' + task.name
            }
        })
        return selectedPhaseTasks.trim()
    }


    const handleSubmit = async (data) => {
        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
        let queryObj = await getAvailableAndScheduleFilters(null, selectedPhaseId, phaseTaskIds,null)
        let response = await Schedules.saveSchedule(data)
        if (response.message !== undefined) {
            setSaveStatus(response.message)
            setSchedule(await Schedules.loadSchedule(selectedDate, queryObj, []))
            setSelectedLots([])
        } else {
            setSaveStatus('Error occurred. Your changes were not saved')
        }
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);
    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };


    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        if(selectedDate === null || selectedDate === '') {
            alert("Please Select a Date first")
        }
        else if(selectedPhase === null) {
            alert("Please Select a Phase first")
        }
        else {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClearSelectedEmployees = () => {
        setSelectedEmployees([])
    }

    const handleSwitchTasks = () => {
        let useEwa = useEwaTasks !== true
        setUseEwaTasks(useEwa)
    }
    const handleShowHideLots = () => {
        let hide = hideLots !== true
        setHideLots(hide)
        let filterLots
        if(hide) {
            filterLots = availableLots.filter(x => (x.number.includes(searchTerm) || x.address.includes(searchTerm)) && x.available === null)
        }
        else{
            filterLots = availableLots.filter(x => (x.number.includes(searchTerm) || x.address.includes(searchTerm)))
        }
        setAvailableLotsFiltered(filterLots)
    }

    const handleCloseDetail = () => {
        setDetailOpen(false)
    }
    const handleDetailCancel = () => {
        setDetailOpen(false)
    }
    const handleDetailSave = async () => {
        let lots = []
        for(let l=0; l<selectedLotsFull.length; l++){
            lots.push(selectedLotsFull[l].id)
        }
        await addLineToSchedule(lots, true)
        setDetailOpen(false)
    }

    const handleScheduleTabChanged = async (e, type) =>{
        if(selectedDate === null || selectedDate === ''){
            alert("Please Select a Date First")
        }
        else {
            if (!isLoading) {
                setIsLoading(true)
                setScheduleTab(type)
                let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
                await handleLoadSchedule(selectedDate, selectedPhaseId, phaseTaskIds, type)
                setIsLoading(false)
            }
        }
    }

    const styles = {
        viewer: {
            margin: '20px auto',
            width: '1200px',
            height: '500px',
        },
        page: {
            paddingBottom: '16mm',
            backgroundColor: 'white',
            width: '216mm',
            height: '280mm'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            marginTop: '5mm',

            company: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '3mm',
                width: '30%',
                textAlign: 'center',
            },
            title: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '5mm',
                width: '40%',
                textAlign: 'center',
            },
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: '15mm',
            paddingRight: '15mm',
            top: '195mm',
            width: '100%',
            fontSize: '3mm',
        },
        tableHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

            columns: {
                marginLeft: '1pt',
                marginRight: '1pt',
                paddingTop: '1.5mm',
                paddingBottom: '1.5mm',
                backgroundColor: 'rgb(220, 220, 220)',
                fontFamily: 'Helvetica',
                fontSize: '2.5mm',
                textAlign: 'center',
            },
            cProjectName: {
                width: '18%'
            },
            cJobNumber: {
                width: '5%'
            },
            cLotNumber: {
                width: '5%'
            },
            cDate: {
                width: '5%'
            },
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '2pt',

            columns: {
                height: '6mm',
                marginLeft: '1pt',
                marginRight: '1pt',
                paddingTop: '1.5mm',
                fontFamily: 'Helvetica',
                fontSize: '2.5mm',
                textAlign: 'center',
            },
            info: {
                width: '91.5%',
                paddingLeft: '2mm',
                textAlign: 'start',
            },
            cProjectName: {
                width: '18%'
            },
            cJobNumber: {
                width: '5%'
            },
            cLotNumber: {
                width: '5%'
            },
            cDate: {
                width: '5%'
            },
        },
    };

    return (
        <WIPPageContainer>
            <div className='schedule-toolbar'>
                {isLoading && <div className='schedule-toolbar-text'>Loading {loadingWhat}</div>}
                {saveStatus && <div className='schedule-toolbar-text'>Save Status {saveStatus}</div>}
            </div>
            <SplitPane>
                <Split sizes={[25, 85]} direction="horizontal" className="split">
                    <section className="pane sidebar">
                        {/*<legend>Select A Date <DeleteIcon onClick={handleClearFilters} style={{float: 'right', cursor: 'pointer', fontSize: 'medium'}}></DeleteIcon></legend>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="column">*/}
                        {/*        <fieldset style={{minHeight: "58px"}}>*/}
                        {/*            <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                        {/*                <DatePicker*/}
                        {/*                    onChange={(newValue) => {*/}
                        {/*                        handleDateChanged(newValue);*/}
                        {/*                    }}*/}
                        {/*                    slotProps={{*/}
                        {/*                        textField: {*/}
                        {/*                            helperText: '',*/}
                        {/*                            sx: {minWidth: '120px'},*/}
                        {/*                            variant: 'standard',*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                    disabled={isLoading}*/}
                        {/*                    isDisabled={isLoading}*/}
                        {/*                    defaultValue={dayjs(selectedDate)}*/}
                        {/*                    selected={selectedDate}*/}
                        {/*                />*/}
                        {/*            </LocalizationProvider>*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*    <div className="column">*/}
                        {/*        <fieldset style={{minHeight: "58px"}}>*/}
                        {/*            <Select*/}
                        {/*                id="super-filter-select-id"*/}
                        {/*                label="Select A Superintendent"*/}
                        {/*                required*/}
                        {/*                onChange={(e) => handleSuperChanged(e)}*/}
                        {/*                options={supers}*/}
                        {/*                isClearable*/}
                        {/*                isSearchable*/}
                        {/*                value={selectedSuper}*/}
                        {/*                disabled={isLoading}*/}
                        {/*                isDisabled={isLoading}*/}
                        {/*                filterOption={createFilter(filterConfig)}*/}
                        {/*                placeholder={"Select a Superintendent"}*/}
                        {/*            />*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*{scheduleTab !== 'request' && <div>*/}
                        {/*    <fieldset>*/}
                        {/*        {scheduleTab === 'work' && <legend>Select A Phase & Task:</legend>}*/}
                        {/*        {scheduleTab === 'ewa' && <legend>Select an EWA Code:</legend>}*/}
                        {/*        <div className="row" style={{textAlign: 'right'}}>*/}
                        {/*            <label className="switch">*/}
                        {/*                <input type="checkbox" onChange={handleSwitchTasks}/>*/}
                        {/*                <span className="slider"></span>*/}
                        {/*            </label>*/}
                        {/*            {useEwaTasks && <span className={'ewa-task-label'}>EWA Tasks</span>}*/}
                        {/*        </div>*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="column">*/}
                        {/*                {selectPhases && selectPhases[0] && selectPhases[1] &&*/}
                        {/*                    <Select*/}
                        {/*                        id="phase-list"*/}
                        {/*                        isClearable*/}
                        {/*                        isMulti={false}*/}
                        {/*                        isSearchable*/}
                        {/*                        value={selectedPhase}*/}
                        {/*                        onChange={(e) => {*/}
                        {/*                            handlePhaseOnChange(e);*/}
                        {/*                        }}*/}
                        {/*                        disabled={isLoading}*/}
                        {/*                        isDisabled={isLoading}*/}
                        {/*                        options={selectPhases}/>*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <div className="column">*/}
                        {/*                <Select*/}
                        {/*                    value={selectedTasks}*/}
                        {/*                    isMulti*/}
                        {/*                    options={(useEwaTasks ? phaseTasks.filter(x => x.type === 'ewa') : phaseTasks.filter(x => x.type === 'work')).map((item, i) => (*/}
                        {/*                        {*/}
                        {/*                            value: item.id,*/}
                        {/*                            label: item.code === null ? item.name : item.code + ' ' + item.name,*/}
                        {/*                            detail: item.detail,*/}
                        {/*                        }*/}
                        {/*                    ))}*/}
                        {/*                    onChange={(e) => {*/}
                        {/*                        handlePhaseTaskOnChange(e);*/}
                        {/*                    }}*/}
                        {/*                    disabled={isLoading}*/}
                        {/*                    isDisabled={isLoading}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </fieldset>*/}
                        {/*    <div className="row">*/}
                        {/*        <fieldset>*/}
                        {/*            <legend>Select Employees</legend>*/}
                        {/*            <CrewGrid*/}
                        {/*                style={{height: selectedEmployees.length, minHeight: "0px !important"}}*/}
                        {/*                selectedEmployees={selectedEmployees}*/}
                        {/*                setSelectedEmployees={setSelectedEmployees}*/}
                        {/*                setSelectedEmployeesEdit={setSelectedEmployees}*/}
                        {/*                selectedEmployeesEdit={selectedEmployees}*/}
                        {/*                empsToRemove={empsToRemove}*/}
                        {/*                setEmpsToRemove={setEmpsToRemove}*/}
                        {/*                minHeight={"0px"}*/}
                        {/*                marginTop={"0px"}*/}
                        {/*            />*/}
                        {/*            <Button variant="contained" onClick={handleClickOpen} disabled={isLoading} style={{*/}
                        {/*                width: "20%",*/}
                        {/*                fontSize: "x-small",*/}
                        {/*                float: "right",*/}
                        {/*                marginTop: "15px",*/}
                        {/*                marginLeft: "2px"*/}
                        {/*            }}>*/}
                        {/*                Employees*/}
                        {/*            </Button>*/}
                        {/*            <Button variant="contained" disabled={isLoading} onClick={handleClearSelectedEmployees}*/}
                        {/*                    style={{width: "30%", fontSize: "x-small", float: "right", marginTop: "15px"}}>*/}
                        {/*                Clear Selections*/}
                        {/*            </Button>*/}
                        {/*            <Dialog open={open} onClose={handleClose} fullWidth={true}>*/}
                        {/*                <DialogTitle>Build A Crew</DialogTitle>*/}
                        {/*                <DialogContent>*/}
                        {/*                    <ScheduleCrew*/}
                        {/*                        crews={crews}*/}
                        {/*                        employees={employees}*/}
                        {/*                        setEmployees={setEmployees}*/}
                        {/*                        setSelectedEmployees={setSelectedEmployees}*/}
                        {/*                        selectedEmployees={selectedEmployees}*/}
                        {/*                        empsToRemove={empsToRemove}*/}
                        {/*                        setEmpsToRemove={setEmpsToRemove}*/}
                        {/*                        setOpen={setOpen}*/}
                        {/*                        showActions={true}*/}
                        {/*                        phase={selectedPhase}*/}
                        {/*                        scheduleDate={selectedDate}*/}
                        {/*                    ></ScheduleCrew>*/}
                        {/*                </DialogContent>*/}
                        {/*            </Dialog>*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*    <div className="row">*/}
                        {/*        <fieldset>*/}
                        {/*            <TextField*/}
                        {/*                label="Schedule Notes"*/}
                        {/*                variant="standard"*/}
                        {/*                type="string"*/}
                        {/*                value={notes}*/}
                        {/*                multiline={true}*/}
                        {/*                onChange={handleNotesChange}*/}
                        {/*                className="notes"*/}
                        {/*                disabled={isLoading}/>*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*    <div className="row">*/}
                        {/*        <fieldset>*/}
                        {/*            <legend>Select A Job</legend>*/}
                        {/*            <Select id="job-filter-select-id"*/}
                        {/*                    value={selectedJob}*/}
                        {/*                    onChange={(e) => handleJobChange(e)}*/}
                        {/*                    options={availableJobs}*/}
                        {/*                    isClearable*/}
                        {/*                    isSearchable*/}
                        {/*                    filterOption={createFilter(filterConfig)}*/}
                        {/*                    disabled={isLoading}*/}
                        {/*                    isDisabled={isLoading}/>*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*    <div className="row">*/}
                        {/*        <fieldset className="fs-available-lots">*/}
                        {/*            <div className="column-available-lots">*/}
                        {/*                <legend>Select Available Lot(s)</legend>*/}
                        {/*                <TextField*/}
                        {/*                    id="search"*/}
                        {/*                    label="Search Available Lots"*/}
                        {/*                    variant="standard"*/}
                        {/*                    type="search"*/}
                        {/*                    value={searchTerm}*/}
                        {/*                    onChange={handleSearchAvailableLotsChange}*/}
                        {/*                    className="available-lots-search"*/}
                        {/*                    disabled={isLoading}*/}
                        {/*                />*/}
                        {/*                <label className="switch">*/}
                        {/*                    <input type="checkbox" onChange={handleShowHideLots}/>*/}
                        {/*                    <span className="slider"></span>*/}
                        {/*                </label>*/}
                        {/*                {!hideLots && <span className={'hide-lots-label show'}>Showing All</span>}*/}
                        {/*                {hideLots && <span className={'hide-lots-label hide'}>Showing Available</span>}*/}
                        {/*                <AvailableLots*/}
                        {/*                    data={availableLotsFiltered}*/}
                        {/*                    columns={['phase', 'number', 'address']}*/}
                        {/*                    disabled={availableLots.length === 0}*/}
                        {/*                    selected={selectedLots}*/}
                        {/*                    setSelected={setSelectedLots}*/}
                        {/*                    scheduleDate={dayjs(selectedDate)}*/}
                        {/*                    selectedPhaseId={selectedPhaseId}*/}
                        {/*                    selectedTasks={selectedTasks}*/}
                        {/*                    handleLoadSchedule={handleLoadSchedule}*/}
                        {/*                    setAvailableLotsFiltered={setAvailableLotsFiltered}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div className="column-available-lot-buttons">*/}
                        {/*                <Grid item>*/}
                        {/*                    <Grid container direction="column" alignItems="left">*/}
                        {/*                        <Button*/}
                        {/*                            className="available-lots-select-some"*/}
                        {/*                            sx={{my: 0.5}}*/}
                        {/*                            size="small"*/}
                        {/*                            onClick={handleAddSelectedLotsToSchedule}*/}
                        {/*                            disabled={scheduleDate === "" || selectedLots.length === 0 || isLoading || isLocked}*/}
                        {/*                            aria-label="Add selected to the schedule"*/}
                        {/*                            startIcon={<ArrowRight style={{fontSize: '52px', color: scheduleDate === "" || selectedLots.length === 0 || isLoading || isLocked ? '' : 'blue'}}/>}>*/}
                        {/*                        </Button>*/}
                        {/*                    </Grid>*/}
                        {/*                </Grid>*/}
                        {/*            </div>*/}
                        {/*        </fieldset>*/}
                        {/*    </div>*/}
                        {/*</div>}*/}
                        <Button
                            className="WIP-concrete-report"
                            variant="contained"
                            disabled={isLoading}
                            onClick={(e) => createWIPCycleTimeReportConcrete()}
                            style={{width: "30%", fontSize: "x-small", float: "right", marginTop: "15px"}}
                        >
                            {buttonText}
                        </Button>
                    </section>
                    <section className="pane editor">
                        {locks.length > 0 && <LockList locks={locks} setLocks={setLocks}/>}
                        <div style={styles.header}>
                            <div style={styles.header.company}>
                                <span>SIERRA VERDE CONSTRUCTION, LLC</span>
                            </div>
                            <div style={styles.header.title}>
                                <span>CYCLE TIME - EVENFLOW REPORT</span>
                            </div>
                        </div>
                        <div style={styles.footer}>
                            <span>{dayjs(new Date()).format('MM-DD-YYYY')}</span>
                            {/*<span render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />*/}
                        </div>

                        {data.map((d) => (
                            <div>

                                <WIPTableConcrete data={d.lots} tasks={phaseTasks}/>
                            </div>
                        ))}

                        {/*<div>*/}
                        {/*    {data.map((d, index) => {*/}
                        {/*        if(d.lineType === 'name'){*/}
                        {/*            const color = 'rgb(252, 230, 212)';*/}
                        {/*            return (*/}
                        {/*                <div key={`${d.id}-${index}-name`} style={styles.tableRow}>*/}
                        {/*                    <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.info]}>{`${d.firstname} ${d.lastname}`}</span>*/}
                        {/*                </div>*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*        if(d.lineType === 'builder'){*/}
                        {/*            const color = 'rgb(217, 217, 217)';*/}
                        {/*            return (*/}
                        {/*                <div key={`${d.id}-${index}-builder`} style={styles.tableRow}>*/}
                        {/*                    <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.info]}>{d.builderName}</span>*/}
                        {/*                </div>*/}
                        {/*            )*/}
                        {/*        }*/}

                        {/*        const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';*/}

                        {/*        return (*/}
                        {/*            <div key={`${d.id}-${index}-data`} style={styles.tableRow}>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cProjectName]}>{d.projectName}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cJobNumber]}>{d.jobNumber}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cLotNumber]}>{d.lotNumber}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.releaseDate}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.layout}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.excavateFooting}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.spreadPerimeter}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.setPerimeter}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.soilLabor}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.deliverABC}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.backfillCompact}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.roughGradeSpreadABC}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.setFloorLinePerm}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.boxWrapLabor}</span>*/}
                        {/*                <span styles={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.cDate]}>{d.finishFloor}</span>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </section>
                </Split>
            </SplitPane>
            {/*<div>*/}
            {/*    <Dialog open={detailOpen} onClose={handleCloseDetail} fullScreen={true}>*/}
            {/*        <DialogTitle>{getSelectedPhaseNames(true)} Schedule Details</DialogTitle>*/}
            {/*        <DialogContent style={{height: '100%', width: '100%'}}>*/}
            {/*            <DetailNew*/}
            {/*                selectedTasks={selectedTasks}*/}
            {/*                selectedLots={selectedLotsFull}*/}
            {/*                suppliers={suppliers}*/}
            {/*                setOpen={setDetailOpen}*/}
            {/*                schedule={schedule}*/}
            {/*                setSchedule={setSchedule}*/}
            {/*            />*/}
            {/*        </DialogContent>*/}
            {/*        <DialogActions>*/}
            {/*            <Button onClick={handleDetailCancel} value={1}>Cancel</Button>*/}
            {/*            <Button onClick={handleDetailSave} value={0}>Save</Button>*/}
            {/*        </DialogActions>*/}
            {/*    </Dialog>*/}
            {/*</div>*/}
        </WIPPageContainer>
    );
}
export default WIPPage;
