import {useState} from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import Auth from './components/authentication/Auth.js';

import AdminPage from './components/admin/AdminPage.js'
import Login from './components/login/Login.js';
import HomeScreen from './components/home-screen/HomeScreen.js';
import Messager from './components/utilities/Messager.js';
import EmailSetup from './components/utilities/EmailSetup';
import FieldForms from './components/field-utilities/FieldForms.js';
import BuildersPage from './components/builders/BuildersPage.js';
import JobsPage from './components/jobs/JobsPage.js';

import Config from './config'
import LotsPage from './components/lots/LotsPage.js';
import EvenFlow from './components/lots/EvenFlow.js';
import ViewUsers from './components/users/ViewUsers.js';
import CreateUser from './components/users/CreateUser.js';
import EditUser from './components/users/EditUser.js';
import ChangePasswordUser from './components/users/ChangePasswordUser.js';
import ChangePasswordAdmin from './components/users/ChangePasswordAdmin.js';
import ReportsPage from './components/reporting/ReportsPage.js'
import DailySchedule from './components/utilities/DailySchedule.js';
import MaintenanceScreen from './components/site-maintenance/MaintenanceScreen.js';
import SiteMaintenance from './components/site-maintenance/SiteMaintenance.js';
import AuthProvider from './components/authentication/AuthProvider.js';
import AppContainer from './AppContainer.js';
import EmployeesPage from './components/crews/employees/EmployeesPage.js';
import CrewsPage from './components/crews/CrewsPage.js';
import EWAOld from './components/ewa/EWAOld.js';
import EWAList from './components/ewa/EWAList.js';
import WorkForSVC from './components/work-for-svc/WorkForSVC.js';
import ProspectiveEmployees from './components/users/ProspectiveEmployees.js';
import SchedulePage from './components/scheduling/SchedulePage.js';
import WIPPage from './components/wip/WIPPage.js';
import ScheduleUpdates from './components/field-utilities/schedule/Updates.js';
import PourLog from './components/scheduling/PourLog.js';
import SVCScheduleRequest from './components/field-utilities/schedule/SVCScheduleRequest.js';
import Gates from './components/field/Gates.js';
import UserPermissions from './components/users/UserPermissions.js';
import PhaseCodes from './components/utilities/PhaseCodes.js';
import { WrongVersionScreen, FMSCrashScreen } from './components/authentication/WarningScreens.js';
import TaskAdjustment from "./components/ewa/TaskAdjustment";
import EmployeeTimeOffPage from "./components/crews/EmployeeTimeOffPage";
import ConcreteMeasurements from "./components/field-utilities/ConcreteMeasurements";
import EWAForm from "./components/field-utilities/EWAForm";
import GateInformation from "./components/field-utilities/GateInformation";
import PaverMeasurements from "./components/field-utilities/PaverMeasurements";
import PlumbingCameraQAForm from "./components/field-utilities/PlumbingCameraQAForm";
import TopOutTrimRelease from "./components/field-utilities/TopOutTrimRelease";

import {AppProvider} from './context/AppProvider';
import MainHeader from "./components/utilities/MainHeader";
import ScheduleTemplate from "./components/evenflow/ScheduleTemplate";

// import axios from 'axios';
function App() {

    const [loadedAppData, setLoadedAppData] = useState(false);
    const [appData, setAppData] = useState(null);

    let lastActionTime = new Date();
    const logoutTime = 3600000; // 1 hour = 3600000

    const loadAppData = async () => {
        setLoadedAppData(true);
        const tempAppData = await Auth.hitEndpoint("GET", "APP_STATE");
        setAppData(tempAppData[0]);
    }

    if(!loadedAppData){
        loadAppData();
    }

    const timeOutUser = () => {
        const currentTime = new Date();
        if(window.location.pathname !== '/' && window.location.pathname !== '/work-for-svc'){
            // TODO Change this to valueOf() and parse the number
            if(currentTime - lastActionTime > logoutTime){
                Auth.logout();
                localStorage.removeItem('appState');
                //setIsAuthenticated(false)
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            }
            else{
                setTimeout(timeOutUser, logoutTime - (currentTime - lastActionTime));
            }
        }
    }

    if(window.location.pathname !== '/' && window.location.pathname !== '/work-for-svc'){
        setTimeout(timeOutUser, logoutTime);
    }

    const setInitialTimeout = () => {
        setTimeout(timeOutUser, logoutTime);
    }

    const resetLastAction = () => {
        // Reset the time of the last action based on mouse
        // TODO add in touch for phones
        lastActionTime = new Date();
    }

    if (appData && appData.mode === "MAINTENANCE") {
        return (
            <AppProvider>
                <AppContainer>
                    <Router>
                        <MainHeader/>
                        <Routes>
                            <Route path="/" exact element={<MaintenanceScreen/>}/>
                            <Route path='/site-maintenance' exact element={<SiteMaintenance/>}/>
                            <Route path="/work-at-svc" exact element={<WorkForSVC/>}/>
                        </Routes>
                    </Router>
                </AppContainer>
            </AppProvider>
        );
    }

    if (appData && appData.mode === "FULL") {
        return (
            <AppProvider>
                <AppContainer onClick={resetLastAction} onWheel={resetLastAction} onReset={resetLastAction}
                              onChange={resetLastAction} onInput={resetLastAction}>
                    <Router>
                        {appData && <MainHeader/>}
                        <Routes>
                            <Route path="/" exact element={<Login setInitialTimeout={setInitialTimeout}/>}/>
                            <Route path="/login" element={<Login setInitialTimeout={setInitialTimeout}/>}/>
                            <Route path='/site-maintenance' exact element={<SiteMaintenance/>}/>
                            <Route path='/wrong-version' exact element={<WrongVersionScreen/>}/>
                            <Route path='/fms-crashed' exact element={<FMSCrashScreen/>}/>
                            <Route path="/work-at-svc" exact element={<WorkForSVC/>}/>
                            <Route path="/home" exact element={<HomeScreen/>}/>

                            {/* From here down use the new permission system */}
                            <Route path="/user/permissions" element={<AuthProvider permissionName="userPermissions"><UserPermissions/></AuthProvider>}/>
                            <Route path="/builders-page" exact element={<AuthProvider permissionName="builders"><BuildersPage/></AuthProvider>}/>
                            <Route path="/users/change-password" element={<AuthProvider permissionName="changePassword"><ChangePasswordAdmin/></AuthProvider>}/>
                            <Route path="/change-password" element={<AuthProvider permissionName="changePassword"><ChangePasswordUser/></AuthProvider>}/>
                            <Route path="/users/view" element={<AuthProvider permissionName="users"><ViewUsers/></AuthProvider>}/>
                            <Route path="/users/create" element={<AuthProvider permissionName="users"><CreateUser/></AuthProvider>}/>
                            <Route path="/users/edit" element={<AuthProvider permissionName="users"><EditUser/></AuthProvider>}/>
                            <Route path="/prospective-employees" exact element={<AuthProvider permissionName="prospectiveEmployees"><ProspectiveEmployees/></AuthProvider>}/>
                            <Route path="/jobs-page" element={<AuthProvider permissionName="jobs"><JobsPage/></AuthProvider>}/>
                            <Route path='/lots-page' element={<AuthProvider permissionName="lots"><LotsPage/></AuthProvider>}/>
                            <Route path='/lots/evenflow' element={<AuthProvider permissionName="evenFlow"><EvenFlow/></AuthProvider>}/>
                            <Route path="/schedule" exact element={<AuthProvider permissionName="schedule"><SchedulePage/></AuthProvider>}/>
                            <Route path="/schedule-updates" exact element={<AuthProvider permissionName="scheduleUpdates"><ScheduleUpdates showFieldMenu={false}/></AuthProvider>}/>
                            <Route path="/wip" exact element={<AuthProvider permissionName="scheduleUpdates"><WIPPage showFieldMenu={false}/></AuthProvider>}/>
                            <Route path="/svc-schedule-request" exact element={<AuthProvider permissionName="scheduleRequest"><SVCScheduleRequest/></AuthProvider>}/>
                            <Route path="/gates" exact element={<AuthProvider permissionName="gateInformation"><Gates/></AuthProvider>}/>
                            <Route path="/employees-page" exact element={<AuthProvider permissionName="employees"><EmployeesPage/></AuthProvider>}/>
                            <Route path="/employee/time-off" exact element={<AuthProvider permissionName="employeeTimeOff"><EmployeeTimeOffPage/></AuthProvider>}/>
                            <Route path="/crews-page" exact element={<AuthProvider permissionName="crews"><CrewsPage/></AuthProvider>}/>
                            <Route path="/reports" exact element={<AuthProvider permissionName="reports"><ReportsPage/></AuthProvider>}/>
                            <Route path="/daily-schedule" exact element={<AuthProvider permissionName="dailySchedule"><DailySchedule/></AuthProvider>}/>
                            <Route path="/messager" exact element={<AuthProvider permissionName="messaging"><Messager/></AuthProvider>}/>
                            <Route path="/ewa" exact element={<AuthProvider permissionName="ewa"><EWAOld/></AuthProvider>}/>
                            <Route path="/ewas" exact element={<AuthProvider permissionName="ewa"><EWAList/></AuthProvider>}/>
                            <Route path="/task-adjustment" exact element={<AuthProvider permissionName="ewa"><TaskAdjustment/></AuthProvider>}/>

                            <Route path="/field-forms" exact element={<AuthProvider permissionName="fieldForms"><FieldForms/></AuthProvider>}/>
                            <Route path="/field-forms/concrete-measurements" exact element={<AuthProvider permissionName="fieldForms"><ConcreteMeasurements/></AuthProvider>}/>
                            <Route path="/field-forms/ewa-form" exact element={<AuthProvider permissionName="fieldForms"><EWAForm/></AuthProvider>}/>
                            <Route path="/field-forms/gate-information" exact element={<AuthProvider permissionName="fieldForms"><GateInformation/></AuthProvider>}/>
                            <Route path="/field-forms/paver-measurements" exact element={<AuthProvider permissionName="fieldForms"><PaverMeasurements/></AuthProvider>}/>
                            <Route path="/field-forms/plumbing-camera-qa" exact element={<AuthProvider permissionName="fieldForms"><PlumbingCameraQAForm/></AuthProvider>}/>
                            <Route path="/field-forms/topout-trim-release" exact element={<AuthProvider permissionName="fieldForms"><TopOutTrimRelease/></AuthProvider>}/>
                            <Route path="/field-forms/schedule-updates" exact element={<AuthProvider permissionName="scheduleUpdates"><ScheduleUpdates showFieldMenu={true}/></AuthProvider>}/>
                            <Route path="/field-forms/wip" exact element={<AuthProvider permissionName="scheduleUpdates"><WIPPage showFieldMenu={true}/></AuthProvider>}/>

                            <Route path="/email/setup" exact element={<AuthProvider permissionName="emailSetup"><EmailSetup/></AuthProvider>}/>
                            <Route path="/pour-log" exact element={<AuthProvider permissionName="pourLog"><PourLog/></AuthProvider>}/>
                            <Route path="/phase-codes" exact element={<AuthProvider permissionName="phaseCodes"><PhaseCodes/></AuthProvider>}/>
                            <Route path="/schedule-templates" exact element={<AuthProvider permissionName="phaseCodes"><ScheduleTemplate/></AuthProvider>}/>
                            <Route path="/admin-page" exact element={<AuthProvider permissionName="admin"><AdminPage/></AuthProvider>}/>
                        </Routes>
                    </Router>
                </AppContainer>
            </AppProvider>
        );
    }
    return (
        <div></div>
    );
}

export default App;
