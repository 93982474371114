import { useState, useMemo } from 'react';
// import Auth from '../authentication/Auth';
import Grid from "@mui/material/Grid";
import {MaterialReactTable} from "material-react-table";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddchartIcon from '@mui/icons-material/Addchart';
import Can from '../../authentication/Can';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import XLSX from 'xlsx';

const EmployeesTable = ({message, employees, setCreateEmployee,
                            setEditEmployee, setEmployeeToEdit, setDeleteEmployee, setEmployeeToDelete}) => {
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    });

    const canEditEmployee = Can.isAuthorized('employees', 'WRITE');

    const columns = useMemo(() => [
        {
            accessorKey: 'first_name',
            header: 'First Name',
            enableEditing: false,
            size: 250
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
            enableEditing: false,
            size: 300
        },
        {
            accessorKey: 'alias',
            header: 'Alias',
            enableEditing: false,
            size: 200
        },
        {
            accessorKey: 'company',
            header: 'Company',
            enableEditing: false,
            size: 300
        },
        {
            accessorKey: 'phone_number',
            header: 'Phone #',
            enableEditing: false,
            size: 200
        },
        {
            accessorKey: 'email',
            header: 'Email',
            enableEditing: false,
            size: 300,
        },
        {
            accessorKey: 'qb_id',
            header: 'Quickbooks ID',
            enableEditing: false,
            size: 200,
        },
        {
            accessorKey: 'crews',
            header: 'Crew #',
            enableEditing: false,
            size: 150,
        },
    ], []);

    const exportToXLSX = async () => {
        const exportData = []
        for(let i = 0; i < employees.length; i++){
            exportData.push({
                'Active': parseInt(employees[i].active) ? 'X' : '',
                'First Name': employees[i].first_name ? employees[i].first_name : '',
                'Last Name': employees[i].last_name ? employees[i].last_name : '',
                'Alias': employees[i].alias ? employees[i].alias : '',
                'Company': employees[i].company ? employees[i].company : '',
                'Phone #': employees[i].phone_number ? employees[i].phone_number : '',
                'Email': employees[i].email ? employees[i].email : '',
                'Quickbooks ID': employees[i].qb_id ? employees[i].qb_id : '',
                'SVC': parseInt(employees[i].svc) ? 'X' : '',
                'SVP': parseInt(employees[i].svp) ? 'X' : '',
                'SVF': parseInt(employees[i].svf) ? 'X' : '',
                'Concrete Sub': parseInt(employees[i].sub_concrete) ? 'X' : '',
                'Plumbing Sub': parseInt(employees[i].sub_plumbing) ? 'X' : '',
                'Framing Sub': parseInt(employees[i].sub_framing) ? 'X' : '',
            });
        }

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
        XLSX.writeFile(workbook, './Employees.xlsx');
    }

    return (
        <MaterialReactTable
            muiTableContainerProps={{ sx: { maxWidth: '1880px', maxHeight: '600px' } }}
            columns={columns}
            data={employees}
            layoutMode='grid-no-grow'
            enableColumnFilters
            enableStickyHeader
            enableColumnPinning
            onPaginationChange={(paginationState) => {
                if(!bypassPagination){
                    setPagination(paginationState);
                }
                setBypassPagination(false);
            }}
            initialState={{
                pagination: pagination,
                columnPinning: {left: ['mrt-row-actions', 'first_name', 'last_name']}
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    size: 130,
                    grow: false,
                },
            }}
            renderTopToolbarCustomActions={({table}) => {
                return (
                    <Grid>
                        <Tooltip title="Open Create Employee">
                            <IconButton onClick={() => setCreateEmployee(true)}>
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Export To XLSX">
                            <IconButton onClick={() => exportToXLSX()}>
                                <AddchartIcon />
                            </IconButton>
                        </Tooltip>
                        <Grid>
                            <span style={{fontWeight: 'bold'}}>Status: </span>
                            <span style={{fontWeight: 'bold', color: message.color}}>{message.message}</span>
                        </Grid>
                    </Grid>
                );
            }}
            muiTableBodyCellProps={({ row }) => {
                let bgColor = row.original.active || row.original.active === '1' ? 'white' : 'rgb(255, 150, 150)';
                return {
                    sx: {
                        backgroundColor: bgColor,
                        padding: '0'
                    }
                }
            }}
            positionToolbarAlertBanner='hide'
            state={{pagination}}
            enableRowActions={true}
            renderRowActions={({ row, table }) => {
                return (
                    <Box sx={{ display: 'flex', gap: '1rem',}}>
                        {canEditEmployee &&
                        <Tooltip title="Edit">
                            <IconButton onClick={async () => {
                                setEditEmployee(true);
                                const tempEmployee = {
                                    ...row.original,
                                    active: row.original.active === 1,
                                    first_name: row.original.first_name === null ? '' : row.original.first_name,
                                    last_name: row.original.last_name === null ? '' : row.original.last_name,
                                    alias: row.original.alias === null ? '' : row.original.alias,
                                    phone_number: row.original.phone_number === null ? '' : row.original.phone_number,
                                    email: row.original.email === null ? '' : row.original.email,
                                    qb_id: row.original.qb_id === null ? '' : row.original.qb_id,
                                    company: row.original.company === null ? '' : row.original.company,
                                    svc: row.original.svc === 1,
                                    svp: row.original.svp === 1,
                                    svf: row.original.svf === 1,
                                    sub: row.original.sub === 1,
                                    sub_concrete: row.original.sub_concrete === 1,
                                    sub_plumbing: row.original.sub_plumbing === 1,
                                    sub_framing: row.original.sub_framing === 1,
                                }
                                setEmployeeToEdit(tempEmployee);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>}
                        {canEditEmployee &&
                        <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => {
                                setEmployeeToDelete({...row.original});
                                setDeleteEmployee(true);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>}
                    </Box>
                )
            }}
        />
    );
}

export default EmployeesTable;