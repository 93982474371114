import { useState } from 'react';
import { createAlert } from '../../utilities/MainHeaderAlert.js';
import Auth from '../../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const DeleteEmployee = ({displayMessageWithTimer, updateEmployeesArray, 
                    deleteEmployee, setDeleteEmployee, employeeToDelete, setEmployeeToDelete}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const closeWithDelete = async () => {
        const crewMembers = await Auth.hitEndpointNew("GET", "crew-members", `jsonString=${JSON.stringify({employee_id: employeeToDelete.id})}`);
        let removeFromCrew = false;
        if(crewMembers.length > 0){
            removeFromCrew = true;
            createAlert(
                `Remove ${employeeToDelete.first_name} ${employeeToDelete.last_name} from crew ${employeeToDelete.crews}`,
                '/crews-page',
                [39, 49]
            )
        }
        const dbObj = {
            id: employeeToDelete.id,
            active: false,
            remove_from_crew: removeFromCrew
        }
        await Auth.hitEndpointNew("PATCH", "employee", "", dbObj);

        await updateEmployeesArray(employeeToDelete);
        displayMessageWithTimer('Employee Deleted', 'green', 5000);
        setEmployeeToDelete(null);
        setDeleteEmployee(false);
    }

    const closeNoDelete = () => {
        displayMessageWithTimer('No Employees Deleted', 'red', 5000);
        setEmployeeToDelete(null);
        setDeleteEmployee(false);
    }

    return (
        <Dialog open={deleteEmployee}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Employee To Delete</DialogTitle>
            <DialogContent sx={{width: "600px"}}>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Name:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.first_name} {employeeToDelete.last_name}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Alias/Nickname:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.alias}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Phone Number:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.phone_number}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Email:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.email}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Quickbooks ID:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.qb_id}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Crew:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {employeeToDelete.crews}
                    </Box>
                </Box>
                {confirmDelete ?
                <Dialog open={confirmDelete}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Employee</DialogTitle>
                    <DialogContent sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>
                            Please Confirm Deleting Employee
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                        <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                        <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>Delete</Button>
                <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteEmployee;