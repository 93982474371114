import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';

const EmployeesFilter = ({filters, setFilters, setDidLoadData}) => {
    return (
        <Grid 
            sx={{
                display: 'grid',
                justifyContent: 'center',
                width: 600, 
                padding: '10px 0',
                marginBottom: '10px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '5px',
                zIndex: '49'
            }}
        >
            <FormControlLabel 
                control={<Switch 
                    checked={filters.includeDeleted} 
                    onChange={(e) => {
                        setFilters({...filters, includeDeleted: e.target.checked})
                        setDidLoadData(false);
                    }}/>} 
                label="Included Deleted Employees" 
            />
        </Grid>
    );
}

export default EmployeesFilter;