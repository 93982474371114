import {useMemo, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {MaterialReactTable} from "material-react-table";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import dayjs from "dayjs";
import {createTheme, styled} from "@mui/material/styles";
import {darken, IconButton, lighten, Tooltip} from "@mui/material";
import ReassignCrew from "../scheduling/crew/ReassignCrew";
import Schedules from "../../classes/Schedules";
import {
    Delete, EditCalendar, EditLocation, EditNote,
    Info, People, RadioButtonChecked,
    RadioButtonUnchecked
} from "@mui/icons-material";
import DetailEdit from "../scheduling/detail/DetailEdit";
import RoutingData from "../scheduling/routing/RoutingData";
import {logError} from "../../utilities/error";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const NotesDialogBox = (props) => {
    const [notes, setNotes] = useState({
        id: props.id,
        notes: props.notes
    })

    return (
        <Dialog open={props.open}>
            <DialogTitle>Notes:</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{
                        width: '100%',
                        margin: '5px auto',
                    }}
                    autoFocus
                    placeholder="Enter Notes"
                    label="Add Notes"
                    value={notes.notes}
                    onChange={(e) => setNotes({...notes, notes: e.target.value})}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleNotesSave(notes.notes)}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

const WIPTableConcrete = ({date, selectedSuper, data, setSchedule, tasks,
                           selectedEmployees, crews, setCrews, employees, setEmployees, setSelectedEmployees,
                           selectedEmployeesReassign, setSelectedEmployeesReassign, empsToRemove, setEmpsToRemove,
                           selectedJob, phase, phases,
                           isLoading, userId, setAvailableLots,
                           getSelectedPhaseNames, suppliers, selectedTab}) => {

    const [isLocked, setIsLocked] = useState(false)
    const [newSelectedDate, setNewSelectedDate] = useState(date)
    const [dateOpen, setDateOpen] = useState(false)
    const [detailOpen, setDetailOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [employeesOpen, setEmployeesOpen] = useState(false)
    const [selectedTableRows, setSelectedTableRows] = useState([])
    const [tableObj, setTable] = useState({})
    const [selectedTasks, setSelectedTasks] = useState([])
    const [editDetails, setEditDetails] = useState([])
    const [editNotes, setEditNotes] = useState(null)
    const [routingOpen, setRoutingOpen] = useState(false)
    const [routeCrews, setRoutCrews] = useState([])
    const [fullSchedule, setFullSchedule] = useState([])

    const handleCloseDate = () => {
        setDateOpen(false);
    };
    const handleCloseRouting = () => {
        setRoutingOpen(false)
    }

    const Item = styled(Paper)(({ thm }) => ({
        width: '100%',
        fontSize: '8pt',
        backgroundColor: '#fff',
        padding: '5px',
        textAlign: 'left',
        color: '#1A2027',
    }));

    const gridColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => {
                        return (
                            <Grid container columns={{xs: 4, md: 12}}>
                                <Item>{row.builder !== null ? `${row.builder}` : ''}</Item>
                                <Item>{row.job_number} - {row.project_name}</Item>
                                <Item>Lot: {row.lot_number} {row.lot_address}</Item>
                            </Grid>
                        )
                    },
                    id: 'job',
                    header: 'Project',
                    enableEditing: false,
                    filterFn: (row, _columnIds, filterValue) => {
                        //Had to do some custom filtering since we are pulling all the data together in a single column.
                        // Might be a better way to do this but for now...
                        let builder = (`${row.original.builder}`).toLowerCase()
                        let job = (`${row.original.job_number} - ${row.original.roject_name}`).toLowerCase()
                        let lot = (`Lot: ${row.original.lot_number} ${row.original.lot_address}`).toLowerCase()
                        let filterVal = filterValue.toLowerCase()
                        return builder.includes(filterVal) || job.includes(filterVal) || lot.includes(filterVal)
                    },
                    size: 300
                },
                {
                    accessorKey: 'floor_released',
                    id: 'release',
                    header: 'Release',
                    size: 20,
                    enableEditing: false,
                },
            ]
            for (let t = 0; t < tasks.length; t++) {
                let task = tasks[t]
                let columnId = task.name.replace(' & ', '_and_',).replace(' ', '_').replace(' ', '_').toLowerCase()
                columns.push(
                    {
                        accessorFn: (row) => {
                            return (
                                <Grid container columns={{xs: 4, md: 12}}>
                                    <Item>{row[columnId] !== null && row[columnId] !== '' ? `${row[columnId].scheduled}` : ''}</Item>
                                    <Item>{row[columnId] !== null && row[columnId] !== '' ? `${row[columnId].completed}` : ''}</Item>
                                    <Item>{row[columnId] !== null && row[columnId] !== '' ? `${row[columnId].crew}` : ''}</Item>
                                </Grid>
                            )
                        },
                        id: columnId,
                        header: task.name,
                        enableEditing: false,
                    }
                )
            }
            return columns
        }
    );

    const detailColumns = useMemo(
        () => [
            {
                accessorFn: (row) => {
                    let emp = row.employee
                    let name = emp.alias !== undefined && emp.alias !== null ? `${emp.alias} ${emp.first_name} ${emp.last_name}` : `${emp.first_name} ${emp.last_name}`
                    return (name)
                },
                header: '',
                id: 'scheduled-employee'
            },
            {
                accessorFn: (row) => {
                    let emp = row.employee
                    let crew = emp.membership.crew.number
                    if(crew !== row.parent_crew)
                        return (`From Crew ${crew}`)
                },
                header: '',
                id: 'scheduled-employee-from-crew'
            },
        ],
        [],
    );

    const handleDateChanged = async newDate => {
        setIsLocked(isLocked)
        setNewSelectedDate(new Date(newDate))
    }

    const handleDateCloseNoSave = async () => {
        setDateOpen(false)
        setNewSelectedDate('')
        setSelectedRows([])
    }

    const handleDateCloseSave = async () => {
      //  setSchedule(await Schedules.handleDateChange(selectedRows, schedule, date, newSelectedDate))
        tableObj.resetRowSelection()
        setSelectedRows([])
        setDateOpen(false)
    }

    const handleCloseEmployees = async () => {
        setEmployeesOpen(false)
    }

    const handleCloseDetail = () => {
        setDetailOpen(false)
    }

    const handleDetailCancel = () => {
        setDetailOpen(false)
    }

    const handleDetailSave = async () => {
      //  setSchedule(await Schedules.handleDetailSave(schedule, editDetails, selectedRows))
        setDetailOpen(false)
    }

    const handleNotesSave = async (notes) => {
       // const index = schedule.findIndex(s => parseInt(s.id) === parseInt(editNotes.id));
      //  const tempSchedule = [...schedule];
    //    tempSchedule[index].notes = notes;
    //    setSchedule([...tempSchedule]);

        await Schedules.updateById({
            id: editNotes.id,
            notes: notes
        })
        setEditNotes(null);
    }

    const handlePrepAssignmentChange = async (tableRows) => {
        setSelectedEmployeesReassign(await Schedules.handlePrepAssignmentChange(phases, tableRows, setEmployees, setCrews, setSelectedRows, setSelectedTableRows, setEmpsToRemove, date))
    }

    const handleAssignmentCloseNoSave = () => {
        setEmployeesOpen(false);
    };

    const handleCloseAssignmentSave = async () => {
     //   setSchedule(await Schedules.handleAssignmentSave(selectedRows, schedule, employees, selectedEmployeesReassign, empsToRemove, userId))
        setEmployeesOpen(false)
    }

    //Needed for showing crew schedule together per task
    let colorGroupCounter = 1
    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    // const baseHeaderColor = theme.palette.mode === 'dark' ? 'rgba(200, 200, 200, 1)' : 'rgba(200, 200, 200, 1)'
    const baseCompleteColor = theme.palette.mode === 'dark' ? 'rgba(143, 248, 144, 1)' : 'rgba(143, 248, 144, 1)'

    return (
        <div style={{overflow: "auto", width: "100%"}}>
            <MaterialReactTable
                id="wip-concrete-table"
                columns={gridColumns}
                data={data}
                enableColumnFilterModes
                rowNumberDisplayMode={'static'}
                enableRowSelection={false}
                enableSelectAll={false}
                enableBatchRowSelection={true}
                enableStickyHeader
                rowCount={data.length}
                enableSorting={false}
                enableEditing={true}
                editDisplayMode={'table'}
                // displayColumnDefOptions={{
                //     'mrt-row-expand': {
                //         header: '', //change header text
                //     }}}
                // muiTableProps={{
                //     sx: () => ({
                //         borderCollapse: 'collapse'
                //     })
                // }}
                // muiSelectCheckboxProps = {({table,  row }) => ({
                //     disabled: row.original.locked,
                //     onClick: e => {
                //         if (e.shiftKey) {
                //             //Will come back to this later
                //             let rows = table.getSelectedRowModel().rows
                //             let lastSelectedIndex = rows[rows.length - 1].index
                //             let index = row.index
                //             let start = index > lastSelectedIndex ? lastSelectedIndex : index
                //             let end = index > lastSelectedIndex ? index : lastSelectedIndex
                //             for (let i = start; i < end; i++) {
                //                 rows.push(table.getRowModel().rows[i])
                //             }
                //         }
                //     }
                // })}
                muiTableBodyCellProps={({cell, row, table}) => {
                    // let columnId = cell.column.id
                    // let rows = table.getRowModel().rows
                    // let index = rows.indexOf(row)
                    // let prev = rows[index - 1]
                    // let next = rows[index + 1]
                    // let prevTask = prev !== undefined ? prev.original.task.id : null
                    // let curTask = row.original.task.id
                    // let prevCrew = prev !== undefined && prev.original.crew !== undefined && prev.original.crew !== null ? prev.original.crew.crew_id : -1
                    // let curCrew = row.original.crew !== undefined && row.original.crew !== null ? row.original.crew.crew_id : -1
                    // let nextCrew = next !== undefined && next.original.crew !== undefined && next.original.crew !== null ? next.original.crew.crew_id : -1
                    //
                    // return ({
                    //     sx: {
                    //         color: () => {
                    //             let color = ''
                    //             if(columnId === 'task' || columnId === 'crew') {
                    //                 if (columnId === 'task' && curTask === prevTask) {
                    //                     color = 'rgba(0, 0, 0, 0)'
                    //                 }
                    //                 if (columnId === 'crew') {
                    //                     if (curCrew === prevCrew && curTask === prevTask) {
                    //                         color = 'rgba(0, 0, 0, 0)'
                    //                     } else if (row.original.approved === 0) {
                    //                         color = '#000'
                    //                     } else if (row.original.approved === 1) {
                    //                         color = '#fff'
                    //                     }
                    //                 }
                    //             }
                    //             return color
                    //         },
                    //         borderBottom: () => {
                    //             let border = ''
                    //             if (nextCrew !== curCrew) {
                    //                 border = 'solid 1pt black'
                    //             }
                    //             return border
                    //         },
                    //         borderRight: () => {
                    //             if(columnId === 'notes'){
                    //                 return 'solid 2pt black'
                    //             }
                    //         },
                    //         borderLeft: () => {
                    //             if(columnId === 'task'){
                    //                 return 'solid 1pt black'
                    //             }
                    //         },
                    //         fontWeight: () => {
                    //             let weight = ''
                    //             if(columnId === 'task' || columnId === 'crew') {
                    //                 weight = 'bold'
                    //             }
                    //             return weight
                    //         }
                    //
                    //     }
                    // })
                }}
                muiTableBodyRowProps={({row, table}) => {
                    // let rows = table.getRowModel().rows
                    // let index = rows.indexOf(row)
                    // let prev = rows[index - 1]
                    // let prevTask = prev !== undefined ? prev.original.task.id : null
                    // let curTask = row.original.task.id
                    // let prevCrew = prev !== undefined && prev.original.crew !== undefined && prev.original.crew !== null ? prev.original.crew.crew_id : -1
                    // let curCrew = row.original.crew !== undefined && row.original.crew !== null ? row.original.crew.crew_id : -1
                    // if(curCrew !== prevCrew){
                    //     colorGroupCounter +=1
                    // }
                    // return({
                    //     sx: {
                    //         backgroundColor: () => {
                    //             let background = ''
                    //             if (colorGroupCounter % 2) {
                    //                 background = row.original.complete ? darken(baseCompleteColor, 0.1) : darken(baseBackgroundColor, 0.1)
                    //             } else {
                    //                 background = row.original.complete ? lighten(baseCompleteColor, 0.3) : lighten(baseBackgroundColor, 0.3)
                    //             }
                    //             return background
                    //         },
                    //         borderTop:  () => {
                    //             let border = ''
                    //             if (curTask !== prevTask) {
                    //                 border = 'solid 1pt'
                    //             }
                    //             return border
                    //         },
                    //     }
                    // })
                }}
                // enableExpandAll={false}
                // muiDetailPanelProps={({row}) => {
                //     return({
                //         sx: (theme) => ({
                //             backgroundColor:
                //                 theme.palette.mode === 'light'
                //                     ? 'rgba(255,210,244,0.1)'
                //                     : 'rgba(0,0,0,0.1)',
                //         })
                //     })}}
                // muiExpandButtonProps ={({ row, table }) => ({
                //     //  children: row.getIsExpanded() ? <Minimize/> : <Add/>,
                //     onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
                //     sx: {
                //         transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                //         transition: 'transform 0.2s',
                //     }
                // })}
                // renderDetailPanel={({row}) =>
                //     row.original.crew !== null && row.original.crew.employees.length > 1 ?
                //         (
                //             <MaterialReactTable
                //                 id="scheduled-employees-table"
                //                 columns={detailColumns}
                //                 data={Schedules.getEmployees(row.original)}
                //                 enableSelectAll={false}
                //                 enableExpanding={false}
                //                 enableExpandAll={false}
                //                 enableTopToolbar={false}
                //                 enableBottomToolbar={false}
                //                 enableSorting={false}
                //                 enableFilters={false}
                //                 enableHiding={false}
                //                 enablePagination={false}
                //                 enableFullScreenToggle={false}
                //                 enableDensityToggle={false}
                //                 enableColumnActions={false}
                //                 enableTableHead={false}
                //                 enableRowActions
                //                 displayColumnDefOptions={{
                //                     'mrt-row-actions': {
                //                         header: '', //change header text
                //                         size: 50, //make actions column wider
                //                     }
                //                 }}
                //                 muiTableBodyRowProps={({row, table}) => {
                //                     return ({
                //                         sx: {
                //                             backgroundColor: () => {
                //                                 let background = ''
                //                                 if (colorGroupCounter % 2) {
                //                                     background = row.original.complete ? darken(baseCompleteColor, 0.1) : darken(baseBackgroundColor, 0.1)
                //                                 } else {
                //                                     background = row.original.complete ? lighten(baseCompleteColor, 0.3) : lighten(baseBackgroundColor, 0.3)
                //                                 }
                //                                 return background
                //                             },
                //                         }
                //                     })
                //                 }}
                //                 initialState={{
                //                     pagination: {pageSize: 100, pageIndex: 0},
                //                     density: 'compact',
                //                 }}
                //             />) : null
                // }
                initialState={{
                    showColumnFilters: false,
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {
                     //   left: ['mrt-row-select', 'mrt-row-expand']
                    },
                    density: 'compact',
                }}
                positionToolbarAlertBanner="top"
                // renderTopToolbarCustomActions={({table}) => {
                //     const handleRemove = async () => {
                //         setTable(table)
                //         setSchedule(await Schedules.handleRemove(table, schedule, phase, tasks, selectedJob, setAvailableLots))
                //     }
                //     const handleAssign = async () => {
                //         let tableRows = table.getSelectedRowModel().rows
                //         await handlePrepAssignmentChange(tableRows)
                //         setTable(table)
                //         setEmployeesOpen(true)
                //     }
                //     const handleChangeDate = () => {
                //         let rows = table.getSelectedRowModel().rows
                //         setTable(table)
                //         setSelectedRows(rows)
                //         setDateOpen(true)
                //     }
                //     const handleEditDetails = async () => {
                //         let rows = table.getSelectedRowModel().rows
                //         setTable(table)
                //         setSelectedRows(rows)
                //
                //         let lotDetails = await Schedules.handleEditDetails(rows, schedule, setSelectedTasks, setEditDetails)
                //         if(lotDetails.length > 0) {
                //             setDetailOpen(true)
                //         }
                //     }
                //     const handleMoveToSchedule = async () => {
                //         let rows = table.getSelectedRowModel().rows
                //         setSchedule(await Schedules.handleMoveToSchedule(date, schedule, rows, userId))
                //         table.resetRowSelection()
                //     }
                //     const handleMoveToRequest = async () => {
                //         let rows = table.getSelectedRowModel().rows
                //         setSchedule(await Schedules.handleMoveToRequest(date, schedule, rows, userId))
                //         table.resetRowSelection()
                //     }
                //     const handleComplete = async (isComplete) => {
                //         let rows = table.getSelectedRowModel().rows
                //         await Schedules.handleComplete(isComplete, schedule, rows, userId)
                //         table.resetRowSelection()
                //     }
                //     const handleUpdateRouting = async () => {
                //         let fs = selectedTab === 'work' ? schedule.filter(x => x.scheduled && !x.is_ewa && (x.deleted_at === undefined || x.deleted_at === null)) :
                //             selectedTab === 'request' ? schedule.filter(x => x.requested && (selectedSuper !== null ? x.requested_by === selectedSuper.id : true) && (x.deleted_at === undefined || x.deleted_at === null)) :
                //                 selectedTab === 'ewa' ? schedule.filter(x => x.is_ewa && (x.deleted_at === undefined || x.deleted_at === null)) :
                //                     []
                //         //await Schedules.loadSchedule(date, {}, '')
                //         setFullSchedule(fs)
                //         setRoutCrews(await Schedules.getDistinctCrews(fs, ''))
                //         setRoutingOpen(true)
                //     }
                //     return (
                //         <div>
                //             {(selectedTab === 'work' || selectedTab === 'ewa') &&
                //                 <div style={{display: 'flex', gap: '0.5rem'}}>
                //                     <Tooltip title="Remove">
                //                         <span>
                //                             <IconButton
                //                                 color="error"
                //                                 disabled={(table.getSelectedRowModel().rows.length === 0) || isLoading || isLocked}
                //                                 onClick={handleRemove}
                //                                 variant="contained"
                //                             >
                //                                 <Delete />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Assign">
                //                         <span>
                //                             <IconButton
                //                                 color="secondary"
                //                                 onClick={handleAssign}
                //                                 disabled={(table.getSelectedRowModel().rows.length === 0) || isLoading || isLocked}
                //                                 variant="contained"
                //                             >
                //                                 <People />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Change Date">
                //                         <span>
                //                             <IconButton
                //                                 color="secondary"
                //                                 onClick={handleChangeDate}
                //                                 disabled={(table.getSelectedRowModel().rows.length === 0) || isLoading || isLocked}
                //                                 variant="contained"
                //                             >
                //                                 <EditCalendar />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Edit Schedule Details">
                //                         <span>
                //                             <IconButton
                //                                 color="secondary"
                //                                 onClick={handleEditDetails}
                //                                 disabled={(table.getSelectedRowModel().rows.filter(x => x.original.detail !== undefined && x.original.detail.length > 0).length === 0) || isLoading || isLocked}
                //                                 variant="contained"
                //                             >
                //                                 <EditNote />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Mark as Complete">
                //                         <span>
                //                             <IconButton
                //                                 color="success"
                //                                 onClick={ async () => {
                //                                     await handleComplete(true)
                //                                 }}
                //                                 disabled={(table.getSelectedRowModel().rows.length === 0 || isLoading || isLocked)}
                //                                 variant="contained"
                //                             >
                //                                 <RadioButtonChecked />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Mark As Open">
                //                         <span>
                //                             <IconButton
                //                                 color="primary"
                //                                 onClick={ async () => {
                //                                     await handleComplete(false)
                //                                 }}
                //                                 disabled={(table.getSelectedRowModel().rows.length === 0 || isLoading || isLocked)}
                //                                 variant="contained"
                //                             >
                //                                 <RadioButtonUnchecked />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Update Routing">
                //                         <span>
                //                             <IconButton
                //                                 color="success"
                //                                 onClick={ async () => {
                //                                     await handleUpdateRouting()
                //                                 }}
                //                                 disabled={(table.getRowModel().rows.length === 0 || isLoading || isLocked)}
                //                                 variant="contained"
                //                             >
                //                                 <EditLocation />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                     <Tooltip title="Move To Request">
                //                        <span>
                //                            <IconButton
                //                                color="error"
                //                                onClick={ async () => {
                //                                    await handleMoveToRequest()
                //                                }}
                //                                disabled={(table.getSelectedRowModel().rows.length === 0 || isLoading || isLocked)}
                //                                variant="contained"
                //                            >
                //                                 <EditLocation />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                 </div>
                //             }
                //             {selectedTab === 'request' &&
                //                 <div style={{display: 'flex', gap: '0.5rem'}}>
                //                     <Tooltip title="Move to Schedule">
                //                         <span>
                //                             <IconButton
                //                                 color="success"
                //                                 onClick={ async () => {
                //                                     await handleMoveToSchedule()
                //                                 }}
                //                                 disabled={(table.getRowModel().rows.length === 0 || isLoading || isLocked)}
                //                                 variant="contained"
                //                             >
                //                                 <EditLocation />
                //                             </IconButton>
                //                         </span>
                //                     </Tooltip>
                //                 </div>}
                //         </div>
                //     );
                // }}
            />
            {/*<Dialog open={employeesOpen} onClose={handleCloseEmployees} fullScreen={true}>*/}
            {/*    <DialogTitle>Build A Crew</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <ReassignCrew*/}
            {/*            selectedRows={selectedTableRows}*/}
            {/*            crews={crews}*/}
            {/*            employees={employees}*/}
            {/*            setEmployees={setEmployees}*/}
            {/*            setSelectedEmployees={setSelectedEmployees}*/}
            {/*            selectedEmployees={selectedEmployees}*/}
            {/*            selectedEmployeesReassign={selectedEmployeesReassign}*/}
            {/*            setSelectedEmployeesReassign={setSelectedEmployeesReassign}*/}
            {/*            empsToRemove={empsToRemove}*/}
            {/*            setEmpsToRemove={setEmpsToRemove}*/}
            {/*            setOpen={setEmployeesOpen}*/}
            {/*            schedule={schedule}*/}
            {/*            setSchedule={setSchedule}*/}
            {/*            table={tableObj}*/}
            {/*            userId={userId}*/}
            {/*            showActions={false}*/}
            {/*            isUpdates={false}*/}
            {/*        ></ReassignCrew>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleAssignmentCloseNoSave}>Cancel</Button>*/}
            {/*        <Button onClick={handleCloseAssignmentSave}>Save</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            {/*<Dialog open={dateOpen} onClose={handleCloseDate} fullWidth={true}>*/}
            {/*    <DialogTitle>Select a New Date</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <div>*/}
            {/*            <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
            {/*                <DatePicker*/}
            {/*                    onChange={(newValue) => {*/}
            {/*                        handleDateChanged(newValue);*/}
            {/*                    }}*/}
            {/*                    slotProps={{*/}
            {/*                        textField: {*/}
            {/*                            helperText: '',*/}
            {/*                            sx: {minWidth: '120px'},*/}
            {/*                            variant: 'standard',*/}
            {/*                        },*/}
            {/*                    }}*/}
            {/*                    selected={newSelectedDate}*/}
            {/*                />*/}
            {/*            </LocalizationProvider>*/}
            {/*        </div>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleDateCloseNoSave}>Cancel</Button>*/}
            {/*        <Button onClick={handleDateCloseSave}>Update Jobs</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            {/*<Dialog open={detailOpen} onClose={handleCloseDetail} fullScreen={true}>*/}
            {/*    <DialogTitle>{getSelectedPhaseNames(true)} Schedule Details</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DetailEdit*/}
            {/*            schedule={schedule}*/}
            {/*            setSchedule={setSchedule}*/}
            {/*            editDetails={editDetails}*/}
            {/*            setEditDetails={setEditDetails}*/}
            {/*            selectedTasks={selectedTasks}*/}
            {/*            suppliers={suppliers}*/}
            {/*            setOpen={setDetailOpen}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleDetailCancel} value={1}>Cancel</Button>*/}
            {/*        <Button onClick={handleDetailSave} value={0}>Save</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            {/*{editNotes !== null &&*/}
            {/*    <NotesDialogBox*/}
            {/*        open={true}*/}
            {/*        id={editNotes.id}*/}
            {/*        notes={editNotes.notes}*/}
            {/*        handleNotesSave={handleNotesSave}*/}
            {/*    />}*/}
            {/*<Dialog open={routingOpen} onClose={handleCloseRouting} fullScreen={true}>*/}
            {/*    <DialogTitle>Schedule Routing</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <RoutingData*/}
            {/*            phases={phases}*/}
            {/*            crews={routeCrews}*/}
            {/*            setCrews={setRoutCrews}*/}
            {/*            schedule={fullSchedule}*/}
            {/*            setSchedule={setSchedule}*/}
            {/*            setOpen={setRoutingOpen}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </div>
    )
}

export default WIPTableConcrete;
