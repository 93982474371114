import { useState } from 'react';
import Employees from '../../../classes/Employees.js';
import Auth from '../../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import dayjs from 'dayjs';

const CreateEmployee = ({displayMessageWithTimer, updateEmployeesArray,
                            createEmployee, setCreateEmployee}) => {
    const [newEmployee, setNewEmployee] = useState({
        active: true,
        first_name: '',
        last_name: '',
        alias: '',
        phone_number: '',
        email: '',
        qb_id: '',
        svc: false,
        svp: false,
        svf: false,
        sub: false,
        company: '',
        sub_concrete: false,
        sub_plumbing: false,
        sub_framing: false,
    });

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async () => {
        console.log('here')
        const errorResult = await Employees.checkEmployeeForErrors(newEmployee);
        if(errorResult !== 'SUCCESS'){
            if(errorResult === 'ERROR_DUPLICATE_NAME'){
                setError({
                    type: 'DUPLICATE NAME',
                    message: 'Name already exists. Please enter a unique name.'
                })
                return;
            }
            if(errorResult === 'ERROR_DUPLICATE_QUICK_BOOKS_ID'){
                setError({
                    type: 'DUPLICATE QUICKBOOKS ID',
                    message: 'Quickbooks ID already exists. Please enter a unique Quickbooks ID.'
                })
                return;
            }
            setError({
                type: errorResult,
                message: ''
            })
            return;
        }

        const dbObj = {
            active: newEmployee.active,
            first_name: newEmployee.first_name,
            last_name: newEmployee.last_name,
            alias: newEmployee.alias,
            phone_number: newEmployee.phone_number,
            email: newEmployee.email,
            qb_id: newEmployee.qb_id,
            svc: newEmployee.svc,
            svp: newEmployee.svp,
            svf: newEmployee.svf,
            sub: newEmployee.sub,
            company: newEmployee.company,
            sub_concrete: newEmployee.sub_concrete,
            sub_plumbing: newEmployee.sub_plumbing,
            sub_framing: newEmployee.sub_framing,
            modified_by: await Auth.getUserId(),
            modified_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        };

        await Auth.hitEndpointNew("POST", "employee", "", dbObj);

        await updateEmployeesArray(dbObj);
        displayMessageWithTimer('1 Employee Created', 'green', 5000);
        setCreateEmployee(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Employee Created', 'green', 5000);
        setCreateEmployee(false);
    }

    return (
        <Dialog open={createEmployee} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Create Employee</DialogTitle>
            <DialogContent sx={{width: '800px', paddingBottom: '30px'}}>
                <FormControlLabel 
                    sx={{
                        margin: '10px 0'
                    }}
                    control={<Checkbox 
                        defaultChecked={newEmployee.active} 
                        onChange={(e) => setNewEmployee({...newEmployee, active: e.target.checked})}/>} 
                    label="Active" 
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_FIRST_NAME" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="First Name"
                    placeholder="Enter First Name"
                    value={newEmployee.first_name}
                    onChange={(e) => setNewEmployee({...newEmployee, first_name: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_LAST_NAME" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={newEmployee.last_name}
                    onChange={(e) => setNewEmployee({...newEmployee, last_name: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Alias/Nickname"
                    placeholder="Enter Alias/Nickname"
                    value={newEmployee.alias}
                    onChange={(e) => setNewEmployee({...newEmployee, alias: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    value={newEmployee.phone_number}
                    onChange={(e) => setNewEmployee({...newEmployee, phone_number: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Email"
                    placeholder="Enter Email"
                    value={newEmployee.email}
                    onChange={(e) => setNewEmployee({...newEmployee, email: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "ERROR_NO_QUICKBOOKS_ID" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="QuickBooks ID"
                    placeholder="Enter QuickBooks ID"
                    value={newEmployee.qb_id}
                    onChange={(e) => setNewEmployee({...newEmployee, qb_id: e.target.value})}
                />
                <FormControlLabel 
                    sx={{
                        margin: '10px 0 0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        defaultChecked={newEmployee.svc} 
                        onChange={(e) => setNewEmployee({...newEmployee, svc: e.target.checked})}/>} 
                    label="SVC" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        defaultChecked={newEmployee.svp} 
                        onChange={(e) => setNewEmployee({...newEmployee, svp: e.target.checked})}/>} 
                    label="SVP" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        defaultChecked={newEmployee.svf} 
                        onChange={(e) => setNewEmployee({...newEmployee, svf: e.target.checked})}/>} 
                    label="SVF" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0',
                        width: '100%',
                        backgroundColor: error.type === "ERROR_NO_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        defaultChecked={newEmployee.sub} 
                        onChange={(e) => setNewEmployee({...newEmployee, sub: e.target.checked})}/>} 
                    label="Sub Contractor" 
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0',
                        backgroundColor: error.type === "ERROR_NO_SUB_COMPANY" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Sub Contractor Name"
                    placeholder="Enter Sub Contractor Name"
                    value={newEmployee.company}
                    onChange={(e) => setNewEmployee({...newEmployee, company: e.target.value})}
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!newEmployee.sub && newEmployee.company === ''}
                        defaultChecked={newEmployee.sub_concrete} 
                        onChange={(e) => setNewEmployee({...newEmployee, sub_concrete: e.target.checked})}/>} 
                    label="Concrete Sub" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!newEmployee.sub && newEmployee.company === ''}
                        defaultChecked={newEmployee.sub_plumbing} 
                        onChange={(e) => setNewEmployee({...newEmployee, sub_plumbing: e.target.checked})}/>} 
                    label="Plumbing Sub" 
                />
                <FormControlLabel 
                    sx={{
                        margin: '0 0 0 30px',
                        width: '60%',
                        backgroundColor: error.type === "ERROR_SUB_WORK_NOT_DEFINED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    control={<Checkbox 
                        disabled={!newEmployee.sub && newEmployee.company === ''}
                        defaultChecked={newEmployee.sub_framing} 
                        onChange={(e) => setNewEmployee({...newEmployee, sub_framing: e.target.checked})}/>} 
                    label="Framing Sub" 
                />
                {(error.type === 'DUPLICATE NAME' || error.type === 'ERROR_DUPLICATE_QUICK_BOOKS_ID' || error.type !== '') &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateEmployee;