import styled from 'styled-components';

const TopOutTrimReleaseContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 0;

    .topout-trim-release-box{
        display: grid;
        background: rgb(210, 200, 190);
        margin-bottom: 10px;

        .topout-trim-release-sub-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-top: 2px solid white;
            border-bottom: 2px solid white;
        }

        .topout-trim-release-date-box,
        .topout-trim-release-status-box,
        .topout-trim-lot-notes-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .topout-trim-release-date-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 15px 5px 5px 0;
            }

            .topout-trim-release-date-value{
                grid-column: 6/11;
                padding: 15px 0 5px 5px;
            }

            .topout-trim-release-status-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .topout-trim-release-status-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;
            }

            .topout-trim-lot-notes-label{
                grid-column: 1/6;
                justify-self: end;
                padding: 10px 5px 5px 0;
            }

            .topout-trim-lot-notes-value{
                grid-column: 6/11;
                padding: 10px 0 5px 5px;
                cursor: pointer;

                .add-note-button-box {
                    display: grid;
                    justify-content: start;
                    margin-top: -2px;
                    
                    .add-note-button {
                        width: 100px;
                        height: 22px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: default;
                        
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }

        .submit {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 10px 0 20px;
            background: rgb(210, 200, 190);
            
            .button {
                justify-self: center;
                width: 180px;
                height: 30px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }

            .grayed{
                color: rgb(240, 240, 240);
                background: rgb(170, 170, 170);
                border: 1px solid rgb(180, 180, 180);
                cursor: default;
                
                :hover {
                    background: rgb(170, 170, 170);
                }
            }
        }
    }
`

export default TopOutTrimReleaseContainer;