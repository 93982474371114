import {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

import MainHeaderContainer, {Spacer} from './styles/MainHeaderContainer.js';
import logo from './../../img/HeaderLogo.png';
import Config from '../../config'
import Auth from "../authentication/Auth.js";
import Navbar from "../navigation/Navbar";
import {LockReset, PowerSettingsNew} from "@mui/icons-material";
import MainHeaderAlert from './MainHeaderAlert.js';
import {Tooltip} from "@mui/material";
import 'react-tooltip/dist/react-tooltip.css';
import {AppContext} from "../../context/AppProvider";

const MainHeader = (props) => {

    const navigate = useNavigate();
    const [name, setName] = useState('');

    const [didLoadData, setDidLoadData] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);
            setName(await Auth.getFullName());
        }

        if (!didLoadData) {
            loadData();
        }
    }, [didLoadData]);

    const handleLogOut = (e) => {
        Auth.logout();
        setState(false)
        setIsAuthenticated(false)
        navigate('/login');
    }
    const handleChangePassword = (e) => {
       navigate('/change-password');
    }

    const { isAuthenticated, setIsAuthenticated, state, setState } = useContext(AppContext);
    return (
        <Spacer>
            {(isAuthenticated === true || state === true) && <MainHeaderContainer id='main-header'>
                <div className="main-header-logo" onClick={(e) => navigate('/home')}>
                    <picture>
                        <img sizes="(max-width: 236px) 100vw, 236px"
                             srcSet={`${logo} 100w, ${logo} 236w`}
                             src={logo}
                             alt="SVC Logo"/>
                    </picture>
                </div>
                <div className={"desktop-nav"}>
                    <div className='title-name-box'>
                        {Config.BUILD_TYPE() === 'DEV' ?
                        <div className="main-header-title dev-build">### Dev Build ###</div> :
                        <div className="main-header-title">Field Management System</div>}
                        <MainHeaderAlert/>
                        <Tooltip id={"user-name-tooltip"} title={"This is You"} placement={"left-start"} arrow>
                            <div className="main-header-user-name">{name}</div>
                        </Tooltip>
                        <div className={"main-header-user-buttons-div"}>
                            <Tooltip id={"logout-tooltip"} title={"This Will Log You Out Of FMS"} placement={"left-start"} arrow>
                                <PowerSettingsNew className={"main-header-user-buttons logout"} onClick={(e) => handleLogOut(e)}/>
                            </Tooltip>
                            <Tooltip id={"change-password-tooltip"} title={"This Will Change Your FMS Password"} placement={"right-start"} arrow>
                                <LockReset className={"main-header-user-buttons change-password"} onClick={(e) => handleChangePassword(e)}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="main-header-drop-down">
                        <Navbar/>
                    </div>
                </div>
                <div className={"mobile-nav"}>
                    <div className='title-name-box'>
                        <div className='username-logout-box'>
                            <div className="main-header-user-name">{name}</div>
                            <div className={"main-header-user-buttons-div"}>
                                <PowerSettingsNew className={"main-header-user-buttons"} title={"Logout"}
                                                  onClick={(e) => handleLogOut(e)}/>
                                <LockReset className={"main-header-user-buttons"} title={"Change Password"}
                                           onClick={(e) => handleChangePassword(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="main-header-drop-down">
                        <Navbar/>
                    </div>
                </div>
            </MainHeaderContainer>}
        </Spacer>
    );
}

export default MainHeader;
