import {useEffect, useState} from 'react';

import Lots from '../../classes/Lots.js';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import JobLotFilterContainer from './styles/JobLotFilterContainer.js';
import Auth from "../authentication/Auth.js";
import Select, {createFilter} from "react-select";


// Add more forms here
const JobLotFilter = (props) => {

    const [didLoadData, setDidLoadData] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            let tempJobs = await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders())
            props.setJobs(tempJobs.sort((j1, j2) => {
                return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;}))
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const selectJob = async (selection) => {
        let tempLots = []
        props.setSelectedJob(selection)
        if (selection !== null && selection !== '') {
            tempLots = Lots.sortLots("jobNumber", "ascending", await Lots.loadLots({jobId: selection.id}, selection.id), props.jobs)
        }
        props.setLots(tempLots)
        //Clear these out because the job would have changed
        props.setSelectedLot(null)
    }

    const selectLot = async (selection) => {
        // Get selected lot
        const tempSelectedLot = selection === null ? null : props.lots.find(lot => lot.id.toString() === selection.value.toString());
        if (selection !== null) {
            tempSelectedLot.value = selection.value
            tempSelectedLot.label = selection.label
            tempSelectedLot.lot = selection.lot
        }
        let lot = await props.lotSelected(tempSelectedLot)
        props.setSelectedLot(lot)
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);
    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    return (
        <JobLotFilterContainer>
            <div className='form-header'>{props.title}</div>
            <div className='job-sub-header'>Select Job & Lot</div>
            <div className='job-drop-down-box'>
                <label className="job-drop-down-label" htmlFor='job-form-select'>Job</label>
                <Select className="job-drop-down-select" id='job-form-select' name='job-form-select'
                        onChange={(e) => selectJob(e)}
                        options={props.jobs.map(job => {
                            return (
                                {
                                    value: job.id,
                                    id: job.id,
                                    label: job.display,
                                    job: job
                                }
                            )
                        })}
                        value={props.selectedJob}
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig)}
                        label="Select a Job"
                        placeholder={"Select a Job"}
                />
            </div>
            <div className='lot-drop-down-box'>
                <label className="lot-drop-down-label" htmlFor='lot-form-select'>Lot</label>
                <Select className="lot-drop-down-select" id='lot-form-select' name='lot-form-select'
                        onChange={(e) => selectLot(e)}
                        options={props.lots.map(lot => {
                            const value = lot.id;
                            const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                            return (
                                {
                                    value: value,
                                    id: value,
                                    label: display,
                                    lot: lot
                                }
                            )
                        })}
                        value={props.selectedLot}
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig)}
                        label="Select a Lot"
                        placeholder={"Select a Lot"}
                />
            </div>
        </JobLotFilterContainer>
    )
}

export default JobLotFilter;