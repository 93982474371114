import { useState} from 'react';
import moment from "moment/moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Stack from '@mui/material/Stack';

import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import EWAs from '../../classes/Ewas';
import Employees from '../../classes/Employees.js';
import Auth from '../authentication/Auth.js';
import Button from "@mui/material/Button";

import MainHeader from '../utilities/MainHeader.js';

import Popup, {PopupFailureColor, PopupWarningColor, PopupSuccessColor} from '../utilities/Popup.js';

import EWAContainer from './styles/EWAContainer';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";

import {
    IconButton,
    InputLabel,
    Tooltip, Typography,
} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Add, Cancel, CheckCircle, Close, Delete, Edit, Save, ThumbDown, ThumbUp} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Select, {createFilter} from "react-select";
import {filterConfig} from "../utilities/FilterConfig"
import Users from "../../classes/Users";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DialogActions from "@mui/material/DialogActions";
import CrewGrid from "./crew/CrewGrid";
import ScheduleCrew from "./crew/ScheduleCrew";
import Crews from "../../classes/Crews";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Ewas from "../../classes/Ewas";

const EWA = ({openEWA, setOpenEWA, ewa, setEWA, applyFilters, canCreateEditEWA}) => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([])

    const [ewaCodes, setEwaCodes] = useState([])
    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([])

    const [allEmployees, setAllEmployees] = useState([]);
    const [displayLoadingMessage, setDisplayLoadingMessage] = useState(false);

    const [errorsInNewEWA, setErrorsInNewEWA] = useState(false);
    const [errorNoLotSelected, setErrorNoLotSelected] = useState(false);
    const [errorNoPhaseSelected, setErrorNoPhaseSelected] = useState(false);
    const [errorNoPhaseCodeSelected, setErrorNoPhaseCodeSelected] = useState(false);
    const [errorNoJobDescription, setErrorNoJobDescription] = useState(false);
    const [errorNoInternalEstimateOrBuilderPO, setErrorNoInternalEstimateOrBuilderPO] = useState(false);
    const [errorNoScheduledDate, setErrorNoScheduledDate] = useState(false);
    const [errorNoAmountEntered, setErrorNoAmountEntered] = useState(false);
    const [errorNoEmployeeSelected, setErrorNoEmployeeSelected] = useState(false);
    const [errorNoLineItems, setErrorNoLineItems] = useState(false);
    const [ewaSuccess, setEWASuccess] = useState(false);

    const [phases, setPhases] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('')

    const [openLineItem, setOpenLineItem] = useState(false)

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [crew, setCrew] = useState('')

    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [empsToRemove, setEmpsToRemove] = useState([])

    const [po, setPO] = useState('')
    const [pos, setPOS] = useState([])
    const [poAmount, setPOAmount] = useState(0.00)
    const [poRemaining, setPORemaining] = useState(0.00)


    const [starts, setStarts] = useState([])
    const [payout, setPayout] = useState(0.00)
    const [cost, setCost] = useState(0.00)

    const [codes, setCodes] = useState([])

    const [types, setTypes] = useState([])
    const [ewaType, setEWAType] = useState('')
    const [job, setJob] = useState('')
    const [lot, setLot] = useState('')

    const [selectedDate, setSelectedDate] = useState("")
    const [tasks, setTasks] = useState([])
    const [phase, setPhase] = useState('')
    const [notes, setNotes] = useState('')
    const [internalEstimateNumber, setInternalEstimateNumber] = useState('')
    const [builderPO, setBuilderPO] = useState('')
    const [materials, setMaterials] = useState([])

    const [selectedTask, setSelectedTask] = useState('')

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('')

    const [lineItemsTable, setLineItemsTable] = useState({})

    const [userId, setUserId] = useState('')


    const [ewaNew, setEWANew] = useState({})

    const [editLine, setEditLine] = useState({});

    const togglePopup = () => {

        setErrorsInNewEWA(false);
        setErrorNoLotSelected(false);
        setErrorNoPhaseCodeSelected(false);
        setErrorNoPhaseSelected(false);
        setErrorNoJobDescription(false);
        setErrorNoInternalEstimateOrBuilderPO(false);
        setErrorNoScheduledDate(false);
        setErrorNoAmountEntered(false);
        setErrorNoEmployeeSelected(false);
        setErrorNoLineItems(false);
        setEWASuccess(false);
    }

    const getEmployeeName = (id) => {
        const employee = allEmployees.find(employee => employee.id === id);
        let rtn = employee ? `${employee.alias !== null ? employee.alias : ''} ${employee.first_name} ${employee.last_name}` : '';
        return rtn.trim()
    }

    const getUserName = (id) => {
        const user = users.find(user => user.id === id);
        return user ? `${user.firstname} ${user.lastname}` : '';
    }

    const getUserInitals = (id) => {
        const user = users.find(user => user.id === id);
        return user ? `${user.firstname[0]}.${user.lastname[0]}.` : '';
    }

    const loading = (isLoading) => {
        setDisplayLoadingMessage(isLoading);
    }

    const loadData = async () => {
        setIsLoading(true)
        setDidLoadData(true);

        setUserId(await Auth.getUserId())
        let phaseList = await Phases.loadPhases(null)
        setPhases(phaseList)
        let taskList = await EWAs.loadEWACodes(null)
        setTasks(taskList)

        let dbUsers = await Users.loadUsers()
        setUsers(dbUsers)

        let userId = await Auth.getUserId()
        let user = dbUsers.find(x => x.id == userId)
        if(user !== undefined) {
            setSelectedUser({
                id: user.id,
                label: `${user.firstname} ${user.lastname}`,
                user: user
            })
        }

        setEwaCodes(await EWAs.loadEWACodes(null));
        setAllEmployees(await Employees.loadEmployees(null));
        setJobs(await Jobs.loadJobs(null, await Builders.loadBuilders()));

        //Set the selectedPhase if this is an EWA Edit
        if(ewa !== undefined && ewa !== null && ewa.line_items.length > 0) {
            let foundPhase = phaseList.find(x => x.id === ewa.line_items[0].phase_id)
            if (foundPhase === undefined) {
                let foundTask = taskList.find(x => x.id === ewa.line_items[0].phase_task_id)
                if (foundTask !== undefined) {
                    foundPhase = foundTask.phases[0]
                }
            }
            if (foundPhase !== undefined) {
                setSelectedPhase({
                    id: foundPhase.id,
                    label: (`${foundPhase.description}`),
                    phase: foundPhase
                })
            }
            let ewaEmps = []
            if (ewa.id !== '' && ewa.id !== null) {
                for (let e = 0; e < ewa.line_items[0].crew.employees.length; e++) {
                    ewaEmps.push(ewa.line_items[0].crew.employees[e].employee)
                }
            }
            setSelectedEmployees(ewaEmps)
        }

        if(ewa === undefined){
            //setEWA = setEWANew
            let newEWA = await EWAs.createNewEWA(null)
            newEWA.line_items = []
            setEWA(newEWA)
        }
        setIsLoading(false)
    }

    const handleLoadEmployees = async (phase) => {
        let queryObj = phase !== null ? {[phase.phase.company.toLowerCase()]: 1} : null
        setEmployees(await Employees.loadEmployeesForDropDown(queryObj))
    }
    const handleLoadCrews = async (phase) => {
        let queryObj = phase !== null ? {company: phase.phase.company} : null
        setCrews(await Crews.loadCrewsForDropdown(queryObj))
    }

    if(!didLoadData){
        loadData();
    }

    const defaultColumnProperties = {
        width: 200
    }

    const updateLineItemValue = (row, field, value) => {
        let tempEWA = {...ewa}

        let idx = tempEWA.line_items.indexOf(row)
        tempEWA.line_items[idx][field] = value
        row[field] = value
        setEWA(tempEWA)
    }

    const lineItemColumns = [
        {
            accessorFn: (row) => {
                return (
                    <div style={{display: 'grid'}}>
                        <span style={{display: 'grid', gridRow: '1/6', gridColumn: '1/1'}}>{row.job}</span>
                        <span style={{display: 'grid', gridRow: '6/6', gridColumn: '1/1', paddingTop: '15px'}}>{row.lot}</span>
                        <span style={{display: 'grid', gridRow: '13/13', gridColumn: '1/1', paddingTop: '15px'}}>{row.address}</span>
                    </div>
                )},
            id: 'job_number',
            header: 'Job',
            enableEditing: false,
            size: 300,
        },
        {
            accessorFn: (row) => {
                return (
                    <div style={{display: 'grid'}}>
                        <span style={{display: 'grid', gridRow: '1/6', gridColumn: '1/1'}}>{row.task}</span>
                        <span style={{display: 'grid', gridRow: '6/6', gridColumn: '1/1', paddingTop: '15px'}}>{dayjs(row.schedule_date).format('MM/DD/YYYY')}</span>
                        <span style={{display: 'grid', gridRow: '13/13', gridColumn: '1/1', paddingTop: '15px'}}>${row.payout}</span>
                    </div>
                )},
            id: 'task',
            header: 'Task',
            size: 350,
            enableEditing: true,
            grow: true,
        },
        {
            accessorFn: (row) => {
                return (
                    <div style={{display: 'grid'}}>
                        <span style={{display: 'grid', gridRow: '1/6', gridColumn: '1/1'}}>{row.internal_estimate_number}</span>
                        <span style={{display: 'grid', gridRow: '6/6', gridColumn: '1/1', paddingTop: '15px'}}>{row.builder_po_number}</span>
                        <span style={{display: 'grid', gridRow: '13/13', gridColumn: '1/1', paddingTop: '15px', width: '475%'}}>{row.notes}</span>
                </div>
                )},
            id: 'info',
            header: 'Notes',
            enableEditing: false,
            size: 800,
        }
    ].map(c => ({ ...c, ...defaultColumnProperties }))

    const [crewOpen, setCrewOpen] = useState(false);
    const handleClickCrewOpen = () => {
       if(selectedPhase === null || selectedPhase === '') {
            setErrorNoPhaseSelected(true)
        }
        else {
            setCrewOpen(true);
        }
    };
    const handleCrewClose = () => {
        setCrewOpen(false);
    };

    const handleClearSelectedEmployees = () => {
        setSelectedEmployees([])
    }

    const handleSaveLineItem = () => {
        if(lot === ''){
            setErrorNoLotSelected(true)
        }
        else if(selectedTask === ''){
            setErrorNoPhaseCodeSelected(true)
        }
        else if(selectedTask.task.billable && internalEstimateNumber === '' && builderPO === '') {
            setErrorNoInternalEstimateOrBuilderPO(true)
        }
        else if(payout === null || payout === '' || payout === 0){
            setErrorNoAmountEntered(true)
        }
        else if(selectedDate === null || selectedDate === ''){
            setErrorNoScheduledDate(true)
        }
        else if(notes === ''){
            setErrorNoJobDescription(true)
        }
        else {
            let tempEwa = {...ewa}
            let lines = [...tempEwa.line_items]
            if(editLine.id !== undefined) {
                for(let x=0; x<lines.length;x++){
                    if(lines[x].id === editLine.id){
                        lines[x].lot_id = lot === '' ? null : lot.id
                        lines[x].job = lot === '' ? null : `${job.job.number} ${job.job.project_name}`
                        lines[x].lot = lot === '' ? null : `${lot.label}`
                        lines[x].address = lot === '' ? null : lot.lot.address
                        lines[x].phase_id = selectedPhase.id
                        lines[x].phase_task_id = selectedTask.id
                        lines[x].task = selectedTask.label
                        lines[x].schedule_date = dayjs(selectedDate).format('YYYY-MM-DD')
                        lines[x].scheduled_by = userId
                        lines[x].payout = payout
                        lines[x].internal_estimate_number = internalEstimateNumber
                        lines[x].builder_po_number = builderPO
                        lines[x].notes = notes
                    }
                }
            }
            else {
                lines.push({
                    lot_id: lot === '' ? null : lot.id,
                    job: lot === '' ? null : `${job.job.number} ${job.job.project_name}`,
                    lot: lot === '' ? null : `${lot.label}`,
                    address: lot === '' ? null : lot.lot.address,
                    phase_id: selectedPhase.id,
                    phase_task_id: selectedTask.id,
                    task: selectedTask.label,
                    schedule_date: dayjs(selectedDate).format('YYYY-MM-DD'),
                    scheduled_by: userId,
                    payout: payout,
                    internal_estimate_number: internalEstimateNumber,
                    builder_po_number: builderPO,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                    complete: false,
                    completed_by: null,
                    completed_date: null,
                    notes: notes,
                    scheduled: true,
                    requested: false,
                })
            }
            tempEwa.line_items = lines
            setEWA(tempEwa)
            setEditLine({})
            clearLineItemFields()
            setOpenLineItem(false)
        }
    }
    const clearLineItemFields = () => {
        setJob('')
        setLot('')
        setSelectedTask('')
        setSelectedDate('')
        setPayout(0)
        setNotes('')
        setInternalEstimateNumber('')
        setBuilderPO('')
        setEditLine({})
    }
    const handleCancelLineItem = () => {
        setOpenLineItem(false)
    }

    const handleUserChange = (selected) => {
        setSelectedUser(selected)
    }
    const handlePhaseChange = async (selected) => {

        let phs = selected
        let tempEWA = {...ewa}
        setSelectedPhase(phs)
        if(phs !== null){
            let curDate = moment(new Date()).format('YYYY-MM-DD')
            let year = moment(curDate).format('YYYY')
            setSelectedDate(curDate)

            let number = await EWAs.getNextNumber(phs.phase.company, phs.phase.ewa_suffix, year, undefined)
            tempEWA.number = number !== undefined && number !== null ? number.ewa_number : ''

            await handleLoadCrews(phs)
            await handleLoadEmployees(phs)
        }
        if(Object.keys(lineItemsTable).length > 0) {
            let rows = lineItemsTable.getRowModel().rows
            for(let r=0; r<rows.length; r++){
                let row = rows[r]
                row.original.phase_task_id = null
            }
        }

        setEWA(tempEWA)
    }

    const handleJobChange = async(selection) =>{
        setJob(selection)
        setLots(await Lots.loadLots({job_id: selection.id}, selection.id))
    }
    const handleLotChange = (selection) =>{
        setLot(selection)
    }

    const handleTaskChange = async (selection) => {
       setSelectedTask(selection)
    }

    const handleDateChange = (selection) =>{
        setSelectedDate(selection)
    }

    const handlePayoutChange = (e) => {
        setPayout(e.target.value)
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value)
    }

    const handleInternalEstimateNumberChange = (e) => {
        setInternalEstimateNumber(e.target.value)
    }

    const handleBuilderPOChange = (e) => {
        setNotes(e.target.value)
    }

    const handleSaveEWA = async () => {

        let tempEwa = {...ewa}
        let lines = [...ewa.line_items]
        let emps = []

        if(selectedEmployees.length === 0){
            setErrorNoEmployeeSelected(true)
        }
        else if(lines.length === 0){
            setErrorNoLineItems(true)
        }
        else {
            for (let e = 0; e < selectedEmployees.length; e++) {
                emps.push({
                    employee_id: selectedEmployees[e].id,
                    is_lead: e === 0,
                    approved: false,
                    approval_by: null,
                    approval_date: null
                })
            }

            for (let l = 0; l < lines.length; l++) {
                delete lines[l].job_id
                delete lines[l].job
                delete lines[l].lot
                delete lines[l].address
                delete lines[l].task

                let crewId = selectedEmployees.length > 0 && selectedEmployees[0].membership !== null ?
                    selectedEmployees[0].membership.crew.id :
                    lines[l].crew !== undefined && lines[l].crew !== null ?
                        lines[l].crew.crew_id :
                        lines[0].crew !== undefined && lines[0].crew !== null ?  lines[0].crew.crew_id : null

                let existingCrew = lines[l].crew
                lines[l].crew = {
                    schedule_id: null,
                    crew_id: crewId,
                    employees: emps
                }
                if (existingCrew !== undefined) {
                    lines[l].crew.id = existingCrew.id
                    lines[l].crew.schedule_id = existingCrew.schedule_id
                }
            }

            tempEwa.line_items = lines
            await EWAs.saveEWAs([tempEwa])
            if (applyFilters !== null) {
                applyFilters()
            }
            setOpenEWA(false)
            setEWA({})
            setEWASuccess(true)
        }
    }
    const handleCancelEWA = async () => {
        if(openEWA !== undefined && openEWA !== null) {
            setOpenEWA(false)
        }
    }

    function getOptions() {
        const options = {
            filterType: 'none',
            jsonMode: true,
            selectableRows: false,
            print: false,
            filter: false,
            search:false,
            download: false,
            viewColumns: false,
            fixedHeaderOptions: {
                xAxis: true,
                yAxis: true,
            },
            style: {width: '35%'},
            enableEditing:true,
            editDisplayMode: "table",
        }
        return options
    }


    return (
        <EWAContainer>
            <AppBar position="absolute" style={{zIndex: '0', alignItems: 'end', backgroundColor: 'rgb(210, 200, 190)'}}>
                <Toolbar variant="dense">
                    <IconButton edge="end" color="inherit" aria-label="Close EWA Form" sx={{ md: 'none', color: 'blue', borderRadius: 0}}
                                onClick={() => handleCancelEWA()}>
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div position="absolute" style={{marginTop: '77px', marginLeft: '5px', marginRight: '20px', orverflow: 'auto'}}>
                <div className="baseContainer ewa-form" style={{display: "grid"}}>
                    <div className={'step-one'}>
                        <span className={"ewa-number"}>
                            {ewa === undefined || ewa.number === undefined || ewa.number === null || ewa.number === '' ? 'Select a Phase to generate a new EWA Number' : ewa.number}
                            {/*{ewa.number} {ewaType} {trade}*/}
                        </span>
                        <Select id="user-select"
                                isClearable
                                isSearchable
                                className={"user-select"}
                                value={selectedUser}
                                onChange={(e) => {
                                    handleUserChange(e)
                                }}
                                options={users.map((user) => {
                                    return({
                                        id: user.id,
                                        label: `${user.firstname} ${user.lastname}`,
                                        user: user
                                    })
                                })}
                                filterOption={createFilter(filterConfig(true, true, true, true))}
                                getOptionValue={(option) => option.id}
                                isDisabled={isLoading}/>
                        <Select id="phase-select"
                                isClearable
                                isSearchable
                                className={"phase-select"}
                                value={selectedPhase}
                                onChange={(e) => {
                                    handlePhaseChange(e)
                                }}
                                options={phases.map((phase) => {
                                    return({
                                        id: phase.id,
                                        label: (`${phase.description}`),
                                        phase: phase
                                    })
                                })}
                                filterOption={createFilter(filterConfig(true, true, true, true))}
                                getOptionValue={(option) => option.id}
                                isDisabled={isLoading}/>
                    </div>
                    <div>
                        <div className="row" style={{width: '35%'}}>
                            <fieldset>
                                <legend>Select Employees</legend>
                                <CrewGrid
                                    style={{height: selectedEmployees.length, minHeight: "0px !important"}}
                                    selectedEmployees={selectedEmployees}
                                    setSelectedEmployees={setSelectedEmployees}
                                    setSelectedEmployeesEdit={setSelectedEmployees}
                                    selectedEmployeesEdit={selectedEmployees}
                                    empsToRemove={empsToRemove}
                                    setEmpsToRemove={setEmpsToRemove}
                                    minHeight={"0px"}
                                    marginTop={"opx"}
                                />
                                <Button aria-label="Clear Selected Employees" aria-hidden={false} variant="contained" disabled={isLoading} onClick={handleClearSelectedEmployees}
                                        style={{width: "30%", fontSize: "x-small", float: "right", marginTop: "15px",  color: "red", backgroundColor: "lightgray"}}>
                                    Clear
                                </Button>
                                <Button aria-label="Add Employees To EWA" aria-hidden={false} variant="contained" onClick={handleClickCrewOpen} disabled={isLoading} style={{
                                    width: "20%",
                                    fontSize: "x-small",
                                    float: "right",
                                    marginTop: "15px",
                                    marginLeft: "2px",
                                    color: "blue",
                                    backgroundColor: "lightgray"
                                }}>
                                    Employees
                                </Button>
                                <Dialog open={crewOpen} onClose={handleCrewClose} fullWidth={true}>
                                    <DialogTitle>Build A Crew</DialogTitle>
                                    <DialogContent>
                                        <ScheduleCrew
                                            crews={crews}
                                            employees={employees}
                                            setEmployees={setEmployees}
                                            setSelectedEmployees={setSelectedEmployees}
                                            selectedEmployees={selectedEmployees}
                                            empsToRemove={empsToRemove}
                                            setEmpsToRemove={setEmpsToRemove}
                                            setOpen={setCrewOpen}
                                            showActions={true}
                                            phase={selectedPhase}
                                            scheduleDate={selectedDate}
                                        ></ScheduleCrew>
                                    </DialogContent>
                                </Dialog>
                            </fieldset>
                        </div>
                        <Stack spacing={4}>
                            <MaterialReactTable
                                data={ewa === undefined || ewa.line_items === undefined ? [] : ewa.line_items}
                                columns={lineItemColumns}
                                options={getOptions()}
                                enableEditing={false}
                                enableRowActions={true}
                                initialState={{
                                    showColumnFilters: false,
                                    columnPinning: {
                                        left: ['mrt-row-actions']
                                    },
                                    density: 'compact',
                                    expanded: false,
                                    showGlobalFilter: false,
                                }}
                                renderTopToolbarCustomActions={(table) => {
                                    const handleCreateLineItem = () => {
                                        setEditLine({})
                                        setOpenLineItem(true)
                                    };
                                    return <div>
                                        <Tooltip title="New Line Item">
                                            <IconButton
                                                aria-label="Add a new line item to the EWA"
                                                onClick={() => {
                                                    if(selectedPhase === ''){
                                                        setErrorNoPhaseSelected(true)
                                                    }
                                                    else {
                                                        handleCreateLineItem()
                                                    }
                                                }}>
                                                <Add/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>;
                                }}
                                renderRowActions={({table, row }) =>
                                    (<Box className={'row-actions'}>
                                        <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                         {canCreateEditEWA && (row.original.deleted_at === undefined || row.original.deleted_at === null) &&
                                             <IconButton
                                                 aria-label="Edit EWA"
                                                 aria-hidden={false}
                                                 tabIndex={-1}
                                                 sx={{backgroundColor: 'red', color: 'white', "&:hover": { color: 'red', backgroundColor: 'white' }}}
                                                 disabled={!canCreateEditEWA}
                                                 onClick={async () => {
                                                     let tempEwa = {...ewa}
                                                     let proceed = window.confirm(`Are you sure you want to delete ${row.original.task} for ${row.original.job} lot  ${row.original.lot} line item on ${row.original.schedule_date}?`)
                                                     if(proceed) {
                                                         for (let l = 0; l < tempEwa.line_items.length; l++) {
                                                             if (tempEwa.line_items[l].id === row.original.id) {
                                                                 tempEwa.line_items[l].deleted_at = dayjs(new Date()).format('YYYY-MM-DD')
                                                                 tempEwa.line_items[l].deleted_by = userId
                                                                 row.original.deleted_at = dayjs(new Date()).format('YYYY-MM-DD')
                                                                 row.original.deleted_by = userId

                                                             }
                                                         }
                                                     }
                                                     setEWA(tempEwa)
                                                 }}
                                                 variant="contained"
                                             >
                                                 <Delete />
                                             </IconButton>}
                                        </span>
                                        <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                         {canCreateEditEWA &&
                                             <IconButton
                                                 aria-label="Edit EWA"
                                                 aria-hidden={false}
                                                 tabIndex={-1}
                                                 sx={{backgroundColor: 'orange', color: 'white', "&:hover": { color: 'orange', backgroundColor: 'white' }}}
                                                 disabled={!canCreateEditEWA}
                                                 onClick={async () => {
                                                     let line = row.original
                                                     let jb = jobs.find(x => x.id === line.job_id)
                                                     let lts = await Lots.loadLots({job_id: jb.id}, jb.id)
                                                     setLots(lts)
                                                     let lt = lts.find(x => x.id === line.lot_id)
                                                     let pt = tasks.find(x => x.id === line.phase_task_id)
                                                     setJob({
                                                         id: jb.id,
                                                         label: (`${jb.number} - ${jb.project_name}`),
                                                         job: jb
                                                     })
                                                     setLot({
                                                         id: lt.id,
                                                         label: (`${lt.number} ${lt.phase}`).trim(),
                                                         lot: lt
                                                     })
                                                     setSelectedTask({
                                                         id: pt.id,
                                                         label: (`${pt.code !== null ? pt.code : ''} ${pt.description}`).trim(),
                                                         task: pt
                                                     })
                                                     let schDt = dayjs(line.schedule_date)
                                                     setSelectedDate(schDt)
                                                     setPayout(line.payout)
                                                     setNotes(line.notes)
                                                     setInternalEstimateNumber(line.internal_estimate_number)
                                                     setBuilderPO(line.builder_po_number)
                                                     setEditLine(line)
                                                     setOpenLineItem(true)
                                                 }}
                                                 variant="contained"
                                             >
                                                 <Edit />
                                             </IconButton>}
                                        </span>
                                    </Box>)
                                }
                            />
                        </Stack>
                    </div>
                </div>
                {openLineItem &&
                    <Dialog open={openLineItem}>
                        <DialogTitle>EWA Line Item</DialogTitle>
                        <DialogContent sx={{width: "600px", height: '500px'}}>
                            <div className={"new-lines"}>
                                <Select id="job-select"
                                        style={{width: '100%', paddingBottom: '20pt'}}
                                        isClearable
                                        isSearchable
                                        placeholder={'Select A Job'}
                                        className={"job-select"}
                                        value={job}
                                        onChange={(e) => {
                                            handleJobChange(e)
                                        }}
                                        options={jobs.map((job) => {
                                            return({
                                                id: job.id,
                                                label: (`${job.number} - ${job.project_name}`),
                                                job: job
                                            })
                                        })}
                                        filterOption={createFilter(filterConfig(true, true, true, true))}
                                        getOptionValue={(option) => option.id}
                                        isDisabled={isLoading}/>
                                <Select id="lot-select"
                                        style={{width: '100%', paddingBottom: '20pt'}}
                                        isClearable
                                        isSearchable
                                        placeholder={'Select A Lot'}
                                        className={"job-select"}
                                        value={lot}
                                        onChange={(e) => {
                                            handleLotChange(e)
                                        }}
                                        options={lots.map((lot) => {
                                            return({
                                                id: lot.id,
                                                label: (`${lot.number} ${lot.phase}`).trim(),
                                                lot: lot
                                            })
                                        })}
                                        filterOption={createFilter(filterConfig(true, true, true, true))}
                                        getOptionValue={(option) => option.id}
                                        isDisabled={isLoading}/>
                                <Select id="phase-task-select"
                                        style={{width: '100%', paddingBottom: '20pt'}}
                                        isClearable
                                        isSearchable
                                        placeholder={'Select A Task'}
                                        className={"job-select"}
                                        value={selectedTask}
                                        onChange={(e) => {
                                            handleTaskChange(e)
                                        }}
                                        options={tasks.filter(x => x.phases.filter(p => p.id === selectedPhase.id).length > 0).map((task) => {
                                            return({
                                                id: task.id,
                                                label: (`${task.code !== null ? task.code : ''} ${task.description}`).trim(),
                                                task: task
                                            })
                                        })}
                                        filterOption={createFilter(filterConfig(true, true, true, true))}
                                        getOptionValue={(option) => option.id}
                                        isDisabled={isLoading}/>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        style={{width: '100%', paddingTop: '20px'}}
                                        onChange={(newValue) => {
                                            handleDateChange(newValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                helperText: 'Select A Date',
                                                sx: {minWidth: '120px'},
                                                variant: 'standard',
                                            },
                                        }}
                                        disabled={isLoading}
                                        isDisabled={isLoading}
                                        defaultValue={dayjs(selectedDate)}
                                        selected={selectedDate}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    style={{width: '100%', padding: '20px'}}
                                    placeholder={'Payout'}
                                    type={'decimal'}
                                    helperText={'Payout'}
                                    defaultValue={payout}
                                    onBlur={(e) => handlePayoutChange(e)}
                                ></TextField>
                                <TextField
                                      style={{width: '100%', padding: '20px'}}
                                    placeholder={'Notes'}
                                    type={'text'}
                                    helperText={'Notes'}
                                    defaultValue={notes}
                                    onBlur={(e) => handleNotesChange(e)}
                                ></TextField>
                                    {(selectedTask !== null && selectedTask.task !== undefined && selectedTask.task.billable) &&
                                        <div>
                                            <TextField
                                                style={{width: '100%', padding: '20px'}}
                                                placeholder={'Internal Estimate #'}
                                                type={'text'}
                                                helperText={'Internal Estimate #'}
                                                defaultValue={internalEstimateNumber}
                                                onBlur={(e) => handleInternalEstimateNumberChange(e)}
                                            ></TextField>
                                            AND/OR
                                            <TextField
                                                style={{width: '100%', padding: '20px'}}
                                                placeholder={'Builder PO'}
                                                type={'text'}
                                                helperText={'Builder PO'}
                                                defaultValue={builderPO}
                                                onBlur={(e) => handleBuilderPOChange(e)}
                                            ></TextField>
                                        </div>}
                                {errorNoLotSelected && <Popup color={PopupFailureColor} message={'No Lot Selected. Please select a lot.'} handlePopup={togglePopup}/>}
                                {errorNoPhaseCodeSelected && <Popup color={PopupFailureColor} message={'No Phase Code Selected. Please select a phase code.'} handlePopup={togglePopup}/>}
                                {errorNoJobDescription && <Popup color={PopupFailureColor} message={'No job description entered. Please enter a job description.'} handlePopup={togglePopup}/>}
                                {errorNoInternalEstimateOrBuilderPO && <Popup color={PopupFailureColor} message={'No Internal Estimate Number or Builder PO Number entered. Please enter an Internal Estimate Number or Builder PO Number.'} handlePopup={togglePopup}/>}
                                {errorNoScheduledDate && <Popup color={PopupFailureColor} message={'No scheduled date selected. Please select a schedule date.'} handlePopup={togglePopup}/>}
                                {errorNoAmountEntered && <Popup color={PopupFailureColor} message={'No amount entered. Please enter an amount.'} handlePopup={togglePopup}/>}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button aria-label="Save Line Item" variant='contained' onClick={() => handleSaveLineItem()}>{editLine.id !== undefined && editLine !== null ? 'Update' : 'Add'}</Button>
                            <Button aria-label="Cancel Line Item" variant='contained' onClick={() => handleCancelLineItem()}>Cancel</Button>
                        </DialogActions>
                    </Dialog>}

                <AppBar position="absolute" sx={{ top: 'auto', bottom: 0, alignItems: 'end', backgroundColor: 'rgb(210, 200, 190)'}}>
                    <Toolbar variant="dense">
                        <IconButton edge="start" color="inherit" aria-label="Save EWA" sx={{ md: 'none', paddingRight: '15px', borderRadius: '0', color: 'green'}}
                                    onClick={() => handleSaveEWA()}>
                            <Save />
                            <div>Save</div>
                        </IconButton>
                        <IconButton edge="end" color="inherit" aria-label="Cancel EWA" sx={{ md: 'none', paddingLeft: '15px', borderRadius: '0', color: 'red'}}
                                    onClick={() => handleCancelEWA()}>
                            <Cancel />
                            <div>Cancel</div>
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            {errorsInNewEWA && <Popup color={PopupFailureColor} message={"Errors exist in some or all of the new EWA's. Please correct or remove the EWA's and resubmit."} handlePopup={togglePopup}/>}
            {errorNoLineItems && <Popup color={PopupFailureColor} message={"No line items. Please add as line item with a job/lot, task, payout, and date first."} handlePopup={togglePopup}/>}
            {errorNoEmployeeSelected && <Popup color={PopupFailureColor} message={'No employee selected. Please select an employee.'} handlePopup={togglePopup}/>}
            {errorNoPhaseSelected && <Popup color={PopupFailureColor} message={'No Phase Selected. Please select a phase to proceed.'} handlePopup={togglePopup}/>}
            {ewaSuccess && <Popup color={PopupSuccessColor} message={`New Extra Work Authorization has been submitted`} handlePopup={togglePopup}/>}
        </EWAContainer>
    )
};

export default EWA;
