import { useState } from 'react';
import moment from 'moment/moment';

import Auth from '../authentication/Auth.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { Button } from '../utilities/Button.js';
import { Checkbox } from '../utilities/Checkbox.js';
import MainHeader from '../utilities/MainHeader.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import EditUserContainer from './styles/EditUserContainer.js';


const EditUser = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);
    const [userToEdit, setUserToEdit] = useState(null);

    const [roles, setRoles] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [fields, setFields] = useState([]);
    const [permissionGroups, setpermissionGroups] = useState([]);

    const [errorNoUserSelected, setErrorNoUserSelected] = useState(false);
    const [errorNoFirstName, setErrorNoFirstName] = useState(false);
    const [errorNoLastName, setErrorNoLastName] = useState(false);
    const [errorNoUsername, setErrorNoUsername] = useState(false);
    const [errorUserAlreadyExist, setErrorUserAlreadyExist] = useState(false);
    const [errorPositionNotSet, setErrorPositionNotSet] = useState(false);
    const [errorFieldNotSet, setErrorFieldNotSet] = useState(false);
    const [errorRoleNotSet, setErrorRoleNotSet] = useState(false);
    const [success, setSuccess] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);

        setRoles(await Auth.hitEndpoint("GET", "USER_ROLES"));
        setRolePermissions(await Auth.hitEndpoint("GET", "USER", null, null, "role/permissions"));
        setPositions(await Auth.hitEndpoint("GET", "USER_POSITIONS"));
        setFields(await Auth.hitEndpoint("GET", "USER_FIELDS"));
        setpermissionGroups(await Auth.hitEndpointNew("GET", 'permission/groups'));

        const tempUsers = await Auth.hitEndpoint("GET", "USERS");
        const sortedUsers = tempUsers.sort((user1, user2) => user1.username < user2.username ? -1 : 1);
        setUsers(sortedUsers);
    }

    if(!didLoadData){
        loadData();
    }

    const setUserToEditToNull = async () => {
        setUserToEdit(null);
    }

    const updateUser = async (e) => {
        if(!userToEdit){setErrorNoUserSelected(true);return;}
        if(userToEdit.firstname === ''){setErrorNoFirstName(true);return;}
        if(userToEdit.lastname === ''){setErrorNoLastName(true);return;}
        if(userToEdit.username === ''){setErrorNoUsername(true);return;}
        const isDupUserName = users.filter(userToFind => userToFind.username === userToEdit.username && userToFind.id !== userToEdit.id)
        if(isDupUserName.length > 1){setErrorUserAlreadyExist(true);return;}
        if(userToEdit.role === ''){setErrorRoleNotSet(true);return;}

        userToEdit.modified_by = await Auth.getUserId();
        userToEdit.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
        userToEdit.permissions.map(pn => {
            delete pn.permission;
            return pn;
        })

        await Auth.hitEndpoint("PATCH", "USER", "", userToEdit);
        await setUserToEditToNull();
        setUsers(await Auth.hitEndpoint("GET", "USERS"));
        setSuccess(true);
    }

    const toggleErrorPopup = () => {
        setErrorNoUserSelected(false);
        setErrorNoUsername(false);
        setErrorUserAlreadyExist(false);
        setErrorPositionNotSet(false);
        setErrorFieldNotSet(false);
        setErrorRoleNotSet(false);
        setErrorNoFirstName(false);
        setErrorNoLastName(false);
    }

    const toggleSuccess = async () => {
        const tempUsers = await Auth.hitEndpoint("GET", "USERS");
        const sortedUsers = tempUsers.sort((user1, user2) => user1.username < user2.username ? -1 : 1);
        setUsers(sortedUsers);

        setUserToEdit(null);
        setSuccess(false);
    }

    const handleRoleSelect = (e) => {
        const tempRolePermissions = rolePermissions.filter(rolePermission => rolePermission.role_id === Number.parseInt(e.target.value));
        const tempUserToEdit = {...userToEdit};
        tempUserToEdit.role_id = e.target.value;
        
        tempUserToEdit.permissions = [];
        for(let i = 0; i < tempRolePermissions.length; i++){
            const newPermission = {
                user_id: tempUserToEdit.id,
                permission_id: tempRolePermissions[i].permission_id,
                view: tempRolePermissions[i].view,
                create: tempRolePermissions[i].create,
                edit: tempRolePermissions[i].edit,
                delete: tempRolePermissions[i].delete
            }
            tempUserToEdit.permissions.push(newPermission);
        }

        setUserToEdit({...tempUserToEdit})
    }

    const selectPermissionGroup = async (e) => {
        if(e.target.value === ''){return;}
        const selectedPermissionGroup = permissionGroups.find(pg => parseInt(pg.id) === parseInt(e.target.value));
        const tempUserToEdit = {...userToEdit};
        tempUserToEdit.permission_group_id = e.target.value;
        tempUserToEdit.permissions = [];
        for(let i = 0; i < selectedPermissionGroup.group_permissions.length; i++){
            tempUserToEdit.permissions.push({
                permission_id: selectedPermissionGroup.group_permissions[i].permission_id,
                permission: selectedPermissionGroup.group_permissions[i].permission,
                read: selectedPermissionGroup.group_permissions[i].default_read,
                write: selectedPermissionGroup.group_permissions[i].default_write
            })
        }
        setUserToEdit(tempUserToEdit);
    }

    const cancelEditUser = async () => {
        const tempUsers = await Auth.hitEndpoint("GET", "USERS");
        const sortedUsers = tempUsers.sort((user1, user2) => user1.username < user2.username ? -1 : 1);
        setUsers(sortedUsers);
        setUserToEdit(null);
    }

    const setReadPermission = (permission, e) => {
        const tempUserToEdit = {...userToEdit};
        const index = tempUserToEdit.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempUserToEdit.permissions[index].read = e.target.checked;
        setUserToEdit(tempUserToEdit);
    }

    const setWritePermission = (permission, e) => {
        const tempUserToEdit = {...userToEdit};
        const index = tempUserToEdit.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempUserToEdit.permissions[index].write = e.target.checked;
        setUserToEdit(tempUserToEdit);
    }

    return (
        <EditUserContainer>
            <div className='edit-user-title'>Edit User</div>
            <div className='sub-header'>Select User To Edit</div>
            <div className='select-user-dropdown'>
                <DropDownWithLabel className="select-user" id="select-user-id" name="user" label="Select User" value={userToEdit ? userToEdit.id : ""} handleDropDown={(e) => setUserToEdit({...users.find(userToFind => userToFind.id === Number.parseInt(e.target.value))})} addBlankOption={true} selectionList={users.map(user => ({id: user.id, display: `${user.username} - ${user.firstname} ${user.lastname}`}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
            </div>
            {userToEdit && 
            <div className="user-details-box">
                <div className='sub-header'>User Details</div>
                <div className='user-data-input-box'>
                    <CheckboxWithLabel className="active" name="active" label="Active" checked={userToEdit.active} handleCheckbox={((e) => setUserToEdit({...userToEdit, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="firstname-textbox" name="firstname" label="First Name" placeholder="First Name" value={userToEdit.firstname} handleTextbox={((e) => setUserToEdit({...userToEdit, firstname: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="lastname-textbox" name="lastname" label="Last Name" placeholder="Last Name" value={userToEdit.lastname} handleTextbox={((e) => setUserToEdit({...userToEdit, lastname: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="username-textbox" name="username" label="User Name" placeholder="User Name" value={userToEdit.username} handleTextbox={((e) => setUserToEdit({...userToEdit, username: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="email-textbox" name="email" label="Email" placeholder="Email" value={userToEdit.email} handleTextbox={((e) => setUserToEdit({...userToEdit, email: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <DropDownWithLabel className="select-position" id="select-position-id" name="position" label="Position" value={userToEdit.position_id} handleDropDown={((e) => setUserToEdit({...userToEdit, position_id: e.target.value}))} addBlankOption={true} selectionList={positions.map(position => ({id: position.id, display: position.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="select-field" id="select-field-id" name="field" label="Field" value={userToEdit.field_id} handleDropDown={((e) => setUserToEdit({...userToEdit, field_id: e.target.value}))} addBlankOption={true} selectionList={fields.map(field => ({id: field.id, display: field.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="select-role" id="select-role-id" name="role" label="Role" value={userToEdit.role_id} handleDropDown={handleRoleSelect} addBlankOption={true} selectionList={roles.map(role => ({id: role.id, display: role.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="permission-group" name="permission-group" label="Permission Group" value={userToEdit.permission_group_id} handleDropDown={selectPermissionGroup} addBlankOption={true} selectionList={permissionGroups} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    {userToEdit.permission_group_id !== '' && userToEdit.permissions.length > 0 &&
                    <div>
                        <div className='assign-user-permission-group'>
                            <div className="read-write-label">
                                <div className="read-label">Read</div>
                                <div className="write-label">Write</div>
                            </div>
                            {userToEdit.permissions.map((permission, index) => {
                                return (
                                    <div key={`${permission.display}-${index}`}  className='permissions-display'>
                                        <div className="group-permission">{permission.permission.display}</div>
                                        <Checkbox className="read" name="read" checked={permission.read} handleCheckbox={(e) => setReadPermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                                        <Checkbox className="write" name="write" checked={permission.write} handleCheckbox={(e) => setWritePermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                    <div className='button-box'>
                        <Button className="update-user" handleKeyPress={((e) => {if(e.key === 'Enter'){updateUser()}})} handleClick={((e) => updateUser())} buttonText="Update User" width="120"/>
                        <Button className="cancel-update-user" handleKeyPress={(async (e) => {if(e.key === 'Enter'){cancelEditUser()}})} handleClick={(async (e) => cancelEditUser())} buttonText="Cancel" width="120"/>
                    </div>
                </div>
            </div>}
            {errorNoUserSelected && <Popup color={PopupWarningColor} message={`Please select a user from the dropdown box`} handlePopup={toggleErrorPopup}/>}
            {errorNoFirstName && <Popup color={PopupWarningColor} message={`Please enter the user's first name`} handlePopup={toggleErrorPopup}/>}
            {errorNoLastName && <Popup color={PopupWarningColor} message={`Please enter the user's last name`} handlePopup={toggleErrorPopup}/>}
            {errorNoUsername && <Popup color={PopupWarningColor} message={'Please enter a username'} handlePopup={toggleErrorPopup}/>}
            {errorUserAlreadyExist && <Popup color={PopupFailureColor} message={'Username already exists. Please enter a unique username.'} handlePopup={toggleErrorPopup}/>}
            {errorPositionNotSet && <Popup color={PopupWarningColor} message={`Position not set. Please select the user's position`} handlePopup={toggleErrorPopup}/>}
            {errorFieldNotSet && <Popup color={PopupWarningColor} message={`Field not set. Please select the user's field`} handlePopup={toggleErrorPopup}/>}
            {errorRoleNotSet && <Popup color={PopupWarningColor} message={`Role not set. Please select the user's role`} handlePopup={toggleErrorPopup}/>}
            {success && <Popup color={PopupSuccessColor} message={`User has been updated.`} handlePopup={toggleSuccess}/>}
        </EditUserContainer>
    );
}

export default EditUser;
