import {useMemo, useState} from 'react';
import {MaterialReactTable} from "material-react-table";
import dayjs from 'dayjs';

// import Builders from '../../classes/Builders.js';
// import Jobs from '../../classes/Jobs.js';
// import { LoadingMessage } from '../utilities/LoadingMessage';
// import { DropDownWithLabel } from '../utilities/DropDown.js';
// import { CheckboxWithLabel } from '../utilities/Checkbox.js';
// import { Button } from '../utilities/Button.js';
import Auth from '../authentication/Auth';
import MainHeader from '../utilities/MainHeader';
import EvenFlowContainer from './styles/EvenFlowContainer';
// import Globals from '../utilities/Globals';
// import Popup, {PopupWarningColor, PopupFailureColor} from '../utilities/Popup';
// import dayjs from "dayjs";

import Select from "react-select";
import Button from '@mui/material/Button';

const EvenFlow = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        builder: null,
        job: null,
        super: null
    });

    const [filterButton, setFilterButton] = useState({
        text: 'Apply Filters',
        disabled: false 
    })

    const [builders, setBuilders] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [supers, setSupers] = useState([]);
    const [phaseAndTasksTemplates, setPhaseAndTasksTemplates] = useState([]);

    const [lots, setLots] = useState([]);
//     const [displayLoadingMessage, setDisplayLoadingMessage] = useState(false);
//     const [displaySuperFor, setDisplaySuperFor] = useState(0);
//     const [displaySuperForDropDownList, setDisplaySuperForDropDownList] = useState([]);
//     const [scopeOfWorkToDisplay, setScopeOfWorkToDisplay] = useState([
//         {name: 'Concrete', display: true, phaseIds: [1, 2]},
//         {name: 'Plumbing', display: true, phaseIds: [3, 4, 5]},
//         {name: 'Masonry', display: true, phaseIds: [7]},
//         {name: 'Pavers', display: true, phaseIds: [8]},
//         {name: 'RFGT', display: true, phaseIds: [6]}
//     ]);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const gridColumns = useMemo(
        () => [
            {
                accessorKey: 'job.builder.name',
                header: 'Builder',
                size: 20,
            },
            {
                accessorKey: 'job.project_name',
                header: 'Project Name',
                size: 20,
            },
            {
                header: 'Supers',
                size: 155,
                Cell: ({row}) => {
                    const supers = row.original.job.assignments.map(a => {
                        return {
                            assignment: a,
                            title: a.assignment.description,
                            name: `${a.user.firstname} ${a.user.lastname}`
                        }
                    })
                    // TODO switch this to mui Grid
                    return (
                        <div>
                            {supers.map(s => {
                                return (
                                    <div key={`${s.title}-${s.name}`}>
                                        <div>{s.title}</div>
                                        <div>{s.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
            },
            {
                accessorKey: 'job.number',
                header: 'Job #',
                size: 20,
            },
            {
                accessorKey: 'number',
                header: 'Lot #',
                size: 20,
            },
            {
                accessorKey: 'phase',
                header: 'Lot Phase',
                size: 20,
            },
            ...phaseAndTasksTemplates.map(ptt => {
                let header = ptt.type === undefined ? ptt.stat_name : ptt.description;
                return {
                    id: `${Math.random()} ${header}`,
                    header: header,
                    size: 20,
                    Cell: (data => {
                        // Phase
                        if(ptt.type === undefined){
                            const phase = data.row.original.phases.find(p => parseInt(p.phase_id) === parseInt(ptt.id));
                            if(phase === undefined){
                                return <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(220, 0, 0)'}}>N/A</div>
                            }

                            const releaseDate = phase.release_date !== null ? dayjs(new Date(phase.release_date)).format('MM-DD-YYYY') : null;

                            return (
                                <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(220, 220, 220)'}}>{releaseDate}</div>
                            )
                        }


    
                        
                        // Task
                        const phase = data.row.original.phases.find(p => parseInt(p.phase_id) === parseInt(ptt.phase_id));

                        // TODO offset the projected date by offset amount from release date, skip holidays and weekends



                        const currentScheduledTasks = data.row.original.schedules.filter(s => parseInt(s.phase_task_id) === parseInt(ptt.id) && s.deleted_at === null);
                        if(currentScheduledTasks.length === 0){
                            return <div></div>
                        }

                        currentScheduledTasks.sort((st1, st2) => (new Date(st1.schedule_date)) > (new Date(st2.schedule_date)) ? -1 : 1);
                        const task = currentScheduledTasks[0];

                        const projectedDate = "Date"; // TODO
                        const scheduledDate = task.schedule_date !== null ? dayjs(new Date(task.schedule_date)).format('MM-DD-YYYY') : 'Not Scheduled';
                        const completedDate = task.completed_date !== null ? dayjs(new Date(task.completed_date)).format('MM-DD-YYYY') : 'Not Completed';


                        return (
                            <div>
                                <div>{projectedDate}</div>
                                <div>{scheduledDate}</div>
                                <div>{completedDate}</div>
                            </div>
                        )

                        return (
                            <div>WIP</div>
                        )
                    })
                }
            })
        ],
        [phaseAndTasksTemplates],
    );

    const loadData = async () => {
        setDidLoadData(true);

        const tempBuilders = await Auth.hitEndpointNew('GET', 'builders');
        setBuilders(tempBuilders.map(b => {
            return {
                ...b,
                value: b.id,
                label: b.name
            }
        }).sort((b1, b2) => b1.name < b2.name ? -1 : 1));

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));

        const tempSupers = await Auth.hitEndpointNew('GET', 'users-new', 'jsonString=position_id=2');
        setSupers(tempSupers.map(s => {
            return {
                ...s,
                value: s.id,
                label: `${s.firstname} ${s.lastname}`
            }
        }).sort((s1, s2) => {
            if(s1.firstname === s2.firstname){
                return s1.lastname < s2.lastname ? -1 : 1;
            }
            return s1.firstname < s2.firstname ? -1 : 1;
        }));

        let tempPhaseTemplates = await Auth.hitEndpointNew('GET', 'phases');
        setPhaseAndTasksTemplates(tempPhaseTemplates.map(pt => {
            pt.tasks = pt.tasks.filter(task => task.type === 'work').sort((t1, t2) => parseInt(t1.order_by) < parseInt(t2.order_by) ? -1 : 1);
            return pt;
        }).sort((pt1, pt2) => parseInt(pt1.even_flow_order_by) < parseInt(pt2.even_flow_order_by) ? -1 : 1).flatMap(pt => [pt, ...pt.tasks]));
    }

    if(!didLoadData){
        loadData();
    }

    const handleBuildersSelect = async (builder) => {
        let tempJobs = [];
        if(builder === null){
            tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        }
        else{
            tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new', `queryStr=builder_id=${builder.id}`);
        }
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));

        setFilterOptions({...filterOptions, builder: builder})
    }

    const applyFilters = async () => {
        if(filterOptions.builder === null && filterOptions.job === null && filterOptions.super === null){
            setError({
                type: 'NO_FILTERS_SELECTED',
                message: 'No filters selected, close this dialog and select a filter.'
            });
            return;
        }

        setFilterButton({
            text: 'Loading Lots...',
            disabled: true 
        })

        let queryStr = '';
        if(filterOptions.builder !== null){
            queryStr += `builder.id=${filterOptions.builder.id} and `
        }
        if(filterOptions.job !== null){
            queryStr += `lot.job_id=${filterOptions.job.id} and `
        }
        if(filterOptions.super !== null){
            queryStr += `job_assignment.user_id=${filterOptions.super.id} and `
        }

        if(queryStr !== ''){
            queryStr = queryStr.slice(0, queryStr.length - 5);
        }

        const tempLots = await Auth.hitEndpointNew("GET", "lots/even-flow", `queryStr=${queryStr}`);
        setLots(tempLots.sort((l1, l2) => {
            if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                if(parseInt(l1.number) === parseInt(l2.number)){
                    return l1.phase < l2.phase ? -1 : 1;
                }
                return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
            }
            return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
        }));
        
        setFilterButton({
            text: 'Apply Filters',
            disabled: false 
        })
    }

//     const toggleScopeOfWorkToDisplay = (index) => {
//         const tempPhaseAndPhaseTasksDisplayTemplate = [...phaseAndPhaseTasksDisplayTemplate];
//         const tempScopeOfWorkToDisplay = [...scopeOfWorkToDisplay];
//         tempScopeOfWorkToDisplay[index].display = !tempScopeOfWorkToDisplay[index].display;
//         for(let i = 0; i < tempPhaseAndPhaseTasksDisplayTemplate.length; i++){
//             if(tempPhaseAndPhaseTasksDisplayTemplate[i].type === 'phase'){
//                 const isPhase = tempScopeOfWorkToDisplay[index].phaseIds.find(phaseId => phaseId === tempPhaseAndPhaseTasksDisplayTemplate[i].id);
//                 if(isPhase !== undefined){
//                     tempPhaseAndPhaseTasksDisplayTemplate[i].display = tempScopeOfWorkToDisplay[index].display;
//                 }
//             }
//             if(tempPhaseAndPhaseTasksDisplayTemplate[i].type === 'task'){
//                 const isTask = tempScopeOfWorkToDisplay[index].phaseIds.find(phaseId => phaseId === tempPhaseAndPhaseTasksDisplayTemplate[i].phase_id);
//                 if(isTask !== undefined){
//                     tempPhaseAndPhaseTasksDisplayTemplate[i].display = tempScopeOfWorkToDisplay[index].display;
//                 }
//             }
//         }
//         setScopeOfWorkToDisplay([...tempScopeOfWorkToDisplay]);
//         setPhaseAndPhaseTasksDisplayTemplate([...tempPhaseAndPhaseTasksDisplayTemplate]);
//     }

//     const toggleTasksColumn = (column) => {
//         const tempPhaseAndPhaseTasksDisplayTemplate = [...phaseAndPhaseTasksDisplayTemplate];
//         const index = tempPhaseAndPhaseTasksDisplayTemplate.findIndex(phase => phase.name === column.name);
//         tempPhaseAndPhaseTasksDisplayTemplate[index].display = false;
//         setPhaseAndPhaseTasksDisplayTemplate([...tempPhaseAndPhaseTasksDisplayTemplate]);
//     }

//     const showAllColumns = () => {
//         const tempPhaseAndPhaseTasksDisplayTemplate = [...phaseAndPhaseTasksDisplayTemplate];
//         for(let i = 0; i < tempPhaseAndPhaseTasksDisplayTemplate.length; i++){
//             tempPhaseAndPhaseTasksDisplayTemplate[i].display = true;
//         }
//         setScopeOfWorkToDisplay(scopeOfWorkToDisplay.map(scope => {
//             scope.display = true;
//             return scope;
//         }));
//         setPhaseAndPhaseTasksDisplayTemplate([...tempPhaseAndPhaseTasksDisplayTemplate]);
//     }

//     const exportToXLSXFileFormat = () => {
//         const exportData = lots.map(lot => {
//             const result = {
//                 'Builder': lot.job.builder.name,
//                 'Project': lot.job.project_name,
//                 'Job #': lot.job.number,
//                 'Lot #': lot.number,
//                 'Phase ID': lot.phase
//             };

//             for(let i = 0; i < phaseAndPhaseTasksDisplayTemplate.length; i++){
//                 if(phaseAndPhaseTasksDisplayTemplate[i].type === 'phase'){
//                     const phase = lot.phases.find(phase => phase.phase_id === phaseAndPhaseTasksDisplayTemplate[i].id);
//                     result[`${phaseAndPhaseTasksDisplayTemplate[i].stat_name}`] = phase !== undefined ? Globals.formatDateToDisplay(phase.release_date) : '';
//                 }
//                 if(phaseAndPhaseTasksDisplayTemplate[i].type === 'task'){
//                     const task = lot.schedules.find(schedule => phaseAndPhaseTasksDisplayTemplate[i].id === schedule.phase_task_id);
//                     result[`${phaseAndPhaseTasksDisplayTemplate[i].description}`] = task !== undefined && task.completed_date ? Globals.formatDateToDisplay(task.completed_date) : '';
//                 }
//             }

//             return result;
//         });

//         Globals.exportToXLSXFileFormat(exportData, 'EvenFlow', './EvenFlow Data.xlsx');
//     }

//     const columns = ['Builder', 'Project', 'Super', 'Job #', 'Lot #', 'Phase ID', ...phaseAndPhaseTasksDisplayTemplate.filter(phase => phase.display)];
//     const lotsToDisplay = PagesData.getCurrentPageData(pagesData, lots);

    return (
        // <EvenFlowContainer numOfPhases={columns.length - 6}>
        <EvenFlowContainer>
            <Select 
                styles={{
                    control: styles => {
                        return {
                            ...styles,
                            width: "300px",
                            height: '50px',
                            margin: '10px auto',
                        }
                    },
                }} 
                id="builder-id"
                className="builder"
                placeholder="Select A Builder"
                value={filterOptions.builder}
                onChange={handleBuildersSelect}
                options={builders}
                isClearable
                isSearchable
            />
            <Select 
                styles={{
                    control: styles => {
                        return {
                            ...styles,
                            width: "300px",
                            height: '50px',
                            margin: '10px auto',
                        }
                    },
                }} 
                id="job-id"
                className="job"
                placeholder="Select A Job"
                value={filterOptions.job}
                onChange={(job) => setFilterOptions({...filterOptions, job: job})}
                options={jobs}
                isClearable
                isSearchable
            />
            <Select 
                styles={{
                    control: styles => {
                        return {
                            ...styles,
                            width: "300px",
                            height: '50px',
                            margin: '10px auto',
                        }
                    },
                }} 
                id="supers-id"
                className="supers"
                placeholder="Select A Super"
                value={filterOptions.super}
                onChange={(sup) => setFilterOptions({...filterOptions, super: sup})}
                options={supers}
                isClearable
                isSearchable
            />
            <Button 
                variant="contained" 
                onClick={applyFilters} 
                disabled={filterButton.disabled}
                style={{
                    width: "300px",
                    fontSize: "18px",
                    margin: '10px auto',
                }}
            >{filterButton.text}</Button>
            {/* <div className='top-boxes'>
                <div className='page-options'>
                    <div className='page-header'>Chart Display Options</div>
                    <div className='options'>
                        <CheckboxWithLabel className="show-concrete" name="show-concrete" label="Show Concrete" checked={scopeOfWorkToDisplay[0].display} handleCheckbox={() => toggleScopeOfWorkToDisplay(0)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                        <CheckboxWithLabel className="show-plumbing" name="show-plumbing" label="Show Plumbing" checked={scopeOfWorkToDisplay[1].display} handleCheckbox={() => toggleScopeOfWorkToDisplay(1)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                        <CheckboxWithLabel className="show-masonry" name="show-masonry" label="Show Masonry" checked={scopeOfWorkToDisplay[2].display} handleCheckbox={() => toggleScopeOfWorkToDisplay(2)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                        <CheckboxWithLabel className="show-pavers" name="show-pavers" label="Show Pavers" checked={scopeOfWorkToDisplay[3].display} handleCheckbox={() => toggleScopeOfWorkToDisplay(3)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                        <CheckboxWithLabel className="show-rfgt" name="show-rfgt" label="Show RFGT" checked={scopeOfWorkToDisplay[4].display} handleCheckbox={() => toggleScopeOfWorkToDisplay(4)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                        <DropDownWithLabel className="display-super-for" id="display-super-for-id" name="display-super-for" label="Display Super For" value={displaySuperFor} handleDropDown={(e) => setDisplaySuperFor(e.target.value)} addBlankOption={true} selectionList={displaySuperForDropDownList} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    </div>
                </div>
                <div className='page-key'>
                    <div className='page-header'>Table Key</div>
                    <div className='key'>
                        <div className='key-pair'>
                            <div className='key-projected'></div>
                            <div className='key-value'>Projected Date</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-yellow'></div>
                            <div className='key-value'>No Starts Release Date</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-blue'></div>
                            <div className='key-value'>Scheduled Date</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-red'></div>
                            <div className='key-value'>Completed Late</div>
                        </div>
                        <div className='key-pair'>
                            <div className='key-green'></div>
                            <div className='key-value'>Completed On Time</div>
                        </div>
                        
                    </div>
                </div>
            </div> */}
            {lots.length > 0 ?
                <MaterialReactTable
                id="even-flow-table"
                columns={gridColumns}
                data={lots}
                // data={[lots[0]]} // TODO
                enableFilters={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableDensityToggle={false}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    density: 'compact',
                }}
                // renderTopToolbarCustomActions={() => <Button handleClick={exportToXLSX} buttonText='Export To XLSX' />}
                /> : null
            }
            {/* {displayLoadingMessage && <LoadingMessage message="Loading Data, this may take several minutes..." width="600px"/>}
            {lotsToDisplay && lotsToDisplay.length > 0 &&
            <div>
                <div className='table-title'>                        
                    <Button className="export-data" handleKeyPress={((e) => {if(e.key === 'Enter'){exportToXLSXFileFormat()}})} handleClick={((e) => exportToXLSXFileFormat())} buttonText="Export to XLSX" buttonMargin="0" height="20" width="150"/>
                    <Button className="reset-layout" handleKeyPress={((e) => {if(e.key === 'Enter'){showAllColumns()}})} handleClick={((e) => showAllColumns())} buttonText="Show All Columns" buttonMargin="0" height="20" width="150"/>
                    <div className='header-title'>Lots</div>
                </div>
                <div className='grid-table'>
                    <div className='grid-table-header'>
                        {columns.map((column, index)=> {
                            if(column.type === 'phase'){
                                return <div key={index} className='grid-table-header-data release-phase'>{column.description}</div>
                            }
                            if(column.type === 'task'){
                                return <div key={index} className='grid-table-header-data tasks'><div className='column-toggle' onClick={() => toggleTasksColumn(column)}></div><div className='header-name'>{column.description}</div></div>
                            }
                            return <div key={index} className='grid-table-header-data'>{column}</div>
                        })}
                    </div>
                    {lotsToDisplay.map((lot, index) => {
                        let superDisplay = '';
                        if(displaySuperFor){
                            const superAssignment = lot.job.assignments.find(assignment => assignment.assignment_id === Number.parseInt(displaySuperFor));
                            if(superAssignment !== undefined){
                                const superInfo = allSupersWithDropDownDisplay.find(element => element.id === superAssignment.user_id);
                                if(superInfo !== undefined){
                                    superDisplay = `${superInfo.firstname} ${superInfo.lastname}`
                                }
                            }
                        }

                        let phaseReleaseDate = null;
                        let runningOffsetDay = 0;
                        let skippedOffsets = 0;

                        return (
                            <div key={index} className='grid-table-row'>
                                <div className='grid-table-row-data'>{lot.job.builder.name}</div>
                                <div className='grid-table-row-data'>{lot.job.project_name}</div>
                                <div className='grid-table-row-data'>{superDisplay}</div>
                                <div className='grid-table-row-data'>{lot.job.number}</div>
                                <div className='grid-table-row-data'>{lot.number}</div>
                                <div className='grid-table-row-data'>{lot.phase}</div>

                                
                                {phaseAndPhaseTasksDisplayTemplate.map((phaseOrTaskTemplate, index) => {
                                    if(!phaseOrTaskTemplate.display){
                                        if(phaseOrTaskTemplate.type === 'task'){
                                            // skippedOffsets += phaseOrTaskTemplate.even_flow_projected_date_offset; // TODO
                                            skippedOffsets += phaseOrTaskTemplate.offsets[1];
                                        }
                                        return null;
                                    }

                                    if(phaseOrTaskTemplate.type === 'phase'){
                                        const phase = lot.phases.find(phase => phase.phase_id === phaseOrTaskTemplate.id);
                                        // let release_date =  phase !== undefined ? dayjs(phase.release_date).format('YYYY-MM-DD') : null
                                        let release_date =  phase !== undefined ? phase.release_date : null
                                        phaseReleaseDate = release_date !== null ? Globals.formatDateToDisplay(release_date) : null;
                                        return <div key={`phase${index}`} className='grid-table-row-data release-phase'>{phaseReleaseDate}</div>
                                    }

                                    let taskProjectedDate = '';
                                    let taskScheduledDate = '';
                                    let taskCompletedDate = '';

                                    let taskProjectedDateColor = '';
                                    let taskScheduledDateColor = '';
                                    let taskCompletedDateColor = '';

                                    // TODO phaseOrTaskTemplate.even_flow_projected_date_offset is garbage, track back and set it correctly
                                    if(phaseReleaseDate){
                                        let offset = 0;
                                        if(phaseOrTaskTemplate.even_flow_order_by === 1){
                                            // offset = phaseOrTaskTemplate.even_flow_projected_date_offset; // TODO
                                            offset = phaseOrTaskTemplate.offsets[1];
                                            runningOffsetDay = offset;
                                            skippedOffsets = 0;
                                        }
                                        else{
                                            // runningOffsetDay += phaseOrTaskTemplate.even_flow_projected_date_offset + skippedOffsets; // TODO
                                            runningOffsetDay += phaseOrTaskTemplate.offsets[1] + skippedOffsets;
                                            offset = runningOffsetDay;
                                            skippedOffsets = 0;
                                        }
                                        const projectedDate = new Date(phaseReleaseDate);

                                        const dayOfTheWeek = projectedDate.getDay();
                                        for(let i = 0; i < offset; i++){
                                            const dayValue = (dayOfTheWeek + i + 1) % 7
                                            if(dayValue === 6 || dayValue === 0){
                                                offset++;
                                            }
                                        }
                                        projectedDate.setDate(projectedDate.getDate() + offset);
                                        taskProjectedDate = Globals.formatDateToDisplay(projectedDate);
                                        taskProjectedDateColor = 'gray';
                                    }

                                    const actualTask = lot.schedules.find(schedule => phaseOrTaskTemplate.id === schedule.phase_task_id);
                                    taskScheduledDate = actualTask && actualTask.schedule_date ? Globals.formatDateToDisplay(actualTask.schedule_date) : '';
                                    taskScheduledDateColor = taskScheduledDate ? 'blue' : '';

                                    taskCompletedDate = actualTask && actualTask.completed_date ? Globals.formatDateToDisplay(actualTask.completed_date) : '';
                                    if(taskCompletedDate && taskScheduledDate && (new Date(taskCompletedDate)) <= (new Date(taskScheduledDate))){taskCompletedDateColor = 'green';}
                                    else if(taskCompletedDate && taskScheduledDate && (new Date(taskCompletedDate)) > (new Date(taskScheduledDate))){taskCompletedDateColor = 'red';}
                                    
                                    if(!taskProjectedDate && (taskScheduledDate || taskCompletedDate)){
                                        taskProjectedDateColor = 'yellow';
                                        taskScheduledDateColor = 'yellow';
                                        taskCompletedDateColor = 'yellow';
                                    }
                                
                                    return (
                                        <div key={`phase${index}`}  className={`grid-table-row-data split`}>
                                            <div className={`work-phase-projected-date ${taskProjectedDateColor}`} >{taskProjectedDate}</div>
                                            <div className={`work-phase-scheduled-date ${taskScheduledDateColor}`} >{taskScheduledDate}</div>
                                            <div className={`work-phase-completed-date ${taskCompletedDateColor}`} >{taskCompletedDate}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>} */}
            {/* {lotsToDisplay && (lotsToDisplay.length > 0) &&  <Pages contentLength={lots.length} pagesData={pagesData} pageBack={() => setPagesData({...pagesData, currentPage: PagesData.pageBack(pagesData)})} pageForward={() => setPagesData({...pagesData, currentPage: PagesData.pageForward(pagesData, lots.length)})} handleInputPageNumber={(value) => setPagesData({...pagesData, currentPage: PagesData.handleInputPageNumber(value, pagesData, lots.length)})}/>} */}
            {/* {errorNoFilters && <Popup color={PopupFailureColor} message={"Please select at least 1 filter."} handlePopup={resetErrorPopups}/>} */}
            {/* {errorNoLotsMeetFilterOptions && <Popup color={PopupWarningColor} message={"No lots meet current filters selected."} handlePopup={resetErrorPopups}/>} */}
        </EvenFlowContainer>
    );
}

export default EvenFlow;