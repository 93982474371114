import {useEffect, useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";

import Auth from '../authentication/Auth.js';
import EWAs from '../../classes/Ewas'
import Employees from '../../classes/Employees.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import Popup, {PopupSuccessColor, PopupFailureColor} from '../utilities/Popup.js';
import EWAFormContainer from './styles/EWAFormContainer';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import Select from "react-select";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import FieldMenu from "./FieldMenu";
import JobLotFilter from "./JobLotFilter";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

const EWAForm = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
   // const [user, setUser] = useState('');

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [ewa, setEWA] = useState(null);

    const [employeesForDropDown, setEmployeesForDropDown] = useState([]);

    const [errorNoPhaseCodeSelected, setErrorNoPhaseCodeSelected] = useState(false);
    const [errorNoJobDescription, setErrorNoJobDescription] = useState(false);
    const [errorNoScheduledDate, setErrorNoScheduledDate] = useState(false);
    const [errorNoAmountEntered, setErrorNoAmountEntered] = useState(false);
    const [errorNoInternalEstimateOrPO, setErrorNoInternalEstimateOrPO] = useState(false);
    const [errorNoEmployeeSelected, setErrorNoEmployeeSelected] = useState(false);
    const [success, setSuccess] = useState(false);

    const [phases, setPhases] = useState([]);
    const [ewaCodes, setEwaCodes] = useState([])

    const [selectedPhase, setSelectedPhase] = useState('')
    const [selectedEwaCode, setSelectedEwaCode] = useState('')
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            if (Utilities.isLoggedOut()) {
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            } else {
                setDidLoadData(true);
                //   setUser(`${await Auth.getFirstName()} ${await Auth.getLastName()}`);
                setPhases(await Phases.loadPhases(null))
            }
        }
        if (!didLoadData) {
            loadData();
        }
    }, [])

    const resetPhaseCodeAndEmployeeDropDown = () => {

        setSelectedPhase(null)
        setSelectedEwaCode(null)
        setSelectedEmployee(null)
        const noteBox = document.getElementById('ew-form-job-description');
        if(noteBox !== null) {
            noteBox.value = '';
        }
        const internalEstimateBox = document.getElementById('ew-form-internal-estimate-number-input');
        if(internalEstimateBox !== null){
            internalEstimateBox.value = ''
        }
        const builderPoBox = document.getElementById('ew-form-builder-po-number-input');
        if(builderPoBox !== null){
            builderPoBox.value = ''
        }

    }

    const lotSelected = async (lot) => {
        if(lot === null){
            setEWA(null)
        }
        else {
            resetPhaseCodeAndEmployeeDropDown();
            const tempSelectedLot = lots.find(l => l.id.toString() === lot.value.toString());
            const tempNewEWA = await EWAs.createNewEWA(tempSelectedLot);
            tempNewEWA.lot_id = tempSelectedLot ? tempSelectedLot.id : null;
            setEWA(tempNewEWA);
        }
        return lot
    }

    const selectPhase = async (e) => {
        const phase = phases.find(phaseToFind => parseInt(phaseToFind.id) === parseInt(e.value))
        setSelectedPhase(e)
        ewa.line_items[0].phase_id = phase !== undefined ? phase.id : null

        setEwaCodes(await EWAs.loadEWACodes({"phase_task_phases.phase_id": e.value}))

        const company = phase !== undefined ? phase.company : null;
        let employees = []
        if(company === null){
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", null);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVC"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVP"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        setEmployeesForDropDown(employees)
    }

    const selectPhaseCode = async (e) => {
       // const ewaCode = ewaCodes.find(codeToFind => codeToFind.id.toString() === e.id.toString())
        setSelectedEwaCode(e)
        ewa.line_items[0].phase_task_id = e === null ? null : e.value
    }

    const selectEmployee = (selection) => {
        const tempEmployee = selection === null ? null : employeesForDropDown.find(employee => employee.id.toString() === selection.value.toString());
        if(tempEmployee === undefined || tempEmployee === null) {
            ewa.line_items[0].crew = {
                crew_id: null,
                employees: [{
                    employee_id: null,
                    is_lead: false,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        else{
            ewa.line_items[0].crew = {
                crew_id: tempEmployee.membership !== null ? tempEmployee.membership.crew_id : null,
                employees: [{
                    employee_id: tempEmployee.id,
                    is_lead: true,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        setSelectedEmployee(selection)
        setEWA({...ewa});
    }

    const setLineItem = (e, field, value) => {
        ewa.line_items[0][field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const setEWABillable = (e, field, value) => {
        ewa[field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const handleErrorCode = (errorCode) => {
        switch(errorCode){
            case "ERROR_NO_PHASE_CODE": setErrorNoPhaseCodeSelected(true);break;
            case "ERROR_NO_DESCRIPTION": setErrorNoJobDescription(true);break;
            case "ERROR_NO_SCHEDULED_DATE": setErrorNoScheduledDate(true);break;
            case "ERROR_NO_PAYOUT_AMOUNT": setErrorNoAmountEntered(true);break;
            case "ERROR_NO_INTERNAL_ESTIMATE_OR_BUILDER_PO": setErrorNoInternalEstimateOrPO(true);break;
            case "ERROR_NO_EMPLOYEE_SELECTED": setErrorNoEmployeeSelected(true);break;
            default: break;
        }
    }

    const handleSubmitEWA = async () => {
        const result = await EWAs.checkEWAForErrors(ewa, selectedEwaCode.billable === 1);
        if(result !== "SUCCESS"){
            handleErrorCode(result);
            return;
        }

        let number
        let ewaToSave = {...ewa}
        let scheduleYear = dayjs(new Date(ewa.schedule_date)).format('YYYY')
        let ewaCode = ewaCodes.find(codeToFind => codeToFind.id.toString() === ewa.line_items[0].phase_task_id.toString())
        ewaToSave.line_items[0].scheduled = true
        if(ewaCode !== undefined && ewaCode !== null) {
            let company = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.company : 'SVC'
            let suffix = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.ewa_suffix : 'ALL'
            number = await EWAs.getNextNumber(company, suffix, scheduleYear, number)
            ewaToSave.number = number.ewa_number
        }

        await EWAs.saveNewEWA(ewaToSave)
        resetPhaseCodeAndEmployeeDropDown();
        let tempNewEwa = await EWAs.createNewEWA(null);
        setEWA(tempNewEwa)
        setSuccess(true);
    }

    const togglePopup = () => {
        setErrorNoPhaseCodeSelected(false);
        setErrorNoJobDescription(false);
        setErrorNoScheduledDate(false);
        setErrorNoAmountEntered(false);
        setErrorNoEmployeeSelected(false);
        setErrorNoInternalEstimateOrPO(false);
        setSuccess(false);
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <EWAFormContainer>
                <JobLotFilter
                    title={'Extra Work Authorization'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />
                {(selectedLot && ewa) &&
                <div className='ew-form-box'>
                    <div className='ew-form-sub-header'>Extra Work Authorization Form</div>
                    <div className='ew-form-phase-drop-down-box'>
                        <label className="ew-form-phase-drop-down-label" htmlFor='ew-form-phase-select'>Phase</label>
                        <Select id='ew-phase-id' className="ew-form-phase-drop-down-select" id='ew-form-phase-select' name='ew-form-phase-select'
                                label="Phase"
                                onChange={(e) => selectPhase(e)}
                                options={phases.map(phase => {
                                    return (
                                        {
                                            value: phase.id,
                                            label: `${phase.name}`,
                                            phase: phase,
                                        }
                                    )
                                })}
                                value={selectedPhase}
                                placeholder={'Phase'}
                                isClearable={true}
                        />
                    </div>
                    <div className='ew-form-phase-code-drop-down-box'>
                        <label className="ew-form-phase-code-drop-down-label" htmlFor='ew-form-phase-code-select'>Code</label>
                        <Select id='ew-phase-code-id' className="ew-form-phase-code-drop-down-select" id='ew-form-phase-code-select' name='ew-form-phase-code-select'
                                label="Code"
                                onChange={(e) => selectPhaseCode(e)}
                                options={ewaCodes.map(code => {
                                    return (
                                        {
                                            value: code.id,
                                            label: `${code.code} - ${code.description}`,
                                            billable: code.billable,
                                            code: code,
                                        }
                                    )
                                })}
                                value={selectedEwaCode}
                                placeholder={'Code'}
                                isClearable={true}/>
                    </div>
                    <div className='ew-form-job-description-box'>
                        <div className='ew-form-job-description-header'>Notes</div>
                        <div className='ew-form-job-description-text-area'>
                            <TextField
                                multiline
                                className="ew-form-job-description-text-input"
                                name='ew-form-job-description'
                                id={'ew-form-job-description'}
                                rows='3' cols='30'
                                placeholder='Enter Job Description'
                                value={ewa.line_items[0].note}
                                inputProps={{style: {fontSize: 'small'}}}
                                onChange={(e) => setLineItem(e, "notes")}/>
                        </div>
                    </div>
                    <div className='ew-scheduled-on-date'>
                        <div className='ew-scheduled-on-label'>Date</div>
                        <div className='ew-scheduled-on-date-picker-box'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className='schedule-date-picker'
                                    onChange={(newValue) => {
                                        setSelectedDate(newValue)
                                        setLineItem(null, "schedule_date", newValue)
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: '',
                                        },
                                        field: { clearable: true },
                                    }}
                                    value={ewa.line_items.length > 0 && ewa.line_items[0].schedule_date !== null ? dayjs(ewa.line_items[0].schedule_date) : null}
                                    selected={ewa.line_items[0].schedule_date}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='ew-form-amount-box'>
                        <div className='amount-label'>Payout</div>
                        <div className='amount-input-box'>
                            <TextField
                                id='id-amount-input'
                                className='amount-input'
                                type='number'
                                value={ewa.line_items[0].payout}
                                inputProps={{style: {fontSize: 'medium'}}}
                                onClick={() => document.getElementById('id-amount-input').select()}
                                onChange={(e) => setLineItem(e, "payout")}/>
                        </div>
                    </div>
                    {(selectedEwaCode !== undefined && selectedEwaCode !== null && selectedEwaCode !== '' && selectedEwaCode.billable === 1) &&
                        <div>
                            <div className='ew-form-internal-estimate-number-box'>
                                <div className='ew-internal-estimate-number-label'>Int Est</div>
                                <div className='internal-estimate-number-input-box'>
                                    <TextField
                                        id='id-internal-estimate-number-input'
                                        className='internal-estimate-number-input'
                                        value={ewa.line_items[0].internal_estimate_number}
                                        inputProps={{style: {fontSize: 'small'}}}
                                        onClick={() => document.getElementById('id-internal-estimate-number-input').select()}
                                        onChange={(e) => setEWABillable(e, "internal_estimate_number")}/>
                                </div>
                            </div>
                            <div className='ew-form-builder-po-number-box'>
                                <div className='ew-builder-po-number-label'>Bldr PO</div>
                                <div className='builder-po-number-input-box'>
                                    <TextField
                                        id='id-builder-po-number-input'
                                        className='builder-po-number-input'
                                        value={ewa.line_items[0].builder_po_number}
                                        inputProps={{style: {fontSize: 'small'}}}
                                        onClick={() => document.getElementById('id-builder-po-number-input').select()}
                                        onChange={(e) => setEWABillable(e, "builder_po_number")}/>
                                </div>
                            </div>
                        </div>}

                    <div className='ew-form-employee-drop-down-box'>
                        <label className="ew-form-employee-drop-down-label" htmlFor='ew-form-employee-select'>Emp</label>
                        <Select className="employee-select" id="employee-select-id" name="employee-select"
                                label="Employee"
                                onChange={(e) => selectEmployee(e)}
                                options={employeesForDropDown.map(emp => {
                                    return (
                                        {
                                            value: emp.id,
                                            label: emp.display,
                                            employee: emp,
                                        }
                                    )
                                })}
                                value={selectedEmployee}
                                placeholder={'Employee'}
                                isClearable={true}
                        />
                    </div>
                    <div className='ew-form-submit'>
                        <div tabIndex='0' className='ew-form-submit-button' onClick={handleSubmitEWA}>Submit Extra Work Authorization</div>
                    </div>
                </div>}
                {errorNoPhaseCodeSelected && <Popup color={PopupFailureColor} message={'No Phase Code Selected. Please select a phase code.'} handlePopup={togglePopup}/>}
                {errorNoJobDescription && <Popup color={PopupFailureColor} message={'No job description entered. Please enter a job description.'} handlePopup={togglePopup}/>}
                {errorNoScheduledDate && <Popup color={PopupFailureColor} message={'No scheduled date selected. Please select a schedule date.'} handlePopup={togglePopup}/>}
                {errorNoAmountEntered && <Popup color={PopupFailureColor} message={'No amount entered. Please enter an amount.'} handlePopup={togglePopup}/>}
                {errorNoInternalEstimateOrPO && <Popup color={PopupFailureColor} message={'No internal estimate # or builder po # entered. Please enter an internal estimate # and/or builder po #.'} handlePopup={togglePopup}/>}
                {errorNoEmployeeSelected && <Popup color={PopupFailureColor} message={'No employee selected. Please select an employee.'} handlePopup={togglePopup}/>}
                {success && <Popup color={PopupSuccessColor} message={`Extra Work Authorization Form has been submitted`} handlePopup={togglePopup}/>}
            </EWAFormContainer>
        </FieldFormsContainer>
    )
};

export default EWAForm;