import { useState } from 'react';
import Crews from '../../classes/Crews.js';
import Auth from '../authentication/Auth.js';
import Employees from '../../classes/Employees.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';

const DeleteCrew = ({displayMessageWithTimer, updateCrewsArray, setEmployeesToRemove,
                    deleteCrew, setDeleteCrew, crewToDelete, setCrewToDelete}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const closeWithDelete = async () => {
        for(let i = 0; i < crewToDelete.members.length; i++){
            const tempEmployee = Employees.removeCrewNumber(crewToDelete.number, crewToDelete.members[i].employee)
            const empDBObj = {
                id: tempEmployee.id,
                crews: tempEmployee.crews,
                remove_from_crew: tempEmployee.crews === "" ? false : tempEmployee.remove_from_crew
            }

            await Auth.hitEndpointNew("PATCH", "employee", "", empDBObj);

            const jsonString = `crew_id=${crewToDelete.id} and employee_id=${tempEmployee.id}`;
            await Auth.hitEndpointNew("DELETE", "crew-member", `jsonString=${jsonString}`, {});
        }

        let reportsToRemove = []
        for(let i = 0; i < crewToDelete.scheduleReports.length; i++){
            reportsToRemove.push(crewToDelete.scheduleReports[i].id);
        }

        await Crews.deleteCrewScheduleReport(reportsToRemove);

        const dbObj = {
            id: crewToDelete.id,
            active: false,
            crew_scope_id: null,
            company: '',
            members: [],
            modified_at: dayjs(new Date()).format('YYYY/MM/DD'),
            modified_by: await Auth.getUserId(),
            phone_number: "",
            scheduleReports: []
        };

        await Auth.hitEndpointNew("PATCH", "crews", "", dbObj);

        setEmployeesToRemove(await Auth.hitEndpointNew("GET", "employees-new", "jsonString=remove_from_crew=1"));

        await updateCrewsArray(crewToDelete.number);
        displayMessageWithTimer('Crew Deleted', 'green', 5000);
        setCrewToDelete(null);
        setDeleteCrew(false);
    }

    const closeNoDelete = () => {
        displayMessageWithTimer('No Crews Deleted', 'red', 5000);
        setCrewToDelete(null);
        setDeleteCrew(false);
    }

    return (
        <Dialog open={deleteCrew}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Delete Crew</DialogTitle>
            <DialogContent sx={{width: "600px"}}>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Crew #:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {crewToDelete.number}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Scope:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {crewToDelete.scope.name}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Company:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {crewToDelete.company}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', margin: "3px 0", paddingRight: '5px'}}>
                        Members:
                    </Box>
                    {crewToDelete.members.map(m => {
                        let display = m.position + " - ";
                        display += m.employee.alias !== null && m.employee.alias !== "" ? (m.employee.alias + " - ") : "";
                        display += m.employee.first_name + " " + m.employee.last_name;
                        return (
                            <Box key={m.employee.id} style={{gridColumn: '5/11', margin: "3px 0"}}>
                                {display}
                            </Box>
                        )
                    })}
                </Box>
                {confirmDelete ?
                <Dialog open={confirmDelete}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Crew</DialogTitle>
                    <DialogContent sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>
                            Please Confirm Deleting Crew
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                        <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                        <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>Delete</Button>
                <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteCrew;