import Can from "../components/authentication/Can";
import {
    faBuildingUser,
    faCalendar, faFile, faGears,
    faHouse,
    faToolbox,
    faTruckPickup,
    faUsers
} from "@fortawesome/free-solid-svg-icons";


class FMS {

    static async loadMainMenu(permissions, uat) {
        const menu = []
        // Builders
        if (Can.isAuthorizedRead(permissions, 'builders')) {
            menu.push({
                label: 'Builders',
                route: '/builders-page',
                icon: faBuildingUser,
                children: [],
                mobile: '-- Builders Page',
                type: 'main',
            })
        }
        // // Jobs
        if (Can.isAuthorizedRead(permissions, 'jobs')) {
            menu.push({
                label: 'Jobs',
                route: '/jobs-page',
                icon: faToolbox,
                children: [],
                mobile: '-- Jobs Page',
                type: 'main',
            })
        }
        // Lots
        if (Can.isAuthorizedRead(permissions, 'startsLots') || Can.isAuthorizedRead(permissions, 'lots') || Can.isAuthorizedRead(permissions, 'evenFlow')) {
            menu.push({
                label: 'Lots',
                route: '#',
                icon: faHouse,
                children: [],
                type: 'main',
            })
            if (Can.isAuthorizedRead(permissions, 'lots') || Can.isAuthorizedRead(permissions, 'startsLots')) {
                menu[menu.length - 1].children.push({
                    label: 'Lots',
                    route: '/lots-page',
                    mobile: '-- Lots Page',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'evenFlow')) {
                menu[menu.length - 1].children.push({
                    label: 'EvenFlow',
                    route: '/lots/evenflow',
                    mobile: '-- Even Flow',
                })
            }
        }
        // Schedule
        if (Can.isAuthorizedRead(permissions, 'schedule') || Can.isAuthorizedRead(permissions, 'dailySchedule') || Can.isAuthorizedRead(permissions, 'pourLog')) {
            menu.push({
                label: 'Schedule',
                route: '#',
                icon: faCalendar,
                children: [],
                type: 'main',
            })
            if (Can.isAuthorizedRead(permissions, 'schedule')) {
                menu[menu.length - 1].children.push({
                    label: 'Schedule Work',
                    route: '/schedule',
                    mobile: '-- Schedule',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'dailySchedule')) {
                menu[menu.length - 1].children.push({
                    label: 'Daily Schedule',
                    route: '/daily-schedule',
                    mobile: '-- Daily Schedule',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'pourLog')) {
                menu[menu.length - 1].children.push({
                    label: 'Pour Log',
                    route: '/pour-log',
                    mobile: '-- Pour Log',
                })
            }
            // if (Can.isAuthorizedRead(permissions, 'scheduleUpdates')) {
            //     menu[menu.length - 1].children.push({
            //         label: 'WIP',
            //         route: '/wip',
            //         mobile: '-- WIP',
            //     })
            // }
        }
        // Crews
        if (Can.isAuthorizedRead(permissions, 'employees') || Can.isAuthorizedRead(permissions, 'crews') || Can.isAuthorizedRead(permissions, 'prospectiveEmployees')) {
            menu.push({
                label: 'Employees',
                route: '#',
                icon: faUsers,
                children: [],
                type: 'main',
            })
            if (Can.isAuthorizedRead(permissions, 'employees')) {
                menu[menu.length - 1].children.push({
                    label: 'Employees Page',
                    route: '/employees-page',
                    mobile: '-- Employees Page',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'crews')) {
                menu[menu.length - 1].children.push({
                    label: 'Crews Page',
                    route: '/crews-page',
                    mobile: '-- Crews Page',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'employeeTimeOff')) {
                menu[menu.length - 1].children.push({
                    label: 'Time Off Requests',
                    route: '/employee/time-off',
                    mobile: '-- Time Off Requests',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'employees')) {
                menu[menu.length - 1].children.push({
                    label: 'Prospective Employees',
                    route: '/prospective-employees',
                    mobile: '-- Prospective Employees',
                })
            }
        }
        // Field Forms
        if (Can.isAuthorizedRead(permissions, 'ewa') || Can.isAuthorizedRead(permissions, 'fieldForms')) {
            menu.push({
                label: 'Field',
                route: '#',
                icon: faTruckPickup,
                children: [{
                    label: 'Field Forms',
                    route: '/field-forms',
                    mobile: '-- Field Forms',
                }],
                type: 'main',
            })
            if (Can.isAuthorizedRead(permissions, 'fieldForms')) {
                menu[menu.length - 1].children.push({
                    label: 'EWAs',
                    route: !uat ? '/ewa' : '/ewas',
                    mobile: '-- Extra Work Authorization',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'ewa')) {
                menu[menu.length - 1].children.push({
                    label: 'Task Adjustments',
                    route: '/task-adjustment',
                    mobile: '-- Task Adjustments',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'gateInformation')) {
                menu[menu.length - 1].children.push({
                    label: ' Gates',
                    route: '/gates',
                    mobile: '-- Gates',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'scheduleRequest')) {
                menu[menu.length - 1].children.push({
                    label: 'SVC Schedule Request',
                    route: '/svc-schedule-request',
                    mobile: '-- SVC Schedule Request',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'scheduleUpdates')) {
                menu[menu.length - 1].children.push({
                    label: 'Schedule Updates',
                    route: '/schedule-updates',
                    mobile: '-- Schedule Updates',
                })
            }
            if (Can.isAuthorizedRead(permissions, 'employeeTimeOff')) {
                menu[menu.length - 1].children.push({
                    label: 'Time Off Requests',
                    route: '/employee/time-off',
                    mobile: '-- Time Off Requests',
                })
            }
        }
        //Reports
        if (Can.isAuthorizedRead(permissions, 'reports')) {
            menu.push({
                label: 'Reports',
                route: '/reports',
                icon: faFile,
                children: [],
                type: 'main',
            })
            // menu.push('-- Reports');
        }
        // Users
        if (Can.isAdmin(permissions)) {
            menu.push({
                label: 'Admin',
                route: '/admin-page',
                icon: faGears,
                children: [],
                type: 'main',
            })
        }

        return menu;
    }

    static async loadFieldFormsMenu(permissions, uat) {
        const menu = []

        if (Can.isAuthorizedRead(permissions, 'concreteMeasurements')) {
            menu.push({
                value: 1,
                label: 'Concrete Flatwork Measurements',
                route: '/field-forms/concrete-measurements',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'paverMeasurements')) {
            menu.push({
                value: 2,
                label: 'Paver Measurements',
                route: '/field-forms/paver-measurements',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'gateInformation')) {
            menu.push({
                value: 3,
                label: 'Gate Information',
                route: '/field-forms/gate-information',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'topOutAndTrimRelease')) {
            menu.push({
                value: 4,
                label: 'Release Top Out & Trim',
                route: '/field-forms/topout-trim-release',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'cameraQA')) {
            menu.push({
                value: 5,
                label: 'Plumbing Camera QA Form',
                route: '/field-forms/plumbing-camera-qa',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'ewa')) {
            menu.push({
                value: 6,
                label: 'Extra Work Authorization Form',
                route: '/field-forms/ewa-form',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        if (Can.isAuthorizedRead(permissions, 'schedule')) {
            menu.push({
                value: 7,
                label: 'Schedule Updates',
                route: '/field-forms/schedule-updates',
                icon: null,
                children: [],
                type: 'field'
            })
        }
        // if (Can.isAuthorizedRead(permissions, 'schedule')) {
        //     menu.push({
        //         value: 8,
        //         label: 'WIP',
        //         route: '/field-forms/wip',
        //         icon: null,
        //         children: [],
        //         type: 'field'
        //     })
        // }
        return menu;
    }
}

export default FMS;