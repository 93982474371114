import {useEffect, useState} from "react";
import Auth from "../authentication/Auth";
import Can from "../authentication/Can";
import FMS from "../../classes/FMS";
import {useNavigate} from "react-router";
import Select, {createFilter} from "react-select";


const FieldMenu = (props) => {

    const navigate = useNavigate();
    const [didLoadData, setDidLoadData] = useState(false);
    const [menu, setMenu] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null)

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);
    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };
    useEffect(() => {
        const loadData = async () => {
            const permissions = await Auth.getPermissions();
            const isUat = Can.isAuthorizedRead(permissions, 'uat')
            let tempMenu = await FMS.loadFieldFormsMenu(permissions, isUat);
            let location = window.location.pathname
            let selected = tempMenu.find(x => x.route === location)
            setSelectedForm(selected)
            setMenu(tempMenu);
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, []);

    const handleSelected = (selection) => {
        setSelectedForm(selection)
        if(selection === null) {
            navigate('/field-forms')
        }
        else{
            let route = selection.route
            navigate(route)
        }
    }

    return (
        <div>
            <div className='field-form-header'>Select Form</div>
            <div className='field-form-drop-down-box'>
                <label className="field-form-drop-down-label" htmlFor='field-form-select'>Form</label>
                <Select className="field-form-drop-down-select"
                        id='field-form-select'
                        name='field-form-select'
                        options={menu}
                        onChange={(e) => {
                            handleSelected(e);
                        }}
                        label="Select A Form"
                        required
                        isClearable
                        isSearchable
                        value={selectedForm}
                        filterOption={createFilter(filterConfig)}
                        placeholder={"Select a Form"}/>
            </div>
        </div>
    )
}


export default FieldMenu;