import {useEffect, useState} from 'react';
import Auth from '../authentication/Auth.js';
import NotesIcon from '../../img/NotesIcon.png';
import LotNotes from '../lots/LotNotes.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor} from '../utilities/Popup.js';
import TopOutTrimReleaseContainer from './styles/TopOutTrimReleaseContainer.js';
import dayjs from 'dayjs';
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";

const TopOutTrimRelease = (props) => {

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [phases, setPhases] = useState([]);
    const [topoutTrimReleaseInformation, setTopoutTrimReleaseInformation] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);
    const [noteType, setNoteType] = useState('');

    const [didLoadData, setDidLoadData] = useState(false);

    const [successTopout, setSuccessTopout] = useState(false);
    const [successTrim, setSuccessTrim] = useState(false);

    const canEditTopOutTrim = props.isAuthorized('topOutAndTrimRelease', 'WRITE');

    // Get date 30 days ago for trim release
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    useEffect(() => {
        const loadData = async () => {
            setPhases(await Auth.hitEndpointNew('GET', 'phases'));
            setDidLoadData(true);
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const getTopoutTrimInformation = async (lot) => {
        if(lot !== undefined && lot !== null) {
            const topoutPhase = lot.phases.find(p => parseInt(p.phase_id) === 4);
            const trimPhase = lot.phases.find(p => parseInt(p.phase_id) === 5);
            setTopoutTrimReleaseInformation({
                topoutReleaseDate: topoutPhase !== undefined ? topoutPhase.release_date : null,
                topoutStartsStatus: topoutPhase !== undefined ? topoutPhase.starts_status : '',
                trimReleaseDate: trimPhase !== undefined ? trimPhase.release_date : null,
                trimStartsStatus: trimPhase !== undefined ? trimPhase.starts_status : ''
            });
        }
    }

    const lotSelected = async (lot) => {
        // Get selected lot
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());
        // Fill in top out and trim information
        getTopoutTrimInformation(tempSelectedLot);
        return tempSelectedLot
    }

    const releasePhase = async (phase_id) => {
        let topoutPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 4);
        let trimPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 5);
        
        if(phase_id === 4){
            if(topoutPhase === undefined){
                selectedLot.phases.push({
                    lot_id: selectedLot.id,
                    modified_at: new Date(),
                    modified_by: await Auth.getUserId(),
                    phase: phases.find(p => parseInt(p.id) === 4),
                    phase_id: 4,
                    release_date: null,
                    start_status_id: null,
                    starts_status: '',
                });
                topoutPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 4);
            }
            topoutPhase.release_date = dayjs(new Date()).format('YYYY-MM-DD');
            if(topoutPhase.starts_status === '' || topoutPhase.starts_status === null){
                topoutPhase.starts_status = 'Received';
                topoutPhase.start_status_id = 1;
            }
        }
        if(phase_id === 5){
            if(trimPhase === undefined){
                selectedLot.phases.push({
                    lot_id: selectedLot.id,
                    modified_at: new Date(),
                    modified_by: await Auth.getUserId(),
                    phase: phases.find(p => parseInt(p.id) === 5),
                    phase_id: 5,
                    release_date: null,
                    start_status_id: null,
                    starts_status: '',
                });
                trimPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 5);
            }
            trimPhase.release_date = dayjs(new Date()).format('YYYY-MM-DD');
            if(trimPhase.starts_status === '' || trimPhase.starts_status === null){
                trimPhase.starts_status = 'Received';
                trimPhase.start_status_id = 1;
            }
        }

        const dbObj = {
            id: selectedLot.id,
            phases: selectedLot.phases.map(p => {
                return {
                    id: p.id !== undefined && p.id !== null ? p.id : null,
                    lot_id: p.lot_id,
                    modified_at: p.modified_at !== null ? dayjs(new Date(p.modified_at)).format('YYYY-MM-DD HH:mm:ss') : null,
                    modified_by: p.modified_by,
                    phase_id: p.phase_id,
                    release_date: p.release_date !== null ? dayjs(new Date(p.release_date)).format('YYYY-MM-DD HH:mm:ss') : null,
                    start_status_id: p.start_status_id,
                    starts_status: p.starts_status,
                }
            })
        }
        await Auth.hitEndpointNew('PATCH', 'lot/no-processing', '', dbObj);

        setTopoutTrimReleaseInformation({
            topoutReleaseDate: topoutPhase !== undefined ? topoutPhase.release_date : null,
            topoutStartsStatus: topoutPhase !== undefined ? topoutPhase.starts_status : '',
            trimReleaseDate: trimPhase !== undefined ? trimPhase.release_date : null,
            trimStartsStatus: trimPhase !== undefined ? trimPhase.starts_status : ''
        });
    }

    const handleReleaseTopOut = () => {
        releasePhase(4);
        setSuccessTopout(true);
    }

    const handleReleaseTrim = () => {
        releasePhase(5);
        setSuccessTrim(true);
    }

    const togglePopup = () => {
        setSuccessTopout(false);
        setSuccessTrim(false); 
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
        setNoteType('');
    }



    // Set Trim grayed out button text if needed
    let trimButtonText = 'Error';
    if(topoutTrimReleaseInformation){
        if(topoutTrimReleaseInformation.topoutReleaseDate === null){trimButtonText = 'Top Out Not Released'}
        else if(topoutTrimReleaseInformation.trimReleaseDate !== null){trimButtonText = 'Released'}
        else if(thirtyDaysAgo < new Date(topoutTrimReleaseInformation.topoutReleaseDate)){
            const thirtyDaysFromTopOutRelease = new Date(topoutTrimReleaseInformation.topoutReleaseDate);
            thirtyDaysFromTopOutRelease.setDate(thirtyDaysFromTopOutRelease.getDate() + 30);
            const daysUntilOpen = Number.parseInt((thirtyDaysFromTopOutRelease.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);
            trimButtonText = `${daysUntilOpen} Days Until Available`
        }
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <TopOutTrimReleaseContainer>
                <JobLotFilter
                    title={'Top Out & Trim Release'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />
                {selectedLot && topoutTrimReleaseInformation &&
                <div className='topout-trim-release-box'>
                    <div className='topout-trim-release-sub-header'>Top Out</div>
                    <div className='topout-trim-release-date-box'>
                        <div className='topout-trim-release-date-label'>Release Date:</div>
                        {topoutTrimReleaseInformation.topoutReleaseDate !== null && <div className='topout-trim-release-date-value'>{Globals.formatDateToDisplay(topoutTrimReleaseInformation.topoutReleaseDate)}</div>}
                        {topoutTrimReleaseInformation.topoutReleaseDate === null && <div className='topout-trim-release-date-value'>Not Released</div>}
                    </div>
                    <div className='topout-trim-release-status-box'>
                        <div className='topout-trim-release-status-label'>Starts Status:</div>
                        {topoutTrimReleaseInformation.topoutStartsStatus !== '' && <div className='topout-trim-release-status-value'>{topoutTrimReleaseInformation.topoutStartsStatus}</div>}
                        {topoutTrimReleaseInformation.topoutStartsStatus === '' && <div className='topout-trim-release-status-value'>N/A</div>}
                    </div>
                    <div className='topout-trim-lot-notes-box'>
                        <div className='topout-trim-lot-notes-label'>Lot Notes:</div>
                        <div className='topout-trim-lot-notes-value' onClick={(e) => {handleDisplayNotes(); setNoteType('TopOut');}}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                    </div>
                    {topoutTrimReleaseInformation.topoutReleaseDate === null && canEditTopOutTrim &&
                    <div className='submit'>
                        <div className='button' onClick={handleReleaseTopOut}>Release Top Out</div>
                    </div>}
                    {topoutTrimReleaseInformation.topoutReleaseDate !== null && canEditTopOutTrim &&
                    <div className='submit'>
                        <div className='button grayed'>Top Out Released</div>
                    </div>}
                    <div className='topout-trim-release-sub-header'>Trim</div>
                    <div className='topout-trim-release-date-box'>
                        <div className='topout-trim-release-date-label'>Release Date:</div>
                        {topoutTrimReleaseInformation.trimReleaseDate !== null && <div className='topout-trim-release-date-value'>{Globals.formatDateToDisplay(topoutTrimReleaseInformation.trimReleaseDate)}</div>}
                        {topoutTrimReleaseInformation.trimReleaseDate === null && <div className='topout-trim-release-date-value'>Not Released</div>}
                    </div>
                    <div className='topout-trim-release-status-box'>
                        <div className='topout-trim-release-status-label'>Starts Status:</div>
                        {topoutTrimReleaseInformation.trimStartsStatus !== "" && <div className='topout-trim-release-status-value'>{topoutTrimReleaseInformation.trimStartsStatus}</div>}
                        {topoutTrimReleaseInformation.trimStartsStatus === "" && <div className='topout-trim-release-status-value'>N/A</div>}
                    </div>
                    <div className='topout-trim-lot-notes-box'>
                        <div className='topout-trim-lot-notes-label'>Lot Notes:</div>
                        <div className='topout-trim-lot-notes-value' onClick={(e) => {handleDisplayNotes(); setNoteType('Trim');}}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                    </div>
                    {!canEditTopOutTrim ? null :
                    topoutTrimReleaseInformation.topoutReleaseDate !== null &&
                    (thirtyDaysAgo > new Date(topoutTrimReleaseInformation.topoutReleaseDate)) &&
                    topoutTrimReleaseInformation.trimReleaseDate === null ?
                    <div className='submit'>
                        <div className='button' onClick={handleReleaseTrim}>Release Trim</div>
                    </div> :
                    <div className='submit'>
                        <div className='button grayed'>
                            {trimButtonText}
                        </div>
                    </div>}
                </div>}


                {/* {displayNotes ? <LotNotes isAuthorized={props.isAuthorized} closeNotes={closeNotes} lot={selectedLot} type={noteType} width='400'/> : null} */}
                {displayNotes ?
                <LotNotes
                    openLotNotes={displayNotes}
                    setOpenLotNotes={setDisplayNotes}
                    lotToViewNotes={selectedLot}
                    setLotToViewNotes={() => {}}
                    noteType={noteType}
                    updatedLotReturned={closeNotes}
                />
                : null}




                {successTopout && <Popup color={PopupSuccessColor} message={`Top out has been released`} handlePopup={togglePopup}/>}
                {successTrim && <Popup color={PopupSuccessColor} message={`Trim has been released`} handlePopup={togglePopup}/>}
            </TopOutTrimReleaseContainer>
        </FieldFormsContainer>
    )
}

export default TopOutTrimRelease;