import styled from 'styled-components';

const FieldFormsContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 10px;

    .field-form-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .field-form-drop-down-box{
        display: grid;
        grid-template-columns: 15% 85%;
        padding: 15px 0;
        background: rgb(210, 200, 190);

        .field-form-drop-down-label{
            align-self: center;
            justify-self: end;
            padding-right: 5px;
        }

        .field-form-drop-down-select{
            padding: 2px 0;
            width: 95%;
            font-size: 15px;
            z-index: 3;
        }
    }
`;

export default FieldFormsContainer;