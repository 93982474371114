import { useState } from 'react';
import md5 from "md5";

import Auth from '../authentication/Auth.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { Button } from '../utilities/Button.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import MainHeader from '../utilities/MainHeader.js';
import ChangePasswordContainer from './styles/ChangePasswordContainer.js';
import Select from "react-select";

const ChangePasswordAdmin = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState('');

    const [passwordData, setPasswordData] = useState({
        newPassword: '',
        newPasswordConfirm: ''
    })

    const [errorNoUserSelected, setErrorNoUserSelected] = useState(false);
    const [errorNewPasswordsMismatch, setErrorNewPasswordsMismatch] = useState(false);
    const [errorNewPasswordLength, setErrorNewPasswordLength] = useState(false);
    const [errorNoPermissions, setErrorNoPermissions] = useState(false);
    const [success, setSuccess] = useState(false);

    const canChangePassword = props.isAuthorized('changePassword', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);
        setUsers((await Auth.hitEndpointNew("GET", `users`)).sort((u1, u2) => u1.firstname < u2.firstname ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const handleUserSelected = (e) => {
        setUser(e === null ? '' : e)
    }
    const updatePassword = async () => {
        if(!canChangePassword){
            setErrorNoPermissions(true);
            return;
        }

        if(user === ''){setErrorNoUserSelected(true);return;}
        if(passwordData.newPassword !== passwordData.newPasswordConfirm){setErrorNewPasswordsMismatch(true);return;}
        if(passwordData.newPassword.length < 7){setErrorNewPasswordLength(true);return;}

        const tempUser = {...user.user};
        tempUser.password = md5(passwordData.newPassword);
        setUser({...tempUser});

        await Auth.hitEndpoint("PATCH", "USER", "", tempUser);
        setSuccess(true);
    }

    const toggleWarnings = (e) => {
        setErrorNoUserSelected(false);
        setErrorNewPasswordsMismatch(false);
        setErrorNewPasswordLength(false);
        setErrorNoPermissions(false);
    }

    const handleSuccess = () => {
        setPasswordData({
            newPassword: '',
            newPasswordConfirm: ''
        })
        setSuccess(false);
    }

    return (
        <ChangePasswordContainer>
            <div className='header'>Change A Users Password</div>
            <div className='change-password-box'>
                <Select
                    id="user-list"
                    isClearable
                    isMulti={false}
                    isSearchable
                    onChange={(e) => {
                        handleUserSelected(e)
                    }}
                    value={user.id}
                    options={users.map((item, i) => (
                        {
                            value: item.id,
                            label: item.firstname + ' ' + item.lastname,
                            user: item,
                        }
                    ))}/>
                <TextboxWithLabel className="new-password-textbox" name="new-password" label="New Password" placeholder="New Password" inputType="password" value={passwordData.newPassword} handleTextbox={((e) => setPasswordData({...passwordData, newPassword: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="new-password-confirm-textbox" name="new-password-confirm" label="Confirm Password" placeholder="Confirm Password" inputType="password"value={passwordData.newPasswordConfirm} handleTextbox={((e) => setPasswordData({...passwordData, newPasswordConfirm: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <Button className="update-password" handleKeyPress={((e) => {if(e.key === 'Enter'){updatePassword()}})} handleClick={((e) => updatePassword())} buttonText="Update Password" width="150"/>
            </div>
            {errorNoUserSelected && <Popup color={PopupWarningColor} message={'Select a user first'} handlePopup={toggleWarnings}/>}
            {errorNewPasswordLength && <Popup color={PopupWarningColor} message={'New password must have minimum of 6 characters'} handlePopup={toggleWarnings}/>}
            {errorNewPasswordsMismatch && <Popup color={PopupWarningColor} message={'New password does not match confirmation. Please make sure new password and confirm password are the same.'} handlePopup={toggleWarnings}/>}
            {errorNoPermissions && <Popup color={PopupFailureColor} message={'You do not permission to change this password. Please see you supervisor for assistance.'} handlePopup={toggleWarnings}/>}
            {success && <Popup color={PopupSuccessColor} message={`${user.firstname} ${user.lastname}'s password has been changed.`} handlePopup={handleSuccess}/>}
        </ChangePasswordContainer>
    );
}

export default ChangePasswordAdmin;